<template>
  <footer-basic v-bind="$props">
    <!-- Flag Button -->
    <button-flag v-if="abilities['flag']" :button="buttons['flag']" />
    <!-- Print Button -->
    <button-print
      v-if="abilities['print']"
      :disabled="$route.name == 'create'"
      :abilities="abilities"
      :button="buttons['print']"
    />
    <!-- Send Button -->
    <button-send
      v-if="abilities['send']"
      :abilities="abilities"
      :button="buttons['send']"
      :values="values"
      @print="print = true"
      @loading="(loading) => $emit('loading', loading)"
    />
  </footer-basic>
</template>

<script>
import FooterBasic from "@/components/item/Footers/FooterBasic";
import ButtonPrint from "@/components/item/Buttons/ButtonPrint";
import ButtonSend from "@/components/item/Buttons/ButtonSend";
import ButtonFlag from "@/components/item/Buttons/ButtonFlag";
export default {
  components: {
    FooterBasic,
    ButtonPrint,
    ButtonSend,
    ButtonFlag,
  },
  props: {
    abilities: {
      type: Object,
      default: () => ({}),
    },
    buttons: {
      type: Object,
      default: () => ({}),
    },
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      print: false,
    };
  },
  methods: {
    onPrintButtonClick() {
      if (this.abilities["update"]) {
        this.$emit("loading", true);
        this.$store.dispatch("form/save", () => {
          this.$emit("loading", false);
          this.print = true;
        });
      } else {
        this.print = true;
      }
    },
  },
};
</script>