<template>
  <div>
    <b-input-group>
      <date-pick
        ref="datepicker"
        v-model="input"
        @click="onClick"
        @change="inputChange"
        @keydown="onKeyDown"
        :format="getFormat"
        :inputClasses="inputClasses"
        :buttonClasses="buttonClasses"
        :displayFormat="getDisplayFormat"
        :inputAttributes="inputAttributes"
        :nextMonthCaption="__('datepicker.nextMonth')"
        :prevMonthCaption="__('datepicker.prevMonth')"
        :setTimeCaption="__('datepicker.setTime')"
        :weekdays="__('datepicker.dayNames')"
        :months="__('datepicker.monthNames')"
        :isDateDisabled="isDateDisabled"
        :keyNavigation="keyNavigation"
        :pickMinutes="pickMinutes"
        :pickSeconds="pickSeconds"
        :placeholder="placeholder"
        :useCalendar="useCalendar"
        :showButton="showButton"
        :pickTime="pickTime"
        :error="error"
        :info="info"
      />
    </b-input-group>
  </div>
</template>

<script>
// TODO: add possibility to use << >>
import moment from "moment";
import DatePick from "@/plugins/DatePick";
export default {
  components: { DatePick },
  props: {
    value: {
      type: String,
      default: () => "",
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      info: null,
      error: null,
      input: null,
      valid: false,
      format: "YYYY-MM-DD",
      displayFormat: "DD-MM-YYYY",
      inputAttributes: this.field,
      neverDateValue: "9998-12-31",
      inputPlaceholders: {
        "DD-MM-YYYY": "DD-MM-JJJJ",
        "DD-MM-YYYY HH:mm": "DD-MM-JJJJ UUR:MIN",
        "DD-MM-YYYY HH:mm:ss": "DD-MM-JJJJ UUR:MIN:SEC",
      },
      tolerantInputPatterns: [
        "D",
        "DD",
        "D-M",
        "DD-M",
        "D-MM",
        "DD-MM",
        "D-M-YY",
        "D-MM-YY",
        "DD-M-YY",
        "DD-MM-YY",
      ],
    };
  },
  mounted() {
    if(this.field.value) {
      this.input = this.field.value;
    }
    if (this.isOpen) {
      this.$refs.datepicker.open();
    }
    if (_.has(this.field, "settings.datepicker.format.value")) {
      this.format = this.field.settings.datepicker.format.value;
    }
    if (_.has(this.field, "settings.datepicker.format.label")) {
      this.displayFormat = this.field.settings.datepicker.format.label;
    }
    if (this.defaultValue && !this.input) {
      this.input = this.__("datepicker.currentDateTerms").includes(this.defaultValue)
        ? moment().format(this.format)
        : this.defaultValue;
    }
    if (this.pickTime) {
      this.format = this.getTimeFormat(this.format);
      this.displayFormat = this.getTimeFormat(this.displayFormat);
      // append missing time data to value
      if (!this.isValidDate()) {
        let value = this.input || this.defaultValue;
        if (this.isValidDate(value, "YYYY-MM-DD")) {
          this.input = moment(value)
            .add(moment().hour(), "hours")
            .add(moment().minute(), "minutes")
            .format(this.format);
        }
      }
    }
  },
  computed: {
    getFormat() {
      return this.getTimeFormat(this.format);
    },
    getDisplayFormat() {
      return this.getTimeFormat(this.displayFormat);
    },
    isValid() {
      return (
        !_.isEmpty(this.input) &&
        this.isValidDate(this.input, false) &&
        !this.isDateDisabled(this.input)
      );
    },
    keyNavigation() {
      return _.has(this.field, "settings.datepicker.keynav")
        ? this.field.settings.datepicker.keynav
        : true;
    },
    allowShortcuts() {
      return _.has(this.field, "settings.datepicker.shortcuts")
        ? this.field.settings.datepicker.shortcuts
        : true;
    },
    showButton() {
      return _.has(this.field, "settings.datepicker.button")
        ? this.field.settings.datepicker.button
        : true;
    },
    pickTime() {
      return (
        _.has(this.field, "settings.datepicker.time") &&
        this.field.settings.datepicker.time !== false
      );
    },
    pickMinutes() {
      return _.has(this.field, "settings.datepicker.time.minutes") &&
        this.pickTime
        ? this.field.settings.datepicker.time.minutes
        : true;
    },
    pickSeconds() {
      return _.has(this.field, "settings.datepicker.time.seconds") &&
        this.pickMinutes
        ? this.field.settings.datepicker.time.seconds
        : false;
    },
    defaultValue() {
      return _.has(this.field, "settings.datepicker.default")
        ? this.field.settings.datepicker.default
        : false;
    },
    futureDate() {
      return _.has(this.field, "settings.datepicker.future")
        ? this.field.settings.datepicker.future
        : null;
    },
    disableWeekend() {
      return _.has(this.field, "settings.datepicker.weekend")
        ? !this.field.settings.datepicker.weekend
        : false;
    },
    useCalendar() {
      return _.has(this.field, "settings.datepicker.calendar")
        ? this.field.settings.datepicker.calendar
        : true;
    },
    isOpen() {
      return _.has(this.field, "settings.datepicker.open")
        ? this.field.settings.datepicker.open
        : false;
    },
    placeholder() {
      return _.has(this.field, "placeholder")
        ? this.field.placeholder
        : this.field.disabled
        ? ""
        : this.getPlaceholder(this.displayFormat);
    },
    fieldSize() {
      return this.size || this.field.size;
    },
    inputClasses() {
      return `datepicker__input form-control${
        this.fieldSize ? ` form-control-${this.fieldSize}` : ""
      }${this.isValid ? " input--valid" : ""} ${
        this.showButton ? " input--has-button" : "input--has-no-button"
      } ${this.useCalendar && !this.showButton ? " input--has-calendar" : ""}`;
    },
    buttonClasses() {
      return `datepicker__button button--toggle btn-secondary ${
        this.fieldSize ? `btn-${this.fieldSize}` : null
      } ${this.field.readonly || this.field.disabled ? "disabled" : null}`;
    },
  },
  watch: {
    input(input) {
      this.error = this.isDateDisabled(input) ? this.__("dateDisabled") : null;
      this.info = this.error ? null : this.info;
      this.input = _.trim(input);
      this.$emit("change", this.input);
    },
  },
  methods: {
    inputChange(input) {
      // check for shortcut 'today'
      if (this.allowShortcuts && this.__("datepicker.currentDateTerms").includes(input)) {
        this.input = moment().format(this.format);
        // check for shortcut 'forever'
      } else if (
        this.allowShortcuts &&
        this.__("datepicker.neverDateTerms").includes(input)
      ) {
        this.input = moment(this.neverDateValue).format(this.format);
        // check for valid date
      } else if (!this.isValidDate(input)) {
        // clear value by default
        this.input = null;
        // check for missing month or year
        _.forEach(this.tolerantInputPatterns, (pattern) => {
          if (this.isValidDate(input, pattern) && !this.input) {
            let format = pattern.includes("YY") ? "YY-MM-DD" : pattern;
            this.input = moment(input, format).format(this.format);
          }
        });
      }
      // check for disabled date
      if (this.isDateDisabled(this.input)) {
        this.$refs.datepicker.$refs.input.focus();
        this.input = null;
      }
      this.info = null;
    },
    isDateDisabled(value) {
      let date = moment(value, this.format);
      if (this.futureDate === true) {
        return date < moment().add(-1, "days");
      } else if (this.futureDate === false) {
        let isNeverDate = date.format(this.format) === this.neverDateValue;
        return date >= moment() && !isNeverDate;
      }
      if (this.disableWeekend) {
        return date.getDay() === 6 || date.getDay() === 0;
      }
      return false;
    },
    getTimeFormat(format) {
      if (format && this.pickTime) {
        if (this.pickSeconds && !format.includes("HH:mm:ss")) {
          return `${format} HH:mm:ss`;
        } else if (this.pickMinutes && !format.includes("HH:mm")) {
          return `${format} HH:mm`;
        }
      }
      return format;
    },
    onClick() {
      this.error = this.info = null;
    },
    onKeyDown() {
      this.info = this.__("dateKeyNavTip");
    },
    isValidDate(date = this.input, format = this.format, strict = true) {
      return moment(date, format, strict).isValid();
    },
    getPlaceholder(key) {
      return _.get(this.inputPlaceholders, key, null);
    },
  },
};
</script>