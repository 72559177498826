/*
 * This is the initial API interface
 * we set the base URL for the API
 ? Both request & response are logged to the console.
 ! Remove the console logs for production.
*/

import axios from "axios";
import router from "@/router";
import store from "@/store/index";

/*
 * set the API Base Url (deprecated)
 */
export const apiBaseUrl = process.env.VUE_APP_API_URL;

/*
 * Determine the API Base Url
 */
export function getApiBaseUrl() {
	return 'https://' + 'api.' + window.location.hostname.split('.').slice(-2).join('.');
}

/*
 * Define API Client for Axios' requests.
 */
export const apiClient = axios.create({
	withCredentials: true,
	baseURL: getApiBaseUrl()
});

/*
 * Define custom Axios functions for attaching the XSRF token.
 */

/*
 * Define custom Axios function for attaching custom XSRF token.
 */
export function getXsrfToken(request) {
	const xsrfToken = document.cookie && document.cookie.length ?
		document.cookie.match(new RegExp(`${request.xsrfCookieName}=([^;]+)`))
		: {};
	if (xsrfToken && xsrfToken.length > 1) {
		return decodeURIComponent(xsrfToken[1]);
	}
}

function customXsrfTokenRequest(request) {
	const xsrfToken = getXsrfToken(request);
	if (xsrfToken && xsrfToken.length > 1) {
		request.headers[request.xsrfHeaderName] = xsrfToken;
	}
	return request;
}

apiClient.interceptors.request.use(customXsrfTokenRequest);

/*
 * Define API Client for Axios' requests.
 */
export function csrfToken() {
	return axios.get(apiBaseUrl + '/vendor/csrf-cookie');
}

/*
 * Add a response interceptor.
 */
apiClient.interceptors.response.use((response) => response,
	(response) => {
		if (
			router.currentRoute['name'] !== 'login' &&
			[401, 419].includes(response.status)
		) {
			store.dispatch("auth/logout");
			router.push({ name: "login" });
		}
		return Promise.reject(response);
	}
);