<template>
  <form
    method="get"
    class="mt-2 mr-2"
    style="min-width: 250px; max-width: 500px"
    @submit.prevent="$emit('search', finder)"
  >
    <!-- Finder Control -->
    <b-input-group size="sm">
      <!-- Column Field -->
      <b-form-select
        v-if="!finder['fixed']"
        name="column"
        :options="columns"
        v-model="finder['column']"
        @change="$emit('change-finder')"
        style="max-width: 125px"
      />
      <!-- Operator Field -->
      <b-form-select
        v-if="!finder['fixed']"
        name="operator"
        :options="operatorOptions"
        v-model="finder['operator']"
        style="max-width: 100px"
      />
      <!-- Fixed Column -->
      <b-input-group-prepend v-if="finder['fixed']">
        <b-input-group-text>
          {{ finder["column"] }}
        </b-input-group-text>
      </b-input-group-prepend>
      <!-- Keywords Field -->
      <b-form-input
        v-model="finder['keywords']"
        name="keywords"
        autocomplete="off"
        :data-1p-ignore="true"
        :placeholder="__('keywords')"
        :autofocus="!_.isNull(finder['column']) && finder['focus']"
        @keydown.backspace="onKeyDownBackspace"
        @keydown.tab.exact="onKeyDownTabExact"
        @keydown.enter.prevent="$emit('search', finder)"
        @keyup="$emit('searching', finder['keywords'])"
        ref="keywords"
      />
      <!-- Remove Button -->
      <b-input-group-append
        v-if="!_.isNull(finder['column']) && !finder['fixed']"
      >
        <b-button
          size="sm"
          tabindex="-1"
          variant="outline-secondary"
          @click="$emit('remove-finder', finder['column'])"
          v-b-tooltip.bottom.hover="__('finder.search_queries.remove')"
        >
          <i class="fal fa-times-circle" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </form>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Array,
      default: () => [],
    },
    operators: {
      type: Array,
      default: () => [],
    },
    expandable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      finder: null,
    };
  },
  watch: {
    value(value) {
      this.finder = value;
    },
    finder: {
      deep: true,
      handler: function (value) {
        this.$emit("input", value);
      },
    },
  },
  beforeMount() {
    this.finder = this.value;
  },
  computed: {
    operatorOptions() {
      return _.map(this.operators, (operator) => {
        // in the case of a "wild-card" search, only enable "contains" operator
        let wildcard = this.finder["column"] === null;
        let contains = operator["value"] === "6";
        let disabled = wildcard && !contains;
        return { ...operator, disabled };
      });
    },
  },
  methods: {
    onKeyDownBackspace() {
      if (_.isEmpty(_.trim(this.finder["keywords"])) && !this.finder["fixed"]) {
        this.$emit("remove-finder", this.finder["column"]);
      }
    },
    onKeyDownTabExact() {
      if (this.expandable && !this.finder["fixed"]) {
        this.$emit("add-finder");
      }
    },
  },
};
</script>