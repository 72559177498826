var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm._.some(_vm.items))?_c('ul',{staticClass:"menu__items"},_vm._l((_vm.items),function(item,key){return _c('li',{key:key,staticClass:"menu__item",class:{
      'item--disabled': item['disabled'],
      'item--parent': _vm._.some(item['items']),
      'item--child': _vm.level > 1,
    }},[_c('div',{staticClass:"item__content",class:{ 'item--active': _vm.isActive(item) }},[_c('div',{class:`item__label level--${_vm.level}`,on:{"click":function($event){return _vm.onItemClick(item)}}},[(item['icon'])?_c('span',{class:`item__icon fal fa-${item['icon']}`}):_vm._e(),_c('span',{staticClass:"label__text",domProps:{"textContent":_vm._s(item['label'])}}),(item['slow'])?_c('span',{staticClass:"item__icon text-danger fal fa-turtle"}):_vm._e()]),(_vm._.some(item['items']) && !_vm.expand)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.__(_vm.getToggleAction(item))),expression:"__(getToggleAction(item))",modifiers:{"hover":true}}],staticClass:"item__toggler",on:{"click":function($event){return _vm.onToggle(item)}}},[_c('span',{staticClass:"toggler__icon fal",class:{
            'fa-minus': _vm.getToggleAction(item) === 'collapse',
            'fa-plus': _vm.getToggleAction(item) === 'expand',
          }})]):_vm._e()]),(_vm.showSubMenu(item))?_c('menu-tree',{attrs:{"expand":_vm.expand,"level":_vm.level + 1,"items":item['items']},on:{"select":_vm.onItemClick},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }