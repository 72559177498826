<template>
  <!-- Notes -->
  <article class="browser__notes" :class="{ loading }">
    <div v-html="notes || __('noNotesFound')" class="notes__text" />
  </article>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: "",
    },
    grid: {
      type: Object,
      default: () => ({}),
    },
    navigator: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      notes: "",
      loading: false,
    };
  },
  watch: {
    "grid.id": {
      immediate: true,
      handler: function (value) {
        if (!_.isEmpty(value)) {
          this.notes = "";
          this.setNotes();
        }
      },
    },
    notes: {
      immediate: true,
      handler: function (value) {
        if (!_.isEmpty(value)) {
          this.$emit("input", value);
        }
      },
    },
  },
  methods: {
    setNotes() {
      this.loading = true;
      this.$http
        .get(`${this.$route.params["resource"]}/${this.grid["id"]}/notes?view`)
        .then(({ data: { notes } }) => (this.notes = notes))
        .finally(() => (this.loading = false));
    },
  },
};
</script>