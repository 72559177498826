var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.send.apply(null, arguments)}}},[(!_vm.success)?[_c('form-field',{staticClass:"mb-4",attrs:{"errors":_vm._.isEmpty(_vm.errors['email']),"field":{
        name: 'email',
        label: _vm.__('E-mailadres | Email address'),
        placeholder: _vm.__('Voer uw e-mailadres in | Enter your email address'),
        autofocus: true,
        required: true,
        control: 'input',
        type: 'email',
        class: 'd-block text-center',
      }},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('submit-button',{attrs:{"label":_vm.__('Doorgaan | Continue'),"icon":"arrow-right","loading":_vm.loading},on:{"submit":_vm.send}})]:_vm._e(),_c('b-alert',{staticClass:"mt-3 mb-0",attrs:{"show":!_vm._.isEmpty(_vm.message),"variant":_vm.success ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('auth-reset-footer')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }