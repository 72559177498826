<template>
  <item-button
    v-if="button"
    v-bind="{ ...button, ...$props }"
    @click="dialogOpen = true"
    :class="{
      'ml-auto': !abilities['flag'],
      'ml-4': abilities['flag'],
    }"
  >
    <!-- Print Dialog -->
    <dialog-print
      v-if="dialogOpen"
      @close="dialogOpen = false"
      :source="$resource.getApiPath('print', true)"
    />
  </item-button>
</template>

<script>
import ItemButton from "@/components/item/Buttons/ItemButton";
import DialogPrint from "@/components/dialogs/DialogPrint";
export default {
  components: {
    DialogPrint,
    ItemButton,
  },
  props: {
    button: {
      type: Object,
      default: () => {},
    },
    abilities: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
};
</script>