<template>
  <div>
    <!-- Field -->
    <form-field
      v-model="module"
      class="d-block mb-4"
      :field="{
        name: 'module',
        label: __('Kies een module | Choose a module'),
        placeholder: __('Selecteer uw module | Please select your module'),
        control: 'select',
        class: 'text-center',
        required: true,
        options: modules,
      }"
    />
    <!-- Button -->
    <submit-button
      :label="__('Doorgaan | Continue')"
      icon="arrow-right"
      @submit="redirect"
    />
  </div>
</template>

<script>
import SubmitButton from "@/components/form/SubmitButton";
import FormField from "@/components/form/FormField";
import AuthService from "@/services/AuthService";
export default {
  components: {
    SubmitButton,
    FormField,
  },
  data() {
    return {
      user: {},
      module: null,
    };
  },
  async beforeMount() {
    this.user = await this.getUser();
    this.module = _.first(this.modules)["value"];
    if (_.size(this.modules) === 1) this.redirect();
  },
  computed: {
    modules() {
      const modules = _.get(this.user, "modules");
      return _.map(modules, ({ title, host }) => ({
        text: title,
        value: host,
      }));
    },
  },
  methods: {
    async getUser() {
      const response = await AuthService.user();
      return _.get(response, "data.data");
    },
    redirect() {
      if (!this.module) return;
      const url = location.protocol + "//" + this.module;
      window.location.href = url;
    },
  },
};
</script>