<template>
  <footer>
    <!-- Back Button -->
    <button-cancel :button="buttons['cancel']" />
    <!-- Custom Button(s) -->
    <slot />
    <!-- Save Button -->
    <button-save
      v-if="abilities[$route['name'] == 'create' ? 'create' : 'update']"
      :button="buttons['save']"
      class="ml-4"
    />
  </footer>
</template>

<script>
import ButtonCancel from "@/components/item/Buttons/ButtonCancel";
import ButtonSave from "@/components/item/Buttons/ButtonSave";
export default {
  components: {
    ButtonCancel,
    ButtonSave,
  },
  props: {
    abilities: {
      type: Object,
      default: () => ({}),
    },
    buttons: {
      type: Object,
      default: () => ({}),
    },
    values: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>