<template>
  <view-form
    group="user"
    base="provim"
    @success="onSuccess"
    @change="onChange"
  />
</template>

<script>
import ViewForm from "@/components/form/ViewForm";
import { mapGetters } from "vuex";
export default {
  components: { ViewForm },
  computed: mapGetters("auth", ["authUser"]),
  methods: {
    onSuccess(values) {
      this.$emit("updated", values);
      if (values["language"] != this.authUser["language"]) {
        setTimeout(() => window.location.reload(), 2000);
      }
    },
    onChange(value, field) {
      if (field == "color_scheme") {
        document.documentElement.classList.remove("light");
        document.documentElement.classList.remove("dark");
        document.documentElement.classList.add(value);
      }
    },
  },
};
</script>