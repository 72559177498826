<template>
  <resource-view base="provim" group="page" v-slot="view" ref="$view">
    <main class="content--home">
      <app-header v-bind="view" />
      <b-card-group deck class="cards">
        <template v-for="panel in panels">
          <component
            v-bind="panel"
            :key="panel['key']"
            v-if="panel['active']"
            :resource="panel['key']"
            :is="getComponentName(panel)"
          />
        </template>
        <template v-for="menu in menus">
          <template v-for="(item, key) in menu">
            <b-card
              :key="key"
              v-if="!_.isEmpty(item.items)"
              footer-class="mt-auto px-3"
              class="card--clean fadeIn"
              no-body
            >
              <template v-slot:header>
                <header class="card__header d-flex">
                  <span>
                    <i :class="`fal fa-${item.icon} mr-1`" />
                    {{ item["label"] }}
                  </span>
                </header>
              </template>
              <article class="card__content" style="min-height: 200px">
                <b-list-group flush class="content__list">
                  <template v-for="(subitem, key) in item['items']">
                    <b-list-group-item
                      @click="onMenuItemClick(subitem)"
                      :button="true"
                      :key="key"
                    >
                      <span>
                        <i
                          :class="`fal fa-${subitem['icon']} text-center`"
                          style="width: 30px; margin-left: -5px"
                        />
                        {{ subitem["label"] }}
                        <app-badge :badge="subitem['badge']" />
                      </span>
                    </b-list-group-item>
                  </template>
                </b-list-group>
              </article>
            </b-card>
          </template>
        </template>
      </b-card-group>
    </main>
  </resource-view>
</template>

<script>
import PanelRecentItems from "@/components/panels/PanelRecentItems";
import PanelInvoices from "@/components/panels/PanelInvoices";
import AppHeader from "@/components/app/AppHeader";
import AppBadge from "@/components/app/AppBadge";
export default {
  components: {
    PanelRecentItems,
    PanelInvoices,
    AppHeader,
    AppBadge,
  },
  data() {
    return {
      panels: {},
      menus: {},
    };
  },
  created() {
    // this.redirectInCaseOfEmployee(); // TODO: the price for the ugliest code goes to....
    this.setAdditionalPanels();
    this.setPrimaryMenu();
    this.setUserMenu();
    this.setTitle();
  },
  methods: {
    // redirectInCaseOfEmployee() {
    //   const user = this.$user();
    //   if (user["email"] == "medewerker@propertyview.nl") {
    //     this.$router.push({
    //       name: "multi-year-planning/import",
    //       query: {
    //         menu: false,
    //         layout: "grid",
    //         Obj_Code_To: "",
    //       },
    //     });
    //   }
    // },
    setPrimaryMenu() {
      this.$http
        .get("provim/menus/page/primary")
        .then(({ data: { data } }) => (this.menus["primary"] = data["items"]))
        .then(() => this.$refs.$view.$forceUpdate());
    },
    setUserMenu() {
      this.$http
        .get("provim/menus/page/user")
        .then(({ data: { data } }) => (this.menus["user"] = data["items"]))
        .then(() => this.$refs.$view.$forceUpdate());
    },
    setAdditionalPanels() {
      this.$http
        .get("provim/panels/dashboard")
        .then(({ data: { data } }) => (this.panels = data));
    },
    getComponentName({ key }) {
      return _.has(this.$options.components, key) ? key : "panel-recent-items";
    },
    onMenuItemClick({ action }) {
      const path = _.get(action, "path", action);
      this.$router.push({ path });
    },
    setTitle() {
      document.title = "Dashboard - PropertyView";
    },
  },
};
</script>