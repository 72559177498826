<template>
  <!-- Details -->
  <article :class="`browser__details details--${componentName || 'empty'}`">
    <template v-if="componentName">
      <keep-alive max="50">
        <component :is="componentName" :key="componentKey" v-bind="$props" />
      </keep-alive>
    </template>
    <template v-else>
      <browser-details-empty
        :message="emptyDetails['message']"
        :icon="emptyDetails['icon']"
      />
    </template>
  </article>
</template>

<script>
import BrowserDetailsEmpty from "@/components/browser/Details/BrowserDetailsEmpty";
import BrowserDetailsIndex from "@/components/browser/Details/BrowserDetailsIndex";
import BrowserDetailsShow from "@/components/browser/Details/BrowserDetailsShow";
import BrowserNotes from "@/components/browser/BrowserNotes";
import BrowserFiles from "@/components/browser/BrowserFiles";
export default {
  inheritAttrs: false,
  components: {
    index: BrowserDetailsIndex,
    show: BrowserDetailsShow,
    notes: BrowserNotes,
    files: BrowserFiles,
    BrowserDetailsEmpty,
  },
  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
    grid: {
      type: Object,
      default: () => ({}),
    },
    navigator: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      resource: null,
      componentName: null,
      emptyDetails: {
        message: "index.browser.details.empty",
        icon: "arrow-left",
      },
    };
  },
  watch: {
    "navigator.action": "onNavigatorActionChange",
  },
  mounted() {
    this.resource = this.$route.params["resource"];
  },
  computed: {
    componentKey() {
      return `${this.resource}_${this.navigatorResource}_${this.navigatorQueryVariant}`;
    },
    navigatorResource() {
      return (
        _.get(this.navigator, "action.params.resource") ||
        _.get(this.navigator, "action.path") ||
        _.get(this.navigator, "action.name") ||
        this.navigator["action"] ||
        "default"
      );
    },
    foreignQuery() {
      return { [this.foreignKey]: this.grid["id"] };
    },
    navigatorQuery() {
      return _.get(this.navigator, "action.query", {});
    },
    navigatorQueryVariant() {
      return (
        this.navigatorQuery["procedure"] ||
        this.navigatorQuery["function"] ||
        this.navigatorQuery["table"] ||
        this.navigatorQuery["view"] ||
        "default"
      );
    },
    foreignKey() {
      return (
        _.get(this.navigator, "action.foreign_key") ||
        _.get(this.meta, "model.foreign_key")
      );
    },
  },
  methods: {
    onNavigatorActionChange(action) {
      // include foreign key as query
      const query = this.foreignQuery;
      // plain action definition
      if (_.isString(action)) {
        // simply redirect the user to the given path
        this.$router.push({ path: action, query });
        // advanced action definition
      } else if (_.isObject(action)) {
        // set the component name based on its availability
        this.componentName = _.get(action, "name", "index");
        // check if the action should open a new tab
        if (_.get(action, "target") == "_blank") {
          const { href } = this.$router.resolve({ ...action, ...{ query } });
          this.emptyDetails["message"] = "index.browser.details.newTab";
          this.emptyDetails["icon"] = "external-link";
          window.open(href);
        }
      }
    },
  },
};
</script>