<template>
  <resource-view
    v-slot="view"
    base="provim"
    group="user"
    name="two-factor-auth"
  >
    <!-- Two-Factor Auth modal-->
    <app-modal
      size="xs"
      v-bind="view"
      :description="description"
      :confirm="step.button"
      @confirm="step.submit()"
      @close="step.cancel()"
    >
      <!-- Confirm -->
      <app-form
        v-if="step.action == 'confirm'"
        :key="key"
        v-model="values"
        :auth="true"
        :loader="false"
        :invalid="errors"
        :fieldset="fields['confirm']"
      />
      <!-- Enable -->
      <article
        v-if="step.action == 'enable' || step.action == 'recover'"
        class="d-flex justify-between"
      >
        <!-- QR Code -->
        <figure class="text-center pr-3" v-if="step.action == 'enable'">
          <div v-if="qrCode" v-html="qrCode" />
          <figcaption class="mt-2 text-muted" style="line-height: 14px">
            <small>
              {{
                __(
                  "Scan de bovenstaande QR-code met uw mobiele applicatie, zoals Google Authenticator of Authy. | Scan this QR code above with your mobile application, such as Google Authenticator or Authy."
                )
              }}
            </small>
          </figcaption>
        </figure>
        <aside style="line-height: 14px">
          <h5>{{ __("Herstelcodes | Recovery codes") }}</h5>
          <small class="text-muted d-block" style="max-width: 300px">
            {{
              __(
                "Deze codes heeft u nodig als u ooit toegang tot uw apparaat verliest. Bewaar deze codes op een veilige plaats. | You will need these codes if you were to ever lose access to your device. Keep these codes in a safe place."
              )
            }}
          </small>
          <!-- Recovery Codes -->
          <code
            class="d-inline-block p-2 mt-2 rounded bg-dark text-white text-center"
            style="font-size: 11px"
          >
            <span v-for="(code, key) in codes" :key="key">
              {{ code }}<br />
            </span>
          </code>
          <!-- Copy Recovery Codes -->
          <b-btn
            size="sm"
            class="d-block mt-2"
            style="width: 155px"
            variant="outline-secondary"
            @click="$copy(codes.join())"
          >
            <i class="fal fa-clipboard mr-2" />{{
              __("Codes kopiëren | Copy codes")
            }}
          </b-btn>
          <!-- Regenerate Recovery Codes -->
          <b-btn
            v-if="step.action == 'recover'"
            size="sm"
            variant="danger"
            class="d-block mt-2"
            style="width: 155px"
            @click="getNewRecoveryCodes"
            v-b-tooltip.hover="
              __(
                'Let op: hiermee worden uw huidige herstelcodes ongeldig. | Warning: this will invalidate your current recovery codes.'
              )
            "
          >
            <i class="fal fa-trash mr-2" />{{
              __("Codes hergenereren | Regenerate codes")
            }}
          </b-btn>
        </aside>
      </article>
      <!-- Verify -->
      <app-form
        v-if="step.action == 'verify'"
        :key="key"
        v-model="values"
        :invalid="errors"
        :fieldset="fields['verify']"
      />
      <!-- Finish -->
      <b-alert
        :show="step.action == 'finish'"
        class="mb-0 text-center"
        variant="success"
      >
        {{
          __(
            `Tweefactorauthenticatie is succesvol ${
              enabled ? "ingeschakeld" : "uitgeschaleld"
            }. | Two-factor authentication has been successfully ${
              enabled ? "enabled" : "disabled"
            }`
          )
        }}
      </b-alert>
    </app-modal>
  </resource-view>
</template>

<script>
import AppModal from "@/components/app/AppModal";
import AppForm from "@/components/app/AppForm";
import { mapGetters } from "vuex";
export default {
  inheritAttrs: false,
  components: {
    AppModal,
    AppForm,
  },
  props: {
    recovery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiBaseUrl: "vendor/user",
      confirmed: false,
      verified: false,
      finished: false,
      disabled: false,
      enabled: false,
      qrCode: null,
      fields: {},
      values: {},
      errors: {},
      codes: [],
      key: 0,
    };
  },
  beforeMount() {
    this.fetchFormFields();
  },
  computed: {
    step() {
      if (!this.confirmed) {
        return {
          action: "confirm",
          button: this.__("Bevestigen | Confirm"),
          submit: () =>
            this.confirm(() => {
              if (this.recovery) {
                this.getRecoveryCodes();
              } else if (this.authUser["2fa"]) {
                this.disable();
              } else {
                this.enable();
              }
            }),
          cancel: () => this.$emit("close"),
        };
      }
      if (this.confirmed && this.recovery) {
        return {
          action: "recover",
          button: this.__("Gereed | Done"),
          submit: () => this.$emit("close"),
          cancel: () => this.$emit("close"),
        };
      }
      if (this.confirmed && !this.authUser["2fa"] && !this.enabled) {
        return {
          action: "enable",
          button: this.__("Inschakelen | Enable"),
          submit: () => (this.enabled = true),
          cancel: () => this.disable(() => this.$emit("close")),
        };
      }
      if (
        this.confirmed &&
        !this.authUser["2fa"] &&
        this.enabled &&
        !this.verified
      ) {
        return {
          action: "verify",
          button: this.__("Controleren | Verify"),
          submit: () => this.verify(),
          cancel: () => this.disable(() => this.$emit("close")),
        };
      }
      if (this.confirmed && (this.verified || this.disabled)) {
        return {
          action: "finish",
          button: this.__("Afronden | Finish"),
          submit: () => this.$emit("close"),
          cancel: () => this.$emit("close"),
        };
      }
      return {};
    },
    description() {
      var description = "Apparaat instellen | Configure device";
      if (this.recovery) {
        description = "Herstelcodes | Recovery codes";
      } else if (this.authUser["2fa"]) {
        description = "Uitschakelen | Disable";
      }
      return this.__(description);
    },
    ...mapGetters("auth", ["authUser"]),
  },
  methods: {
    confirm(callback) {
      this.$http
        .post(`${this.apiBaseUrl}/confirm-password`, {
          password: this.values["password"],
        })
        .then(() => {
          this.confirmed = true;
          if (_.isFunction(callback)) callback();
        })
        .catch(this.handleErrors);
    },
    enable() {
      this.$http
        .post(`${this.apiBaseUrl}/two-factor-authentication`)
        .then(() => {
          this.getRecoveryCodes();
          this.getQrCode();
        })
        .catch(this.handleErrors);
    },
    disable(callback) {
      this.$http
        .delete(`${this.apiBaseUrl}/two-factor-authentication`)
        .then(() => {
          this.disabled = true;
          if (_.isFunction(callback)) callback();
        })
        .catch(this.handleErrors);
    },
    verify(callback) {
      this.$http
        .post(`${this.apiBaseUrl}/confirmed-two-factor-authentication`, {
          code: this.values["code"],
        })
        .then(() => {
          this.verified = true;
          if (_.isFunction(callback)) callback();
        })
        .catch(this.handleErrors);
    },
    getQrCode() {
      this.$http
        .get(`${this.apiBaseUrl}/two-factor-qr-code`)
        .then(({ data: { svg } }) => (this.qrCode = svg))
        .catch(this.handleErrors);
    },
    getRecoveryCodes() {
      this.$http
        .get(`${this.apiBaseUrl}/two-factor-recovery-codes`)
        .then(({ data }) => (this.codes = data))
        .catch(this.handleErrors);
    },
    getNewRecoveryCodes() {
      if (confirm(this.__("Weet u het zeker? | Are you sure?"))) {
        this.$http
          .post(`${this.apiBaseUrl}/two-factor-recovery-codes`)
          .then(() => this.getRecoveryCodes())
          .catch(this.handleErrors);
      }
    },
    fetchFormFields() {
      ["confirm", "verify"].forEach((form) =>
        this.$http
          .get(`provim/forms/user/${form}-two-factor-auth`)
          .then(({ data: { data } }) => {
            this.fields[form] = data;
            this.forceUpdate();
          })
          .catch(this.handleErrors)
      );
    },
    handleErrors({ response: { data } }) {
      this.errors = data.errors || {};
    },
    forceUpdate() {
      this.key++;
    },
  },
  // checkConfirmation() {
  //   this.$http
  //     .get(`${this.apiBaseUrl}/confirmed-password-status`)
  //     .then(() => (this.confirmed = true))
  //     .catch(() => (this.confirmed = false));
  // },
};
</script>