<template>
  <div>
    <template v-if="control === 'line'">
      <hr :style="lineStyle" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    control: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    lineStyle() {
      return {
        "border-top-width": `${this.height}px`,
        "margin-top": "5px",
      };
    },
  },
};
</script>