<template>
  <resource-view
    base="provim"
    group="page"
    name="user"
    v-slot="view"
    ref="$view"
  >
    <section class="content--user">
      <main class="content__container">
        <app-header v-bind="view" />
        <aside class="content__sidebar">
          <menu-tree
            :expand="true"
            :active="$route.fullPath"
            :items="firstMenuItem['items']"
            @select="(item) => $router.push(item['action'])"
          />
        </aside>
        <article class="content__body">
          <keep-alive max="10">
            <router-view :key="$route.fullPath" @updated="onViewUpdated" />
          </keep-alive>
        </article>
      </main>
    </section>
  </resource-view>
</template>

<script>
import AppHeader from "@/components/app/AppHeader";
import MenuTree from "@/components/menu/MenuTree";
export default {
  components: {
    AppHeader,
    MenuTree,
  },
  data() {
    return {
      menu: {},
    };
  },
  created() {
    this.setUserMenu();
  },
  computed: {
    firstMenuItem() {
      return _.first(this.menu) || {};
    },
  },
  methods: {
    setUserMenu() {
      this.$http
        .get("provim/menus/page/user")
        .then(({ data: { data } }) => (this.menu = data["items"]));
    },
    onViewUpdated() {
      this.$refs.$view.setView();
      this.$store.dispatch("auth/getAuthUser");
    },
  },
};
</script>