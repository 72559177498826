<script>
import ResourceService from "@/services/ResourceService";
export default {
  props: {
    base: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    group: {
      type: String,
      default: undefined,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      buttons: {},
    };
  },
  created() {
    this.setButtons();
  },
  render() {
    return this.$scopedSlots.default(this.buttons);
  },
  methods: {
    async setButtons() {
      this.buttons = await ResourceService.getButtons(
        this.base,
        this.name,
        this.group,
        this.params
      );
    },
  },
};
</script>