import { render, staticRenderFns } from "./ItemFile.vue?vue&type=template&id=298f2855&scoped=true"
import script from "./ItemFile.vue?vue&type=script&lang=js"
export * from "./ItemFile.vue?vue&type=script&lang=js"
import style0 from "./ItemFile.vue?vue&type=style&index=0&id=298f2855&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "298f2855",
  null
  
)

export default component.exports