<template>
  <resource-view base="provim" group="user" name="sessions" v-slot="view">
    <!-- Sessions modal-->
    <app-modal
      size="xs"
      v-bind="view"
      :confirm="__('Afronden | Finish')"
      @confirm="$emit('close')"
      @close="$emit('close')"
    >
      <!-- Sessions table -->
      <b-table
        :borderless="true"
        :items="sessions"
        :hover="true"
        class="mb-0"
        :fields="[
          __('Apparaat | Device'),
          __('Systeem | Systeem'),
          __('IP-adres | IP address'),
          __('Laatst gebruikt | Last used'),
        ]"
      />
    </app-modal>
  </resource-view>
</template>

<script>
import AppModal from "@/components/app/AppModal";
export default {
  components: { AppModal },
  data() {
    return {
      sessions: [],
    };
  },
  beforeMount() {
    this.setSessions();
  },
  methods: {
    setSessions() {
      this.$http
        .get("system/sessions")
        .then(({ data: { data } }) => (this.sessions = data));
    },
  },
};
</script>