<template>
  <span
    class="button"
    v-b-tooltip.hover="tooltip"
    v-show-but-keep-inner="useWrapper"
  >
    <b-button
      @click="onClick"
      :type="type"
      :variant="variant"
      :class="classNames"
      :disabled="disabled"
      v-b-tooltip.hover="tooltip"
    >
      <span v-html="labelHtml" />
    </b-button>
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "button",
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: null,
    },
    align: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconHtml() {
      return this.icon ? `<i class="fal fa-${this.icon} mr-2"></i>` : "";
    },
    labelHtml() {
      return this.iconHtml + this.label;
    },
    classNames() {
      return {
        [this.classes]: !_.isEmpty(this.classes),
        "mr-auto": this.align === "left",
      };
    },
    useWrapper() {
      return this.disabled === true && !_.isEmpty(this.tooltip);
    },
  },
  methods: {
    onClick() {
      if (this.link) {
        return (window.location.href = link);
      } else if (this.action.charAt(0) === "@") {
        this.$emit("emit", this.action.replace("@", ""));
      }
    },
  },
};
</script>