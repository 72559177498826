<template>
  <item-button
    v-bind="button"
    @click="onButtonClick"
    :disabled="$route['name'] == 'create'"
    :class="{
      'ml-auto': !abilities['print'],
      'ml-4': abilities['print'],
    }"
  >
    <!-- Send Modal -->
    <dialog-send
      v-if="dialogOpen"
      :values="values"
      :abilities="abilities"
      @print="$emit('print')"
      @close="dialogOpen = false"
    />
  </item-button>
</template>

<script>
import ItemButton from "@/components/item/Buttons/ItemButton";
import DialogSend from "@/components/dialogs/DialogSend";
export default {
  components: {
    DialogSend,
    ItemButton,
  },
  props: {
    button: {
      type: Object,
      default: () => {},
    },
    abilities: {
      type: Object,
      default: () => {},
    },
    values: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
  methods: {
    onButtonClick() {
      if (this.abilities["update"]) {
        this.$emit("loading", true);
        this.$store.dispatch("form/save", () => {
          this.dialogOpen = true;
          this.$emit("loading", false);
        });
      } else {
        this.dialogOpen = true;
      }
    },
  },
};
</script>