<template>
  <resource-view
    v-if="active"
    name="lookup"
    v-slot="view"
    :base="request['url']"
  >
    <app-modal
      v-bind="view"
      :size="width"
      :resize="true"
      :valid="hasSelection"
      :classes="modalClass"
      @close="close"
      @confirm="setSelection"
      ref="$lookupModal"
    >
      <template slot="header">
        <b-button
          tabindex="-1"
          variant="light"
          v-b-tooltip.hover
          :title="formButtonTitle"
          @click="isCompact = !isCompact"
          class="header__button button--compact"
        >
          <i class="fal fa-search" />
        </b-button>
        <b-button
          tabindex="-1"
          variant="light"
          v-b-tooltip.hover
          :title="layoutButtonTitle"
          @click="vertically = !vertically"
          class="header__button button--layout"
        >
          <span
            class="fal fa-columns"
            :class="{ 'rotate-90': !vertically }"
          ></span>
        </b-button>
      </template>
      <main class="lookup" :class="lookupClass">
        <b-container fluid>
          <b-row>
            <b-col :md="vertically ? 12 : 6">
              <section class="lookup__fields" v-show="!isCompact">
                <component
                  ref="$lookupForm"
                  :errors="errors"
                  :is="componentName"
                  @fail="onFormFail"
                  @mounted="onFormReady"
                  v-model="componentValues"
                  v-bind="{ form, fields, values, request }"
                />
                <hr />
                <b-row>
                  <b-col md="3" v-if="scoping === true">
                    <b-form-select
                      v-model="scope"
                      v-b-tooltip.hover.html
                      :options="scopeOptions"
                      :title="__('scopingTooltip')[scope - 1]"
                    />
                  </b-col>
                  <b-col :md="scoping === true ? 9 : 12" class="d-flex">
                    <b-button
                      v-if="isValidForm && clear && keywords.length > 1"
                      @click.prevent="clearForm"
                      variant="outline-danger"
                      class="mr-4"
                    >
                      <i class="fal fa-eraser" />
                      {{ __("clear") }}
                    </b-button>
                    <b-button
                      block
                      z-index="-1"
                      :size="size"
                      type="submit"
                      ref="$submitButton"
                      variant="outline-primary"
                      @click.prevent="loadResults"
                      :disabled="loading || !isValidForm"
                    >
                      <span v-if="!loading">
                        <i class="fal fa-search mr-2" />
                        {{ __("search") }}
                      </span>
                      <span v-if="loading && !lazyloading">
                        <i class="fal fa-spinner fa-spin mr-2" />
                        {{ __("loading") }}
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-col>
            <b-col :md="vertically ? 12 : 6">
              <hr v-if="vertically && !isCompact" />
              <section v-if="hasResults" class="lookup__results">
                <div :class="resultsClass" class="results__list data__frame">
                  <!-- <div class="scrollbarWrapper" ref="scroller">
                    <div class="scrollbar" :style="{ width: scrollWidth }"></div>
                  </div> -->
                  <b-table
                    striped
                    selectable
                    no-select-on-click
                    responsive="sm"
                    :small="isSmall"
                    :items="results"
                    :fields="header"
                    class="datatable"
                    select-mode="single"
                    selected-variant="active"
                    :sticky-header="!vertically"
                    @row-clicked="onRowClicked"
                    @row-dblclicked="onRowDoubleClicked"
                    ref="$lookupResults"
                  >
                    <template v-slot:cell()="data">
                      <text-highlight :queries="keywordsMapHuman">{{
                        data.value
                      }}</text-highlight>
                    </template>
                  </b-table>
                </div>
                <b-button
                  block
                  :size="size"
                  class="results__button"
                  @click="loadMoreResults"
                  variant="outline-secondary"
                  v-if="hasResults && lazyload"
                  :disabled="lazyloading || !isValidForm"
                >
                  <span v-if="!lazyloading">
                    <i class="fal fa-plus-circle mr-1" />
                    {{ __("lookup.loadMoreResults") }}
                  </span>
                  <span v-if="lazyloading">
                    <i class="fal fa-spinner fa-spin mr-2" />
                    {{ __("loading") }}
                  </span>
                </b-button>
              </section>
              <section class="lookup__feedback">
                <template v-if="_.some(feedback)">
                  <b-alert
                    :dismiss="true"
                    variant="secondary"
                    v-text="__('loading')"
                  />
                </template>
                <template v-else-if="!loaded && !loading">
                  <b-alert
                    :dismiss="true"
                    variant="primary"
                    v-text="__('lookup.start')"
                  />
                </template>
              </section>
            </b-col>
          </b-row>
        </b-container>
      </main>
      <template slot="note">
        <small class="text-muted" v-if="hasResults">
          <!-- <i class="fal fa-lightbulb text-primary mr-2" />
          <span v-html="__('lookup.tip')"></span> -->
        </small>
      </template>
    </app-modal>
  </resource-view>
</template>

<script>
import DialogLookup from "@/components/dialogs/DialogLookup";
const AppForm = () => import("@/components/app/AppForm");
import AppModal from "@/components/app/AppModal";
import AppAlert from "@/components/app/AppAlert";
import TextHighlight from "vue-text-highlight";
export default {
  components: {
    TextHighlight,
    DialogLookup,
    AppAlert,
    AppModal,
    AppForm,
  },
  props: {
    value: {
      type: Object | String,
      default: () => ({}),
    },
    request: {
      type: Object,
      default: () => ({}),
    },
    select: {
      type: Array | Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    table: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String | Boolean,
      default: null,
    },
    vertical: {
      type: Boolean | String,
      default: true,
    },
    clear: {
      type: Boolean,
      default: true,
    },
    scoping: {
      type: Boolean | Number,
      default: false,
    },
    component: {
      type: Object | String,
      default: "DialogLookup",
    },
    size: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: "xxl",
    },
    limit: {
      type: Number | Boolean,
      default: 25,
    },
    preload: {
      type: Boolean | String,
      default: false,
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object | String,
      default: null,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    keynav: {
      type: Boolean,
      default: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      scope: 1,
      delay: 500,
      header: {},
      active: false,
      loaded: false,
      errors: {},
      results: [],
      timeout: null,
      loading: false,
      keywords: {},
      feedback: null,
      selected: false,
      lazyload: false,
      selection: [],
      isCompact: false,
      vertically: false,
      lazyloading: false,
      scrollWidth: null,
      componentValues: {},
    };
  },
  mounted() {
    this.setScope();
    this.setCompact();
    this.setLayout(true);
    this.setComponent();
  },
  watch: {
    componentValues: {
      deep: true,
      handler: function (value) {
        this.keywords = value;
      },
    },
  },
  computed: {
    headerNamesDefault() {
      return {
        value: this.__("lookup.header.value"),
        label: this.__("lookup.header.label"),
        title: this.__("lookup.header.title"),
        image: this.__("lookup.header.image"),
      };
    },
    scopeOptions() {
      return [
        { value: 1, text: this.__("lookup.scopes.free") },
        { value: 2, text: this.__("lookup.scopes.exact") },
        { value: 3, text: this.__("lookup.scopes.strict") },
      ];
    },
    keywordsMap() {
      return _.filter(_.values(this.keywords), (keyword) =>
        _.isString(keyword) ? _.trim(keyword) : false
      );
    },
    keywordsMapHuman() {
      return _.map(this.keywordsMap, (keyword) => {
        return this.$getHumanValue(keyword);
      });
    },
    isValidForm() {
      return this.hasKeywords || this.hasComponentKeywords || this.usePreloader;
    },
    hasComponentKeywords() {
      return _.isEmpty(_.omitBy(this.componentValues), _.isEmpty);
    },
    hasKeywords() {
      return !_.isEmpty(this.keywordsMap);
    },
    lookupClass() {
      return {
        "lookup--small": this.size == "sm",
        "lookup--large": this.size == "lg",
        "lookup--loading": this.isLoading,
        "lookup--vertically": this.vertically,
      };
    },
    modalClass() {
      return this.vertically ? "modal--overflown" : "";
    },
    usePreloader() {
      return _.isString(this.preload)
        ? !_.isEmpty(_.get(this.keywords, this.preload))
        : this.preload;
    },
    resultsClass() {
      return { loading: this.loading || this.lazyloading };
    },
    hasResults() {
      return !_.isEmpty(this.results);
    },
    hasSelection() {
      return !_.isEmpty(this.selection);
    },
    isLoading() {
      return this.loading || this.lazyloading;
    },
    isSmall() {
      return this.size == "sm";
    },
    layoutButtonTitle() {
      let orientation = this.vertically ? "horizontal" : "vertical";
      return this.__(`lookup.${orientation + "Layout"}`);
    },
    formButtonTitle() {
      let visibility = this.isCompact ? "show" : "hide";
      return this.__(visibility + "Fields");
    },
    contextPayload() {
      return this.$objectToQueryParams(this.$context(), "context");
    },
    hasComponent() {
      return !_.isEmpty(this.component);
    },
    componentName() {
      if (_.has(this.$options.components, this.component)) {
        return this.component;
      }
    },
    pageNumber() {
      return { "page[number]": this.page };
    },
    pageSize() {
      return { "page[size]": this.limit };
    },
  },
  methods: {
    getApiRequest() {
      let request = _.cloneDeep(this.request);
      return _.merge(request, {
        params: {
          value: null,
          keywords: "",
          ...this.params,
          ...this.pageSize,
          ...this.pageNumber,
          ...this.contextPayload,
          ...this.getApiFilters(),
        },
      });
    },
    open() {
      this.active = true;
      this.selection = this.value;
      this.addEventListeners();
    },
    close() {
      this.active = false;
      this.loading = false;
      this.selection = {};
      this.removeEventListeners();
    },
    setSelection() {
      if (this.selected && this.hasSelection) {
        let result = _.find(this.response, { __data: this.selection });
        let selection = _.isEmpty(result) ? this.selection : result;
        this.$emit("input", selection);
        this.close();
      }
    },
    loadResults() {
      if (this.isValidForm) {
        this.page = 1;
        this.loading = true;
        this.getResults();
      }
    },
    loadMoreResults() {
      if (this.isValidForm) {
        this.page += 1;
        this.lazyloading = true;
        this.getResults();
      }
    },
    getResults() {
      this.loading = true;
      this.$http(this.getApiRequest())
        .then(({ data: { data } }) => {
          this.setData(data);
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.onFormFail();
        })
        .finally(() => (this.loading = false));
    },
    setData(data) {
      if (_.isArray(data)) {
        this.setResults(data);
        this.setTableHeader(data);
        this.setLoaders(data);
        this.$nextTick(() => {
          this.setLayout();
          this.selectFirstRow();
          this.setupTopScrollBar();
          this.blurActiveFormElement();
        });
      } else {
        this.onFormFail();
      }
    },
    onFormFail() {
      this.results = [];
      this.loading = false;
      this.$root.$emit("feedback", {
        key: "error_500",
        group: "error",
      });
    },
    setResults(data) {
      if (_.some(data)) {
        let results = _.isEmpty(this.table)
          ? _.map(data, (value, key) => _.get(value, "__data", value))
          : data;
        this.response = this.page > 1 ? _.union(this.data, data) : data;
        this.results = this.page > 1 ? _.union(this.results, results) : results;
      } else {
        this.results = {};
        this.selection = [];
      }
    },
    getApiFilters() {
      let filters = _.mapValues(this.componentValues, (value) =>
        _.get(value, "value", value)
      );
      return this.$objectToQueryParams(_.omitBy(filters, _.isEmpty), "filter");
    },
    setLoaders(data) {
      this.loaded = true;
      this.loading = false;
      this.lazyloading = false;
      this.lazyload = data.length >= this.limit;
    },
    selectFirstRow() {
      if (this.page == 1) this.selectRow(0);
    },
    selectRow(index = 0, scroll = true) {
      if (this.hasResults) {
        this.selected = true;
        this.selection = this.results[index];
        let $results = this.$refs.$lookupResults;
        if ($results) {
          $results.selectRow(index);
          let $activeRow = $results.$el.querySelector(
            `tbody tr:nth-child(${index + 1})`
          );
          if (scroll && $activeRow) {
            $activeRow.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }
      }
    },
    setTableHeader(data) {
      let firstRow = _.first(data);
      let headers = _.isEmpty(this.table)
        ? _.get(firstRow, "__data", firstRow)
        : this.table;
      this.header = _.map(headers, (value, key) => {
        let label = _.get(this.headerNamesDefault, key, value);
        return { key, label };
      });
    },
    onFormReady() {
      this.$nextTick(() => {
        if (this.hasSelection && this.loaded) {
          this.selectRow(this.getIndexSelectedResult());
        } else if (this.usePreloader) {
          this.loadResults();
        }
      });
    },
    getIndexSelectedResult(human = false) {
      let index = parseInt(
        _.findIndex(this.results, (result) => {
          let selection = _.get(result, "__data", result);
          return _.isEqual(this.selection, selection);
        })
      );
      return human ? index + 1 : index || 0;
    },
    onRowClicked(item, index) {
      this.selectRow(index, false);
    },
    onRowDoubleClicked(item, index) {
      this.selectRow(index, false);
      this.setSelection();
    },
    // onRowSelected(items) {
    // this.selected = _.some(items);
    // this.selection = this.selected
    //   ? _.get(_.first(items), "__data", _.first(items))
    //   : [];
    // },
    setupTopScrollBar() {
      // TODO: fix top scrollbar, currently disabled
      return;
      // set the scrollbar width
      if (_.has(this.$refs, "$lookupResults.$el")) {
        this.scrollWidth =
          this.$refs.$lookupResults.$el.querySelector("table").offsetWidth +
          "px";
        // register scroll event listener for the result element
        this.$refs.$lookupResults.$el.addEventListener("scroll", () => {
          this.$refs.scroller.scrollLeft =
            this.$refs.$lookupResults.$el.scrollLeft;
        });
        // register scroll event listener for the (simulated) top scrollbar
        this.$refs.scroller.addEventListener("scroll", () => {
          this.$refs.$lookupResults.$el.scrollLeft =
            this.$refs.scroller.scrollLeft;
        });
      }
    },
    blurActiveFormElement() {
      if (!this.hasComponent) {
        let $el = this.$refs.$lookupForm.$el.querySelector(":focus");
        if ($el) $el.blur();
      }
    },
    clearForm() {
      this.$refs.$lookupForm.clear();
    },
    addEventListeners() {
      if (this.keynav) {
        document.addEventListener("click", this.onClick);
        document.addEventListener("keyup", this.onEnterKey);
        document.addEventListener("keyup", this.onSpaceKey);
        document.addEventListener("keydown", this.onArrowKeys);
      }
    },
    removeEventListeners() {
      if (this.keynav) {
        document.removeEventListener("click", this.onClick);
        document.removeEventListener("keyup", this.onEnterKey);
        document.removeEventListener("keyup", this.onSpaceKey);
        document.removeEventListener("keydown", this.onArrowKeys);
      }
    },
    setScope() {
      this.scope = this.scoping ? parseInt(this.scoping) : this.scope;
    },
    setCompact() {
      this.isCompact = this.compact;
    },
    setLayout(initial = false) {
      this.vertically =
        (this.vertical === true && initial) ||
        (this.vertical == "auto" &&
          this.hasResults &&
          this.$hasOverflow(this.$refs.$lookupResults.$el))
          ? true
          : this.vertically;
    },
    onEnterKey(e) {
      if (
        e.code == "Enter" &&
        e.target.localName != "button" &&
        _.has(this.$refs, "$lookupForm.$el") &&
        this.$refs.$lookupForm.$el.contains(e.target)
      ) {
        this.$refs.$submitButton.click();
      }
    },
    setComponent() {
      // this.componentValues = _.get(this.component, "value", {});
    },
    onClick(e) {
      //   if (this.hasResults) {
      //     this.$refs.$lookupResults.$el.querySelector("tbody").contains(e.target)
      //       ? this.setSelection()
      //       : document.addEventListener("keydown", this.onArrowKeys);
      //   }
    },
    onSpaceKey(e) {
      //   if (
      //     e.code == "Space" &&
      //     this.lazyload &&
      //     !this.hasComponent &&
      //     !this.$refs.$lookupForm.$el.contains(e.target)
      //   ) {
      //     this.loadMoreResults();
      //   }
    },
    onArrowKeys(e) {
      //   if (["ArrowDown", "ArrowUp"].includes(e.code) && this.hasResults) {
      //     let index = this.getIndexSelectedResult(true);
      //     let indexNextRow = e.code == "ArrowDown" ? index + 1 : index - 1;
      //     this.$refs.$lookupResults.$el
      //       .querySelector(`tbody tr:nth-child(${indexNextRow})`)
      //       .focus();
      //     document.removeEventListener("keydown", this.onArrowKeys);
      //     e.preventDefault();
      //   }
    },
  },
};
</script>