<template>
  <p class="empty__text">
    <span v-if="message" :class="`fal fa-${icon} mr-2`" />
    {{ __(message) }}
  </p>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    message: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>