<template>
  <resource-buttons v-slot="buttons">
    <component
      :is="component"
      :values="values"
      :buttons="buttons"
      :abilities="abilities"
      @submit="$emit('submit')"
      @loading="(loading) => $emit('loading', loading)"
      class="d-flex w-100 justify-content-between"
    />
  </resource-buttons>
</template>

<script>
import workorderReports from "@/components/item/Footers/FooterWorkorderReports";
import basic from "@/components/item/Footers/FooterBasic";
import keys from "@/components/item/Footers/FooterKeys";
export default {
  components: {
    workorderReports,
    basic,
    keys,
  },
  props: {
    abilities: {
      type: Object,
      default: () => ({}),
    },
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    component() {
      let component = _.camelCase(this.$route.params.resource);
      return _.has(this.$options.components, component) ? component : "basic";
    },
  },
};
</script>