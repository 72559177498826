<template>
  <div
    tabindex="-1"
    class="page__modal modal--active"
    :class="{ 'modal--front': front }"
  >
    <div
      tabindex="-1"
      ref="$modal"
      role="dialog"
      class="modal modal-bg show"
      @mousedown="onCloseAttempt"
      @keydown.esc="onCloseAttempt"
    >
      <div
        role="document"
        :class="modalClass"
        :data-loader="loader"
        class="modal-dialog modal--overflown"
        :style="front && size == 'xs' ? 'max-width: 450px' : ''"
      >
        <div
          v-bind="$attrs"
          class="modal-content"
          :style="{ 'min-height': modalHeight }"
        >
          <header class="modal-header" v-if="title">
            <h5 class="modal-title">
              <i v-if="icon" :class="`modal-icon fal mr-2 fa-${icon}`" />
              <span v-html="title"></span>
              <small
                class="modal-description text-muted ml-2"
                v-html="description"
              ></small>
            </h5>
            <div class="header__buttons">
              <slot name="header"></slot>
              <b-button
                tabindex="-1"
                variant="link"
                class="header__button"
                @click="$emit('close')"
                v-b-tooltip.hover="`${__('close')} <Esc>`"
              >
                <i class="fal fa-times-circle text-secondary" />
              </b-button>
            </div>
          </header>
          <div class="modal-body" :class="{ 'p-0': !padding }">
            <slot></slot>
          </div>
          <footer class="modal-footer" v-if="footer">
            <slot name="footer">
              <b-button
                v-if="buttons"
                variant="secondary"
                class="modal__cancel"
                @click="$emit('close')"
                >{{ cancel || __("cancel") }}</b-button
              >
              <slot name="note">
                <small v-if="note" class="text-muted text-center">
                  <i class="fal fa-info-circle mr-2" />
                  <span v-html="note" />
                </small>
              </slot>
              <b-button
                v-if="buttons"
                class="modal__confirm"
                @click="$emit('confirm')"
                :disabled="!valid || loading"
                :variant="confirmButtonVariant"
              >
                <span v-html="confirm || __('confirm')" />
              </b-button>
            </slot>
          </footer>
        </div>
        <div class="modal-backdrop" v-if="backdrop" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    center: {
      type: Boolean,
      default: true,
    },
    front: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    cancel: {
      type: String,
      default: "",
    },
    note: {
      type: String,
      default: "",
    },
    confirm: {
      type: String,
      default: "",
    },
    align: {
      type: String,
      default: "",
    },
    resize: {
      type: Boolean,
      default: true,
    },
    backdrop: {
      type: Boolean,
      default: true,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: Boolean,
      default: true,
    },
    buttons: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    padding: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$emit("open");
    this.$refs.$modal.focus(); /* hack in favor of escape key scoping */
  },
  created: function () {
    document.addEventListener("keyup", this.onKeyUp);
  },
  destroyed: function () {
    document.removeEventListener("keyup", this.onKeyUp);
  },
  computed: {
    modalClass() {
      return `${this.center ? "modal-dialog-centered" : ""} ${
        this.size ? `modal-${this.size}` : ""
      } ${this.resize ? "modal--resize" : ""} ${
        this.align ? `modal--align-${this.align}` : ""
      } ${this.loading ? "loading" : ""} ${this.classes ? this.classes : ""}`;
    },
    modalHeight() {
      if (this.size == "xs" && !this.height) {
        return "auto";
      } else if (this.height) {
        return this.height;
      }
      return "600px";
    },
    confirmButtonVariant() {
      return this.variant || "primary";
    },
  },
  methods: {
    onCloseAttempt(e) {
      if (e.target.className && e.target.className.includes("modal-bg")) {
        this.$emit("close");
      }
    },
  },
};
</script>