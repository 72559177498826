<template>
  <b-card
    :id="id"
    no-body
    v-if="active"
    class="card--clean"
    footer-class="px-3"
  >
    <template v-slot:header>
      <header class="card__header d-flex">
        <span
          ><i :class="`mr-1 fal fa-${icon || config.panel['icon']}`" />
          {{ title || config.panel["heading"] }}
          <b-badge v-if="!_.isEmpty(rows)" variant="primary" pill>
            {{ _.size(rows) }}
          </b-badge>
        </span>
      </header>
    </template>
    <article class="card__content">
      <b-list-group flush class="content__list">
        <b-list-group-item
          v-for="(row, index) in rows"
          @click="selection = row"
          :button="true"
          :key="index"
        >
          {{ row.__data.title }}
          <small class="text-muted float-right">{{ row["Datum"] }}</small>
        </b-list-group-item>
      </b-list-group>
    </article>
    <app-modal
      size="xxl"
      v-if="selection"
      :padding="false"
      @open="loading = true"
      @close="selection = null"
      :title="_.get(selection, '__data.title')"
    >
      <article :class="{ loading }" style="min-height: 80vh">
        <iframe
          :src="`${$http.defaults.baseURL}/workorder-invoice-scans/${_.get(
            selection,
            '__data.value'
          )}/scan`"
          class="position-absolute w-100 h-100 border-0"
          @load="onFrameLoaded"
        ></iframe>
      </article>
      <template slot="footer">
        <b-button
          @click="approveInvoice(false)"
          :variant="config.modal.unapprove['variant']"
          :disabled="loading"
        >
          <i :class="`fal fa-${config.modal.unapprove['icon']}`" />
          {{ config.modal.unapprove["label"] }}
        </b-button>
        <span class="mx-auto text-muted" v-if="loaded && loading">
          <i class="fal fa-spinner fa-spin mr-1" />
          {{ config.modal.loader }}
        </span>
        <b-button
          @click="approveInvoice(true)"
          :variant="config.modal.approve['variant']"
          :disabled="loading"
        >
          <i :class="`fal fa-${config.modal.approve['icon']}`" />
          {{ config.modal.approve["label"] }}
        </b-button>
      </template>
    </app-modal>
  </b-card>
</template>

<script>
import config from '@/translations/nl/panels.json';
import AppModal from "@/components/app/AppModal";
export default {
  components: {
    AppModal,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selection: null,
      loading: false,
      loaded: false,
      config: null,
      pages: null,
      rows: [],
      pdf: null,
    };
  },
  beforeMount() {
    this.setConfig();
    this.setRows();
  },
  beforeDestroy() {
    this.loaded = false;
    this.pdf = null;
  },
  methods: {
    setConfig() {
      this.config = config;
    },
    setRows() {
      this.loading = true;
      this.$http
        .get("workorder-invoice-scans", { params: { view: true } })
        .then(({ data }) => (this.rows = data["data"]))
        .finally(() => (this.loading = false));
    },
    approveInvoice(approved = true) {
      let apiUrl = `/workorder-invoice-scans/${_.get(
        this.selection,
        "__data.value"
      )}/approve`;
      let feedback = this.config.alert[approved ? "approved" : "unapproved"];
      this.loading = true;
      this.$http
        .post(apiUrl, { params: { approved } })
        .then((response) => this.$root.$emit("feedback", response, feedback))
        .finally(() => {
          this.selection = null;
          this.loading = false;
        });
    },
    onFrameLoaded() {
      this.loading = false;
      this.loaded = true;
    },
  },
};
</script>