var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"form__wrapper"},[(_vm._.some(_vm.fields) || !!_vm.$slots)?_c('form',_vm._b({staticClass:"form",class:_vm.formClass,attrs:{"method":"post","action":_vm.action,"data-method":_vm.method},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)},"submit":function($event){$event.preventDefault();return _vm.submit(undefined)}}},'form',_vm.$attrs,false),[_vm._t("default"),_c('b-tabs',{attrs:{"active-tab-class":"d-flex row form__elements","content-class":_vm._.size(_vm.tabs) > 1 ? 'mt-3 ' : '',"nav-wrapper-class":_vm._.size(_vm.tabs) === 1 ? 'd-none' : ''}},[_vm._l((_vm.tabs),function(tab){return [(_vm.showTab(tab))?_c('b-tab',{key:tab.key,scopedSlots:_vm._u([{key:"title",fn:function(){return [(tab.icon)?_c('i',{class:`fal fa-${tab.icon} mr-2`}):_vm._e(),(_vm.issues[tab.key])?_c('figure',{staticClass:"error-count"},[_vm._v(" "+_vm._s(_vm.issues[tab.key])+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(tab.title))])]},proxy:true},{key:"default",fn:function(){return [_vm._l((tab.fields),function(field,name){return [_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible(field) && field.control !== 'tab'),expression:"isVisible(field) && field.control !== 'tab'"}],key:`control-${_vm._.get(field, 'name', name)}`,class:`form__element ${_vm.getElementClass(field)}`,attrs:{"id":!field.repeat ? _vm._.get(field, 'name', name) : null}},[(_vm.isBlockElement(field))?[_c('Block',_vm._b({},'Block',field,false))]:(field.control !== 'tab')?[(_vm._.has(_vm.repeaters, field.repeat))?[_vm._l((_vm.repeaters[field.repeat][
                        'values'
                      ]),function({ _deleted },id){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_deleted),expression:"!_deleted"}],key:id,staticClass:"field__group row fadeIn"},[_vm._l((_vm.repeaters[field.repeat]
                            .fields),function(repeat,name){return _c('form-field',{key:name + id,class:_vm.getElementClass(repeat),attrs:{"auth":_vm.auth,"size":_vm.size,"form":_vm.values,"width":_vm.width,"field":repeat,"labels":_vm.labels,"name":_vm._.get(repeat, 'name', name),"errors":_vm.errors[`${id}.${_vm._.get(repeat, 'name', name)}`]},on:{"values":(values, override) =>
                              _vm.updateValues(
                                values,
                                override,
                                `${field.repeat}.values.${id}`
                              ),"toggling":_vm.onFieldToggling,"change":_vm.onFieldChange,"focus":_vm.onFieldFocus,"toggle":_vm.onToggle,"fail":_vm.onFail,"emit":_vm.emit},model:{value:(
                            _vm.repeaters[field.repeat]['values'][id][name]
                          ),callback:function ($$v) {_vm.$set(_vm.repeaters[field.repeat]['values'][id], name, $$v)},expression:"\n                            repeaters[field.repeat]['values'][id][name]\n                          "}})}),_c('b-btn',{staticClass:"position-absolute",staticStyle:{"top":"-0.5rem","right":"-0.5rem","border-radius":"50%"},attrs:{"size":"sm","variant":"danger","title":_vm.getRepeaterLabel(field.repeat, 'delete')},on:{"click":function($event){return _vm.removeRepeater(field.repeat, id)}}},[_c('i',{staticClass:"fal fa-trash"})])],2)]}),_c('b-btn',{staticClass:"mb-4",staticStyle:{"margin-left":"-15px"},attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.addRepeater(field.repeat)}}},[_c('i',{staticClass:"fal fa-plus mr-1"}),_vm._v(" "+_vm._s(_vm.getRepeaterLabel(field.repeat, "add"))+" ")])]:[_c('form-field',{attrs:{"auth":_vm.auth,"size":_vm.size,"form":_vm.values,"width":_vm.width,"field":field,"labels":_vm.labels,"name":_vm._.get(field, 'name', name),"errors":_vm.errors[_vm._.get(field, 'name', name)]},on:{"enter":(e) => _vm.$emit('enter', e),"toggling":_vm.onFieldToggling,"change":_vm.onFieldChange,"values":_vm.updateValues,"focus":_vm.onFieldFocus,"toggle":_vm.onToggle,"fail":_vm.onFail,"emit":_vm.emit},model:{value:(_vm.values[_vm._.get(field, 'name', name)]),callback:function ($$v) {_vm.$set(_vm.values, _vm._.get(field, 'name', name), $$v)},expression:"values[_.get(field, 'name', name)]"}})]]:_vm._e()],2)]})]},proxy:true}],null,true)}):_vm._e()]})],2),_vm._t("footer",function(){return [(_vm.footer)?_c('footer',{staticClass:"form__footer"},[_c('b-button',{staticClass:"footer__submit-button",attrs:{"block":"","type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.confirm || _vm.__("save"))+" ")])],1):_vm._e()]})],2):_vm._t("fallback")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }