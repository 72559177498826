<template>
  <!-- Navigator -->
  <nav class="browser__navigator" ref="navigator">
    <!-- Navigator Menu -->
    <menu-tree
      v-if="data"
      v-model="selected"
      :preselect="true"
      :loading="loading"
      :items="data['items']"
      class="navigator__menu"
      @expand="onMenuTreeExpand"
    />
  </nav>
</template>

<script>
import MenuTree from "@/components/menu/MenuTree";
export default {
  components: {
    MenuTree,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    grid: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selected: {},
      timeout: 0,
      refresh: false,
      loading: false,
      loaded: false,
      data: {},
    };
  },
  watch: {
    "grid.id": function () {
      if (this.refresh) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.setData(), 500);
      } else if (!this.loaded) {
        this.setData();
      }
    },
    selected: function (item) {
      this.$emit("input", item);
    },
  },
  methods: {
    setData(payload = {}) {
      this.loading = true;
      this.fetchData(({ data }) => {
        this.$emit("data-loaded", data);
        this.loading = false;
        this.loaded = true;
        this.data = data;
      }, payload);
    },
    fetchData(callback, payload = {}) {
      let params = { ...this.$route["query"], ...payload };
      let resource = this.$route.params["resource"];
      let identity = this.grid["id"];
      this.$http
        .get(`${resource}/${identity}/menus/item/local`, { params })
        .then(({ data }) => callback(data));
    },
    onMenuTreeExpand() {
      return;
      // the code below was used to automatically scroll dpwn to prevent items being hidden
      let $navigator = this.$refs.navigator;
      this.$nextTick(() => ($navigator.scrollTop = $navigator.scrollHeight));
    },
  },
};
</script>