<template>
  <footer
    class="d-block d-md-flex align-items-baseline justify-content-between"
  >
    <!-- Limit Control -->
    <section class="footer__limit text-md-left">
      <label for="limitControl" class="mb-0">
        {{ __("index.footer.display") }}
      </label>
      <b-select
        size="sm"
        name="limit"
        @change="setLimit"
        v-if="limitOptions"
        v-model="activeLimit"
        :options="limitOptions"
        :disabled="!useLimitControl"
        style="width: 60px; cursor: pointer"
        class="mx-2"
      />
      <label for="limitControl" class="mb-0">
        {{ _.get(meta, "model.plural", "").toLowerCase() }}
      </label>
    </section>
    <!-- Status Message -->
    <section class="footer__status mt-3 mt-md-0">
      <!-- Progress Indicator -->
      <span v-if="loading">
        <i class="fal fa-spinner fa-spin mr-2" />
        {{ __("loading") }}
      </span>
      <!-- Error Feedback -->
      <span v-if="error && !loading" class="text-danger">
        {{ __("errorLoadingData") }}
        <b-button size="sm" @click.prevent="$emit('reload')" class="ml-2">
          <i class="fal fa-sync mr-1" /> {{ __("index.footer.reload") }}
        </b-button>
      </span>
      <!-- Result Count -->
      <span v-if="!loading && !error" v-html="resultCount" />
      <!-- Custom Message -->
      <span v-if="status" class="ml-1">&middot; {{ status }} </span>
    </section>
    <!-- Pagination -->
    <section class="footer__pagination text-md-right mt-3 mt-md-0">
      <b-button
        size="sm"
        variant="secondary"
        style="width: 30px"
        :disabled="!prevPage"
        @click.prevent="goToPrevPage"
        v-b-tooltip.bottom.hover="__('index.footer.previous')"
        ><i class="fal fa-chevron-left" style="margin-left: -2px" />
      </b-button>
      <span class="mx-2">
        <span v-if="loading">&nbsp;</span>
        <span v-else>{{ pageCount }}</span>
      </span>
      <b-button
        size="sm"
        variant="secondary"
        style="width: 30px"
        :disabled="!nextPage"
        @click.prevent="goToNextPage"
        v-b-tooltip.bottom.hover="__('index.footer.next')"
      >
        <i class="fal fa-chevron-right" style="margin-right: -2px" />
      </b-button>
    </section>
  </footer>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: null,
    },
    searching: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: null,
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeLimit: null,
      limitOptions: [25, 50, 100, 200],
    };
  },
  mounted() {
    this.activeLimit = this.limit;
  },
  computed: {
    prevPage() {
      if (this.meta && this.meta.current_page !== 1) {
        return this.meta.current_page - 1;
      }
    },
    nextPage() {
      const { current_page, last_page } = this.meta;
      if (current_page !== last_page) {
        return this.meta.current_page + 1;
      }
    },
    pageCount() {
      const { current_page, last_page } = this.meta;
      if (current_page && last_page) {
        return `${current_page || "0"} / ${last_page.toLocaleString("nl-NL")}`;
      }
    },
    resultCount() {
      const { from, to, total } = this.meta;
      let until = this.__("index.footer.until");
      let of = this.__("index.footer.of");
      if (total > 0) {
        return `${from} ${until} ${to} ${of} ${total
          .toLocaleString("nl-NL")
          .toLowerCase()} ${this.resultsLabel}`;
      } else if (total === 0) {
        return this.__("noResultsFound");
      } else if (_.isUndefined(total)) {
        return this.__("allResultsLoaded");
      }
    },
    resultsLabel() {
      let { singular, plural } = this.meta["model"];
      let rows = this.meta["total"] == 1 ? singular : plural;
      return this.searching ? this.__("index.footer.resultsFound") : rows;
    },
    useLimitControl() {
      return this.meta.total >= _.first(this.limitOptions);
    },
  },
  methods: {
    goToPrevPage() {
      this.$emit("reload", {
        "page[number]": this.prevPage,
      });
    },
    goToNextPage() {
      this.$emit("reload", {
        "page[number]": this.nextPage,
      });
    },
    setLimit(limit) {
      this.$emit("reload", {
        "page[number]": 1,
        "page[size]": limit,
      });
    },
  },
};
</script>
