import AuthService from "@/services/AuthService";
import router from "@/router";

export const namespaced = true;

export const state = {
	loading: false,
	error: null,
	user: null,
};

export const mutations = {
	SET_USER(state, user) {
		state.user = user;
	},
	SET_LOADING(state, loading) {
		state.loading = loading;
	},
	SET_MESSAGE(state, message) {
		state.message = message;
	},
	SET_ERROR(state, error) {
		state.error = error;
	},
};

export const actions = {
	logout({ commit }) {
		return AuthService.logout()
			.then(() => {
				commit("SET_USER", null);
				// if (router.currentRoute['name'] !== 'login') {
				// 	window.location.href = process.env.VUE_APP_ROOT_URL + '/auth/login';
				// }
			})
			.catch((error) => {
				commit("SET_ERROR", null);
			});
	},
	getAuthUser({ commit }) {
		commit("SET_LOADING", true);
		return AuthService.user()
			.then(({ data: { data } }) => {
				commit("SET_LOADING", false);
				commit("SET_USER", data);
			})
			.catch((error) => {
				commit("SET_LOADING", false);
				commit("SET_USER", null);
				commit("SET_ERROR", null);
			});
	},
};

export const getters = {
	authUser: (state) => {
		return state.user;
	},
	isAdmin: (state) => {
		return state.user && state.user.is_admin;
	},
	error: (state) => {
		return state.error;
	},
	loading: (state) => {
		return state.loading;
	},
	loggedIn: (state) => {
		return !!state.user;
	}
};
