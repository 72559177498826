<template>
  <resource-view name="send" v-slot="view">
    <app-modal
      @close="$emit('close')"
      @confirm="onModalConfirm"
      :loader="__('sending')"
      :note="`${__('beforeSending')}<br>${__('afterSending')}`"
      :confirm="__('send')"
      v-bind="view"
      size="xxl"
    >
      <article :class="{ loading }" style="min-height: 500px">
        <app-form
          v-model="input"
          :fieldset="fields"
          :invalid="errors"
          :loader="false"
          @print="$emit('print')"
        />
      </article>
    </app-modal>
  </resource-view>
</template>

<script>
const AppForm = () => import("@/components/app/AppForm");
import AppModal from "@/components/app/AppModal";
export default {
  components: {
    AppModal,
    AppForm,
  },
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
    abilities: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      input: {},
      fields: {},
      errors: {},
      loading: false,
    };
  },
  created() {
    this.setForm(() => this.setInput());
  },
  methods: {
    setForm(callback = null) {
      this.loading = true;
      this.$http
        .get(this.$resource.getApiPath("forms/item/send"))
        .then(({ data: { data } }) => {
          this.fields = data;
          this.loading = false;
          if (_.isFunction(callback)) callback();
        });
    },
    setInput() {
      _.forEach(this.$var2val(this.fields, this.values), (value, field) => {
        this.input[field] = _.toString(value);
      });
    },
    onModalConfirm() {
      if (this.abilities["update"] && this.abilities["send"]) {
        this.saveForm(() => this.sendMail());
      } else if (this.abilities["send"]) {
        this.sendMail();
      }
      this.$root.$emit("feedback", { key: "send" });
    },
    saveForm(callback) {
      this.loading = true;
      this.$store.dispatch("form/save", (data, error) => {
        if (error) return this.$emit("close");
        this.loading = false;
        callback();
      });
    },
    sendMail() {
      this.loading = true;
      this.$http
        .post(this.$resource.getApiPath("mail"), this.input)
        .then((response) => {
          this.$router.push({ name: "index" });
          this.$root.$emit("feedback", response);
          this.$emit("close");
        })
        .catch(({ response }) => {
          this.$root.$emit("feedback", response);
          this.errors = _.get(response, "data.errors");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>