<template>
  <nav class="files__menu" :class="{ loading }">
    <!-- Controls -->
    <header class="menu__header">
      <!-- Back Button -->
      <b-button
        size="sm"
        variant="secondary"
        @click="onBackClick"
        :disabled="breadcrumbs.length < 2"
      >
        <i class="fal fa-arrow-left mr-1"></i>
        {{ __("Vorige | Previous") }}
      </b-button>
      <!-- Home Button -->
      <span class="home-btn mr-2">
        <i class="btn__icon fal fa-home" @click="onHomeClick"></i>
        <i class="fal fa-angle-right ml-2" v-if="breadcrumbs.length > 1"></i>
      </span>
      <!-- Breadcrumbs -->
      <ul class="breadcrumbs">
        <li
          @click="onBreadcrumbClick(item)"
          v-for="(item, key) in breadcrumbs"
          :key="key"
        >
          <i class="fal fa-angle-right mx-2" v-if="key > 1"></i>
          <i class="fal fa-folder mr-1" v-if="key > 0"></i>
          <span :class="{ breadcrumb__link: key < breadcrumbs.length - 1 }">
            {{ item }}
          </span>
        </li>
      </ul>
    </header>
    <template v-if="_.size(files) > 0">
      <!-- Files Menu -->
      <menu-tree
        v-if="_.size(files) > 0"
        v-model="selected"
        :loading="loading"
        :items="menuItems"
        @select="onSelect"
      />
    </template>
    <!-- No Files -->
    <b-alert v-else-if="!loading" show variant="info">
      {{ __("Deze map is leeg. | This folder is empty.") }}
    </b-alert>
  </nav>
</template>

<script>
import MenuTree from "@/components/menu/MenuTree";
export default {
  inheritAttrs: false,
  components: {
    MenuTree,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    grid: {
      type: Object,
      default: () => ({}),
    },
    navigator: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      files: [],
      selected: {},
      location: "",
      loading: false,
    };
  },
  watch: {
    "grid.id": {
      immediate: true,
      handler: function (value) {
        if (!_.isEmpty(value)) {
          this.setFiles();
        }
      },
    },
    files: {
      immediate: true,
      handler: function (value) {
        if (!_.isEmpty(value)) {
          this.$emit("input", value);
        }
      },
    },
  },
  computed: {
    apiUrl() {
      return `${this.$route.params["resource"]}/${this.grid["id"]}/files`;
    },
    menuItems() {
      return this.files.map(({ name, path, type }) => ({
        label: name,
        action: path,
        icon: this.getIcon(type),
        meta: { type },
      }));
    },
    breadcrumbs() {
      return this.location.split("/");
    },
  },
  methods: {
    setFiles(params = {}) {
      this.loading = true;
      this.files = [];
      this.$http
        .get(this.apiUrl, { params })
        .then(({ data: { data } }) => (this.files = data))
        .finally(() => (this.loading = false));
    },
    openFile(path) {
      const route = {
        name: "file",
        query: { path, menu: false },
        params: {
          identity: this.grid["id"],
          resource: this.$route.params["resource"],
        },
      };
      const url = this.$route2url(route);
      window.open(url, "_blank");
    },
    onSelect({ action, meta }) {
      if (meta["type"] == "dir") {
        this.setFiles({ path: action });
        this.location = action;
      } else {
        this.openFile(action);
      }
    },
    onBackClick() {
      const items = this.breadcrumbs.slice(0, -1);
      const path = items.join("/");
      this.setFiles({ path });
      this.location = path;
    },
    onHomeClick() {
      this.setFiles({ path: "/" });
      this.location = "";
    },
    onBreadcrumbClick(item) {
      const index = this.breadcrumbs.indexOf(item);
      const items = this.breadcrumbs.slice(0, index + 1);
      const path = items.join("/");
      this.setFiles({ path });
      this.location = path;
    },
    getIcon(type) {
      const ext = _.toLower(type);
      return (
        {
          dir: "folder",
          doc: "file-word",
          docx: "file-word",
          xls: "file-excel",
          xlsx: "file-excel",
          ppt: "file-powerpoint",
          pptx: "file-powerpoint",
          pdf: "file-pdf",
          jpg: "file-image",
          jpeg: "file-image",
          png: "file-image",
          gif: "file-image",
          bmp: "file-image",
          zip: "file-archive",
          dot: "file-edit",
          dotx: "file-edit",
        }[ext] ?? "file-alt"
      );
    },
  },
};
</script>