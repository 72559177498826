<template>
  <data-table-vert :data="data" :class="{ loading }" class="data--vert-table" />
</template>

<script>
import DataTableVert from "@/components/data/DataTableVert";
export default {
  inheritAttrs: false,
  components: {
    DataTableVert,
  },
  props: {
    grid: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    navigator: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      data: {},
      loading: false,
      resource: null,
    };
  },
  beforeMount() {
    this.setResource();
    this.setData();
  },
  watch: {
    "grid.id": "setData",
  },
  computed: {
    params() {
      return { view: this.navigator["view"] || true };
    },
  },
  methods: {
    setResource() {
      this.resource = _.get(
        this.navigator,
        "action.params.resource",
        this.$route.params["resource"]
      );
    },
    setData() {
      this.loading = true;
      this.$http
        .get(`${this.resource}/${this.grid["id"]}`, { params: this.params })
        .then(({ data: { data } }) => (this.data = data))
        .finally(() => (this.loading = false));
    },
  },
};
</script>