<template>
  <!-- Data (Select) Field -->
  <section class="control--data">
    <data-select
      :editable="editable"
      :loading="loading"
      :layout="layout"
      :style="style"
      :size="size"
      :meta="meta"
      :data="data"
      @select="onSelect"
      @input="onInput"
    />
  </section>
</template>

<script>
import DataSelect from "@/components/data/DataSelect";
export default {
  components: {
    DataSelect,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: String | Array | Object,
      default: null,
    },
  },
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.setApiFilters(() => this.setData());
      },
    },
    value: {
      deep: true,
      handler: function (value) {
        this.input = value;
      },
    },
    input: {
      deep: true,
      handler: function (input) {
        this.$emit("input", input);
      },
    },
  },
  data() {
    return {
      apiFilters: {},
      loading: false,
      loaded: false,
      error: false,
      input: {},
      data: [],
      meta: {},
    };
  },
  computed: {
    settings() {
      return _.get(this.field, "settings.grid", {});
    },
    apiUrl() {
      return this.settings.source;
    },
    apiParams() {
      return this.settings.params || {};
    },
    size() {
      return this.settings.size || "md";
    },
    layout() {
      return this.settings.layout || "grid";
    },
    editable() {
      return this.settings.editable || false;
    },
    style() {
      return `height: ${this.field.height}px;`;
    },
  },
  methods: {
    onInput(input) {
      if (!this.editable) return;
      if (!_.isEmpty(input)) {
        let key = _.get(input, "__data.value");
        this.input[key] = input;
      }
      this.$emit("input", this.input);
    },
    onSelect(select) {
      if (this.editable) return;
      this.$emit("input", select);
    },
    setData() {
      this.loading = true;
      this.loaded = false;
      this.error = false;
      this.data = [];
      this.$http
        .get(this.apiUrl, {
          params: {
            ...this.getApiFilters(),
            ...this.apiParams,
          },
        })
        .then(({ data: { data, meta } }) => {
          this.data = data;
          this.meta = meta;
        })
        .catch((this.error = true))
        .finally(() => {
          this.loading = false;
          this.loaded = true;
        });
    },
    setApiFilters(callback) {
      var apiFilters = {};
      let filters = this.settings.filters || {};
      if (_.isEmpty(filters)) return {};
      _.forEach(filters, (column, key) => {
        let field = _.isString(key) ? key : column;
        let value = _.get(this.form, field);
        let filter = _.get(value, "value", value);
        if (!_.isEmpty(filter)) apiFilters[column] = filter;
      });
      if (!_.isEmpty(apiFilters) && !_.isEqual(apiFilters, this.apiFilters)) {
        this.apiFilters = apiFilters;
        if (callback) callback(apiFilters);
      }
    },
    getApiFilters() {
      return this.$objectToQueryParams(this.apiFilters, "filter");
    },
    clear() {
      this.input = {};
      this.data = [];
    },
  },
};
</script>