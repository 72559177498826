<template>
  <context-menu ref="menu" :class="classNames" class="dropdown-menu">
    <!-- Context Menu for Grid Header -->
    <template v-if="scope == 'header'">
      <li
        :key="index"
        v-for="(direction, index) in [null, 'asc', 'desc']"
        :class="{ active: sorts[sortColumn] == direction }"
        @click="addSort(direction)"
        class="dropdown-item"
      >
        {{
          __(`index.header.sort.${direction}`) ||
          __("index.header.sort.default")
        }}
        <i
          class="item-legend-icon fal"
          :class="{
            'fa-sort-amount-down': direction == 'desc',
            'fa-sort-amount-up': direction == 'asc',
          }"
        />
      </li>
    </template>
    <!-- Context Menu for Grid Cells -->
    <template v-else-if="scope == 'cell'">
      <template v-if="useFinder">
        <!-- Add Default Finder -->
        <li class="dropdown-item" @click.prevent="addFinder()">
          {{ __("finder.add") }}
          <span class="item-legend-icon">
            {{ operatorSymbols[1] }}
          </span>
        </li>
        <!-- Remove Finder -->
        <li
          v-if="finderActive"
          class="dropdown-item fadeIn"
          @click.prevent="removeFinder()"
        >
          {{ __("finder.remove") }}
        </li>
        <div class="dropdown-divider"></div>
        <!-- Change Finder -->
        <li class="dropdown-submenu">
          <span class="dropdown-item dropdown-toggle" data-toggle="dropdown">
            {{ __(`finder.${finderActive ? "change" : "choose"}`) }}
          </span>
          <ul class="dropdown-menu" v-if="operators">
            <!-- Change Finder Operator -->
            <li
              :key="index"
              class="dropdown-item"
              v-for="(operator, index) in operators"
              @click.prevent="addFinder({ operator: operator['value'] })"
              :class="getFinderOperatorClass(operator)"
            >
              {{ _.capitalize(operator["text"]) }}
              <span class="item-legend-icon">
                {{ getOperatorSymbol(operator["value"]) }}
              </span>
            </li>
            <div class="dropdown-divider" v-if="finderActive"></div>
            <!-- Clear Finder -->
            <li
              v-if="finderActive"
              class="dropdown-item"
              @click.prevent="clearFinder()"
            >
              {{ __("finder.cancel") }}
            </li>
            <!-- Cancel Finder Step -->
            <li class="dropdown-submenu" v-if="finderActive">
              <span
                class="dropdown-item dropdown-toggle"
                data-toggle="dropdown"
              >
                {{ __("finder.cancelStep") }}
              </span>
              <ul class="dropdown-menu">
                <li
                  :key="index"
                  class="dropdown-item"
                  v-for="(find, index) in finder"
                  @click.prevent="removeFinder(find['column'])"
                >
                  {{ find["column"] }}
                  {{ getOperatorSymbol(find["operator"]) }}
                  {{ find["keywords"] }}
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <div class="dropdown-divider"></div>
      </template>
      <template v-if="_.some(actions)">
        <!-- Manage Data -->
        <li class="dropdown-submenu">
          <span class="dropdown-item dropdown-toggle" data-toggle="dropdown">{{
            __("finder.manageData")
          }}</span>
          <!-- Data Management Options (CRUD) -->
          <ul class="dropdown-menu">
            <template v-for="(action, index) in actions">
              <li
                :key="index"
                class="dropdown-item"
                @click.prevent="onActionClick(action)"
              >
                {{ __(`actions.${action}`) }}
                <i
                  class="item-legend-icon fal"
                  :class="{
                    'fa-eye': action == 'show',
                    'fa-plus': action == 'create',
                    'fa-pencil': action == 'edit',
                    'fa-trash': action == 'delete',
                  }"
                />
              </li>
            </template>
          </ul>
        </li>
      </template>
      <div class="dropdown-divider"></div>
      <!-- Copy to clipboard -->
      <li class="dropdown-item" @click.prevent="$copy(selected['value'])">
        {{ __("finder.copyCellValue") }}
      </li>
      <template v-if="false">
        <div class="dropdown-divider"></div>
        <!-- Export Data -->
        <li class="dropdown-submenu">
          <span class="dropdown-item dropdown-toggle" data-toggle="dropdown">{{
            __("actions.exportTo")
          }}</span>
          <!-- Data Export Options -->
          <ul class="dropdown-menu">
            <template v-for="(option, index) in ['excel', 'word', 'pdf']">
              <li
                :key="index"
                class="dropdown-item"
                @click.prevent="$emit('export', option)"
              >
                {{ __(`files.${option}`) }}
                <i class="item-legend-icon" :class="`fal fa-file-${option}`" />
              </li>
            </template>
          </ul>
        </li>
      </template>
    </template>
  </context-menu>
</template> 

<script>
import contextMenu from "vue-context-menu";
export default {
  components: {
    contextMenu,
  },
  props: {
    finder: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
    abilities: {
      type: Object,
      default: () => ({}),
    },
    operators: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    sorts: {
      type: Object,
      default: () => ({}),
    },
    meta: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      scope: null,
      window: {},
      classNames: {},
      sortColumn: null,
      operatorSymbols: {
        1: "=",
        2: "<=",
        3: ">=",
        4: "<>",
        5: "..",
        6: "* ",
      },
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    useFinder() {
      return (
        this.abilities["search"] &&
        this.columns.includes(this.selected["column"])
      );
    },
    finderActive() {
      let finder = this.finder[this.selected["column"]];
      return !_.isEmpty(finder) && !finder["fixed"];
    },
    actions() {
      return this.$getActionRoutes(this.abilities);
    },
  },
  methods: {
    addFinder(find) {
      this.$emit("add-finder", {
        column: _.get(find, "column", this.selected["column"]),
        operator: _.get(find, "operator", 1),
        keywords: _.get(find, "keywords", this.selected["value"]),
        focus: false,
      });
      this.$emit("search");
    },
    removeFinder(column) {
      this.$emit("remove-finder", column || this.selected["column"]);
      this.$emit("search");
    },
    clearFinder() {
      this.$emit("clear-finder");
      this.$emit("search");
    },
    getFinderOperatorClass({ disabled, active }) {
      return { disabled, active };
    },
    getOperatorSymbol(operator) {
      return this.operatorSymbols[operator];
    },
    addSort(direction) {
      let column = this.sortColumn;
      this.$emit("add-sort", { column, direction });
    },
    onResize() {
      this.window["width"] = window["innerWidth"];
      this.window["height"] = window["innerHeight"];
    },
    open(event) {
      this.setMenuContextState(event);
      this.setMenuContextClass(event);
      this.setNextSortColumn(event);
      this.$refs["menu"].open();
    },
    setMenuContextState(event) {
      const eventSourceClass = event.srcElement.offsetParent.className;
      this.scope = _.includes(eventSourceClass, "ag-header-cell")
        ? "header"
        : "cell";
    },
    setMenuContextClass(event) {
      let multiplier = this.finderActive ? 3 : 2;
      let realestate = this.window.width - event.clientX < 160 * multiplier;
      this.classNames = realestate ? "submenu--left" : null;
    },
    setNextSortColumn({ srcElement }) {
      if (this.scope === "header") {
        this.sortColumn = srcElement.offsetParent.attributes["col-id"]["value"];
      }
    },
    onActionClick(action) {
      this.$emit("action", action);
    },
  },
};
</script>