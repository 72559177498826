<template>
  <article>
    <b-alert>In ontwikkeling</b-alert>
  </article>
</template>

<script>
import AuthService from "@/services/AuthService";
export default {
  beforeMount() {
    alert('Deze functionaliteit wordt momenteel nog niet ondersteund. Onze excuses voor het ongemak.');
  //   AuthService.verifyEmail("...")
  //     .then(({ data: { message } }) => {
  //       // redirect
  //     })
  //     .catch(({ response: { data } }) => {
  //       // show error
  //     })
  //     .finally(() => (this.loading = false));
  },
};
</script>