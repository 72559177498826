<template>
  <!-- Controls -->
  <header class="grid__header d-flex align-items-center pt-0 text-nowrap">
    <template v-if="view['title']">
      <h3 class="mt-2 mb-0 mr-3 font-weight-light d-flex align-items-center">
        <small
          v-if="view['icon']"
          :class="`fal fa-${view['icon']} mr-2 ml-1`"
          style="margin-top: 1px"
        />
        {{ view["title"] }}
      </h3>
    </template>
    <!-- Toolbar -->
    <grid-toolbar
      :meta="meta"
      :selected="selected"
      :abilities="abilities"
      v-if="!_.isEmpty(selected)"
      @action="(action) => $emit('action', action)"
      @export="(option) => $emit('export', option)"
      @copy="$emit('copy')"
    />
    <!-- Filter/View -->
    <section
      v-if="_.some(filters)"
      class="flex-fill mt-2 mr-4"
      v-b-tooltip.bottom.hover="__('index.setFilterView')"
    >
      <b-form-select
        size="sm"
        class="w-auto"
        v-model="filter"
        :options="filterOptions"
        @change="(filter) => $emit('filter', filter)"
      />
    </section>
    <!-- Search Fields -->
    <grid-search-fields
      v-if="abilities['search'] && meta.total > 0"
      :operators="operators"
      :selected="selected"
      :columns="columns"
      :view="view"
      v-model="finder"
      @change-finder="(column, find) => $emit('change-finder', column, find)"
      @remove-finder="(column) => $emit('remove-finder', column)"
      @searching="(keywords) => $emit('searching', keywords)"
      @add-finder="(query) => $emit('add-finder', query)"
      @clear-finder="$emit('clear-finder')"
      @search="$emit('search')"
      class="d-flex flex-wrap w-100"
      style="margin-top: 1px"
      :key="componentKey"
    />
  </header>
</template> 

<script>
import GridSearchFields from "@/components/grid/GridSearchFields";
import GridToolbar from "@/components/grid/GridToolbar";
import AppModal from "@/components/app/AppModal";
export default {
  components: {
    GridSearchFields,
    GridToolbar,
    AppModal,
  },
  props: {
    finder: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    abilities: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    operators: {
      type: Array,
      default: () => [],
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
    view: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filter: null,
      window: window,
      componentKey: 0,
    };
  },
  mounted() {
    if (!_.isEmpty(this.filters)) {
      _.forEach(this.filters, ({ selected, value }) => {
        if (selected) this.filter = value;
      });
    }
  },
  computed: {
    filterOptions() {
      return [{ text: this.__("everything") }, ...this.filters];
    },
  },
  methods: {
    forceUpdate() {
      // we use this fix to force re-rendering the component and its data,
      // as adviced here: https://michaelnthiessen.com/force-re-render/
      this.componentKey++;
    },
  },
};
</script>