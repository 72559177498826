var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[(_vm.uuid)?[_c('b-alert',{staticClass:"mb-0",attrs:{"variant":"info","show":_vm.uuid && _vm.loading}},[_c('i',{staticClass:"fal fa-spin fa-spinner mr-1"}),_vm._v(" "+_vm._s(_vm.__("U wordt nu ingelogd... | You are being logged in..."))+" ")]),_c('b-alert',{staticClass:"mb-0",attrs:{"variant":"danger","show":!_vm._.isEmpty(_vm.message)}},[_c('i',{staticClass:"fal fa-frown mr-1"}),_vm._v(" "+_vm._s(_vm.message)+" ")])]:[_c('form-field',{staticClass:"d-block mb-3",attrs:{"errors":_vm.errors['username'],"auth":true,"field":{
        name: 'username',
        label: _vm.__('Uw gebruikersnaam/e-mailadres: | Your username/email:'),
        placeholder: _vm.__(
          'Voer uw gebruikersnaam of e-mailadres in | Enter your username or email'
        ),
        control: 'input',
        icon: 'user',
        autofocus: true,
        required: true,
        class: 'text-center',
      }},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('form-field',{staticClass:"d-block mb-3",attrs:{"errors":_vm.errors['password'],"auth":true,"field":{
        name: 'password',
        label: _vm.__('Uw wachtwoord: | Your password:'),
        placeholder: _vm.__('Voer uw wachtwoord in | Enter your password'),
        control: 'input',
        type: 'password',
        required: true,
        icon: 'key',
        class: 'text-center',
      }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"d-flex justify-content-between align-content-center mt-3"},[_c('b-form-checkbox',{staticClass:"text-secondary mb-0",attrs:{"unchecked-value":false,"name":"remember"},model:{value:(_vm.remember),callback:function ($$v) {_vm.remember=$$v},expression:"remember"}},[_vm._v(" "+_vm._s(_vm.__("Onthoud mij | Remember me"))+" ")]),_c('router-link',{attrs:{"to":_vm.forgotRoute}},[_vm._v(" "+_vm._s(_vm.__("Wachtwoord vergeten? | Forgot your password?"))+" ")])],1),_c('submit-button',{staticClass:"mt-3",attrs:{"loading":_vm.loading},on:{"submit":_vm.login}},[_c('i',{staticClass:"fal fa-lock mr-1"}),_vm._v(" "+_vm._s(_vm.__("Inloggen | Login"))+" ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }