<template>
  <footer-basic v-bind="$props">
    <!-- Print Button -->
    <button-print
      v-if="abilities['print']"
      :abilities="abilities"
      :button="buttons['print']"
      @click="print = true"
    />
    <!-- Print Dialog -->
    <dialog-print
      v-if="print"
      @close="print = false"
      source="https://google.com"
    />
  </footer-basic>
</template>

<script>
import FooterBasic from "@/components/item/Footers/FooterBasic";
import ButtonPrint from "@/components/item/Buttons/ButtonPrint";
import DialogPrint from "@/components/dialogs/DialogPrint";
export default {
  components: {
    FooterBasic,
    ButtonPrint,
    DialogPrint,
  },
  props: {
    abilities: {
      type: Object,
      default: () => ({}),
    },
    buttons: {
      type: Object,
      default: () => ({}),
    },
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      print: false,
    };
  },
};
</script>