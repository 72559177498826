<template>
  <div :class="editorClass" class="editor">
    <b-button-toolbar
      :class="{
        'toolbar--hidden': autohide,
        'toolbar--focused': autohide ? focused : true,
      }"
    >
      <b-button-group
        :size="fieldSize"
        class="toolbar__tools"
        v-if="!field.readonly && !field.disabled"
      >
        <template v-for="(button, name) in toolbar">
          <b-button
            class="tools__button"
            :key="name"
            :variant="buttonVariant"
            @click="onButtonClick(name)"
            :class="{
              active: isHeading(name)
                ? editor.isActive('heading', { level: getHeadingLevel(name) })
                : editor.isActive(_.camelCase(name)),
            }"
            :style="{ display: button.active ? 'initial' : 'none' }"
          >
            <i :class="`fal fa-${button.icon}`" v-if="!isHeading(name)" />
            <span v-if="isHeading(name)">H{{ getHeadingLevel(name) }}</span>
          </b-button>
        </template>
      </b-button-group>
    </b-button-toolbar>
    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
export default {
  components: {
    Editor,
    EditorContent,
  },
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "md",
      validator: function (value) {
        return ["sm", "md", "lg"].indexOf(value.toLowerCase()) !== -1;
      },
    },
  },
  mounted() {
    this.setEditor();
    this.setToolbar();
  },
  watch: {
    value(content) {
      if (!_.isEqual(content, this.editor.getHTML())) {
        this.editor.commands.setContent(content, false);
      }
    },
  },
  data() {
    return {
      buttons: {
        Bold: { active: true, icon: "bold" },
        Italic: { active: true, icon: "italic" },
        Underline: { active: false, icon: "underline" },
        Strike: { active: true, icon: "strikethrough" },
        BulletList: { active: true, icon: "list-ul" },
        OrderedList: { active: true, icon: "list-ol" },
        Paragraph: { active: false, icon: "paragraph" },
        // begin exceptional cases (implemented differently)
        H1: { active: false, icon: false },
        H2: { active: false, icon: false },
        H3: { active: false, icon: false },
        H4: { active: false, icon: false },
        H5: { active: false, icon: false },
        H6: { active: false, icon: false },
        // end exceptional cases
        Code: { active: false, icon: "code" },
        Blockquote: { active: false, icon: "quote-left" },
        HorizontalRule: { active: true, icon: "horizontal-rule" },
      },
      headingRegex: /^[h][1-6]+$/i,
      focused: false,
      timeout: null,
      toolbar: null,
      content: null,
      editor: null,
    };
  },
  computed: {
    editorClass() {
      return {
        "editor--small": this.fieldSize == "sm",
        "editor--large": this.fieldSize == "lg",
        "editor--light": this.useLightTheme(),
        "editor--toolbar": this.useToolbar(),
        "editor--full-width": this.useFullWidth(),
      };
    },
    buttonVariant() {
      return this.useLightTheme() ? "outline-secondary" : "";
    },
    autohide() {
      return _.get(this.field, "settings.autohide", false);
    },
    fieldSize() {
      return this.field.size || this.size || "md";
    },
    editorStyles() {
      return {
        "min-height": this.height + "px",
      };
    },
  },
  methods: {
    useLightTheme() {
      return _.get(this.field, "settings.light", true);
    },
    useFullWidth() {
      return _.get(this.field, "settings.fullwidth", false);
    },
    useToolbar() {
      return _.get(this.field, "settings.buttons") != false;
    },
    hasButton(button) {
      return this.buttons.includes(button);
    },
    isHeading(name) {
      return this.headingRegex.test(name);
    },
    getHeadingLevel(name) {
      return parseInt(name.charAt(name.length - 1));
    },
    getToolbar(buttons, showAll = false) {
      let isArray = Array.isArray(buttons);
      let toolbar = _.mapValues(buttons, (value, key) => {
        return {
          name: isArray ? value : key,
          icon: this.buttons[isArray ? value : key].icon,
          active:
            showAll || isArray
              ? value
              : _.has(value, "active")
              ? value.active
              : value,
        };
      });
      return _.mapKeys(toolbar, function (value, key) {
        return value.name;
      });
    },
    setEditor() {
      this.editor = new Editor({
        onUpdate: () => {
          this.$emit("input", this.editor.getHTML());
        },
        onFocus: () => (this.focused = true),
        onBlur: () => (this.focused = false),
        editable: !this.field.readonly,
        extensions: [StarterKit],
      });
    },
    setToolbar() {
      if (
        _.has(this.field, "settings.buttons") &&
        this.field.settings.buttons != "all"
      ) {
        let defaultToolbar = this.getToolbar(this.buttons);
        let customToolbar = this.getToolbar(this.field.settings.buttons);
        this.toolbar = Array.isArray(this.field.settings.buttons)
          ? _.merge(defaultToolbar, customToolbar)
          : customToolbar;
      } else {
        this.toolbar = this.getToolbar(
          this.buttons,
          _.get(this.field, "settings.buttons") == "all"
        );
      }
    },
    onButtonClick(name) {
      let editor = this.editor.chain().focus();
      if (this.isHeading(name)) {
        return editor
          .toggleHeading({ level: this.getHeadingLevel(name) })
          .run();
      } else if (name == "HorizontalRule") {
        return editor.setHorizontalRule().run();
      }
      return editor[`toggle${name}`]().run();
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>