var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor",class:_vm.editorClass},[_c('b-button-toolbar',{class:{
      'toolbar--hidden': _vm.autohide,
      'toolbar--focused': _vm.autohide ? _vm.focused : true,
    }},[(!_vm.field.readonly && !_vm.field.disabled)?_c('b-button-group',{staticClass:"toolbar__tools",attrs:{"size":_vm.fieldSize}},[_vm._l((_vm.toolbar),function(button,name){return [_c('b-button',{key:name,staticClass:"tools__button",class:{
            active: _vm.isHeading(name)
              ? _vm.editor.isActive('heading', { level: _vm.getHeadingLevel(name) })
              : _vm.editor.isActive(_vm._.camelCase(name)),
          },style:({ display: button.active ? 'initial' : 'none' }),attrs:{"variant":_vm.buttonVariant},on:{"click":function($event){return _vm.onButtonClick(name)}}},[(!_vm.isHeading(name))?_c('i',{class:`fal fa-${button.icon}`}):_vm._e(),(_vm.isHeading(name))?_c('span',[_vm._v("H"+_vm._s(_vm.getHeadingLevel(name)))]):_vm._e()])]})],2):_vm._e()],1),_c('editor-content',{staticClass:"editor__content",attrs:{"editor":_vm.editor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }