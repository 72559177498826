<template>
  <resource-view ref="$view" :parent="true" group="user" base="provim" v-slot="view">
    <article class="content--messages">
      <app-header v-bind="view" size="sm">
        <template slot="buttons">
          <b-button variant="secondary">
            <i class="fal fa-plus mr-2" />Nieuw bericht
          </b-button>
        </template>
      </app-header>
      <div class="table-responsive mb-0">
        <table class="table">
          <thead>
            <th>Afzender</th>
            <th>Onderwerp</th>
            <th>Datum</th>
          </thead>
          <tbody>
            <tr>
              <td>Martijn Kuipers</td>
              <td>Wijziging naar aanleiding van verzoek</td>
              <td>Een uur geleden</td>
            </tr>
            <tr>
              <td>PropertyView</td>
              <td>Welkom op uw applicatie</td>
              <td>1 dag geleden</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </resource-view>
</template>

<script>
import AppHeader from "@/components/app/AppHeader";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>