<template>
  <section :class="`item__button order-${order || 1}`">
    <b-button
      v-if="label"
      v-bind="$attrs"
      :variant="variant"
      v-b-tooltip.hover="tooltip"
      @click="(e) => $emit('click', e)"
    >
      <i v-if="icon" :class="`mr-1 fal fa-${icon}`" /> {{ label }}
    </b-button>
    <slot></slot>
  </section>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: "secondary",
    },
    order: {
      type: Number,
      default: 10,
    },
  },
};
</script>