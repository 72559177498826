<script>
export default {
  beforeCreate() {
    this.$http.interceptors.response.use(
      (response) => {
        const failover = response.headers["x-failover-active"] == "1";
        if (failover && !localStorage.getItem("failover")) {
          localStorage.setItem("failover", failover);
          this.$root.$emit("feedback", {
            group: "failover",
            key: "enabled",
          });
        } else if (!failover && localStorage.getItem("failover")) {
          localStorage.removeItem("failover");
          this.$root.$emit("feedback", {
            group: "failover",
            key: "disabled",
          });
        }
        return Promise.resolve(response);
      },
      (error) => {
        let code = _.get(error, "response.status", 500);
        let key = `error_${code}`;
        let group = "error";
        this.$root.$emit("feedback", { key, group });
        if (this.$route.name !== "login") {
          if ([401, 419].includes(code)) {
            return this.$router.push({ name: "login" });
          }
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>
