<template>
  <resource-buttons :base="resource" group="item" v-slot="buttons">
    <b-card
      no-body
      :id="resource"
      class="card--clean"
      footer-class="mt-auto px-3"
    >
      <template slot="header">
        <header class="card__header d-flex">
          <span>
            <i :class="`fal fa-${icon} mr-1`" />
            {{ title }}
          </span>
          <router-link
            :to="{ name: 'index', params: { resource } }"
            class="ml-auto"
          >
            <i :class="`fal fa-${_.get(buttons, 'index.icon')} mr-1`" />
            {{ _.get(buttons, "index.label") }}
          </router-link>
        </header>
      </template>
      <article
        style="min-height: 150px"
        class="card__content"
        :class="{ loading }"
      >
        <b-list-group flush class="content__list" v-if="data">
          <b-list-group-item
            @click="onRowClick(row)"
            v-for="(row, index) in data"
            :button="true"
            :key="index"
          >
            {{ _.get(row, "__data.title") || __("untitled") }}
          </b-list-group-item>
        </b-list-group>
      </article>
      <template slot="footer">
        <b-button
          block
          :variant="_.get(buttons, 'create.variant')"
          v-if="button && abilities['create']"
          v-b-tooltip.hover="_.get(buttons, 'create.tooltip')"
          @click="$router.push({ name: 'create', params: { resource } })"
        >
          <i :class="`fal fa-${_.get(buttons, 'create.icon')} mr-1`" />
          {{ _.get(buttons, "create.label") }}
        </b-button>
      </template>
    </b-card>
  </resource-buttons>
</template>

<script>
export default {
  props: {
    resource: {
      type: String,
      default: null,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean | Number,
      default: 1,
    },
    button: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number | String,
      default: "5",
    },
    next_route: {
      type: String,
      default: "show",
    },
  },
  data() {
    return {
      meta: {},
      data: null,
      loading: false,
    };
  },
  beforeMount() {
    this.setData();
  },
  computed: {
    abilities() {
      return _.get(this.meta, "model.abilities", {});
    },
  },
  methods: {
    setData() {
      this.loading = true;
      this.$http
        .get(this.resource, {
          params: {
            view: true,
            "page[size]": this.limit,
          },
        })
        .then(({ data: { data, meta } }) => {
          this.data = data;
          this.meta = meta;
        })
        .finally(() => (this.loading = false));
    },
    onRowClick({ __data: { value } }) {
      this.$router.push({
        name: this.abilities["update"] ? "edit" : "show",
        params: {
          resource: this.resource,
          identity: value,
        },
      });
    },
  },
};
</script>