<template>
  <!-- Menu -->
  <ul class="menu__items" v-if="_.some(items)">
    <!-- Menu Item -->
    <li
      :key="key"
      class="menu__item"
      v-for="(item, key) in items"
      :class="{
        'item--disabled': item['disabled'],
        'item--parent': _.some(item['items']),
        'item--child': level > 1,
      }"
    >
      <div class="item__content" :class="{ 'item--active': isActive(item) }">
        <!-- Menu Item Label -->
        <div :class="`item__label level--${level}`" @click="onItemClick(item)">
          <!-- Label Icon -->
          <span
            :class="`item__icon fal fa-${item['icon']}`"
            v-if="item['icon']"
          />
          <!-- Label Text -->
          <span class="label__text" v-text="item['label']" />
          <!-- Label Warning "Slow Query" -->
          <span
            class="item__icon text-danger fal fa-turtle"
            v-if="item['slow']"
          />
          <!-- Label Info "Loading" -->
          <!-- <span
            class="item__icon fal fa-spinner fa-spin"
            v-if="item['slow'] && loading"
          /> -->
        </div>
        <!-- Subitems Toggle Button -->
        <button
          class="item__toggler"
          @click="onToggle(item)"
          v-if="_.some(item['items']) && !expand"
          v-b-tooltip.hover="__(getToggleAction(item))"
        >
          <!-- Toggle Icon -->
          <span
            class="toggler__icon fal"
            :class="{
              'fa-minus': getToggleAction(item) === 'collapse',
              'fa-plus': getToggleAction(item) === 'expand',
            }"
          />
        </button>
      </div>
      <!-- Submenu Items -->
      <menu-tree
        v-if="showSubMenu(item)"
        v-model="selected"
        :expand="expand"
        :level="level + 1"
        :items="item['items']"
        @select="onItemClick"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: "menu-tree",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: String | Object,
      default: null,
    },
    items: {
      type: Object | Array,
      default: () => ({}),
    },
    level: {
      type: Number,
      default: 1,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    preselect: {
      type: Number | Boolean,
      default: null,
    },
  },
  data() {
    return {
      expanded: [],
      selected: {},
    };
  },
  watch: {
    value: function (item) {
      this.selected = item;
    },
    selected: function (item) {
      this.$emit("input", item);
    },
    items: function (items) {
      if (this.preselect !== null && this.preselect !== false) {
        let key = _.isNumber(this.preselect) ? this.preselect - 1 : 0;
        let item = _.values(items)[key];
        this.onItemClick(item);
      }
    },
  },
  methods: {
    isActive(item) {
      if (!item["action"]) return;
      return (
        _.isEqual(this.active, item["action"]) ||
        _.isEqual(this.value, item) ||
        this.isNestedActive(item)
      );
    },
    isNestedActive(item) {
      var match = false;
      if (item["items"]) {
        _.forEach(item["items"], (subitem) => {
          if (this.isActive(subitem)) match = true;
        });
      }
      return match;
    },
    getToggleAction({ action }) {
      return this.expanded.includes(action) ? "collapse" : "expand";
    },
    onToggle({ action }) {
      if (this.expanded.includes(action)) {
        this.expanded = _.remove(this.expanded, action);
        this.$emit("collapse", action);
      } else {
        this.expanded.push(action);
        this.$emit("expand", action);
      }
    },
    onItemClick(item) {
      if (item["action"] && !item["disabled"]) {
        this.selected = item;
        this.$emit("select", item);
      } else if (!_.isEmpty(item["items"])) {
        this.onToggle(item);
      }
    },
    showSubMenu({ action, items }) {
      return _.some(items) && (this.expanded.includes(action) || this.expand);
    },
  },
};
</script>