<template>
  <resource-model v-slot="model">
    <resource-view :parent="true" v-slot="view" ref="$view">
      <section
        :class="`content__container item__edit manage--${$route['name']} 
          ${loading ? 'loading' : ''}`"
      >
        <!-- Item Header -->
        <item-header :view="view" :abilities="model['abilities']" />
        <!-- Item Content -->
        <article class="content__body">
          <!-- Content Form -->
          <app-form
            v-model="values"
            :fieldset="fields"
            :method="$route.name == 'create' ? 'POST' : 'PUT'"
            :invalid="$store.state.form['errors']"
            :action="$resource.getApiPath()"
            :loaded="loaded"
            :loader="false"
            :width="6"
            @response="loading = false"
            @submit="loading = true"
            @success="onFormSuccess"
            @change="onFormChange"
            @ready="onFormReady"
          >
            <template slot="footer">
              <!-- Item Footer -->
              <item-footer
                @loading="onItemFooterLoading"
                :abilities="model['abilities']"
                :values="values"
                class="form__footer"
              />
            </template>
            <template slot="fallback" v-if="!loading && loaded">
              <!-- No Fields -->
              <b-alert
                show
                variant="warning"
                style="height: 150px"
                class="d-flex justify-content-center align-items-center text-center mb-0"
              >
                <span v-html="__('noFields')"></span>
              </b-alert>
            </template>
          </app-form>
        </article>
        <!-- Item Delete Modal -->
        <app-modal
          v-if="$route['name'] == 'delete'"
          size="xs"
          variant="danger"
          :icon="view['icon']"
          :valid="!loading"
          :title="view['title']"
          :confirm="__('delete')"
          @close="$router.push({ name: 'edit' })"
          @confirm="deleteData"
        >
          <b-alert show variant="danger" class="text-center mb-0">
            {{ __(loading ? "busyDeleting" : "confirmDelete") }}
          </b-alert>
        </app-modal>
      </section>
    </resource-view>
  </resource-model>
</template>

<script>
import ItemHeader from "@/components/item/ItemHeader";
import ItemFooter from "@/components/item/ItemFooter";
import AppModal from "@/components/app/AppModal";
import AppForm from "@/components/app/AppForm";
export default {
  components: {
    ItemHeader,
    ItemFooter,
    AppModal,
    AppForm,
  },
  data() {
    return {
      fields: {},
      values: {},
      loaded: false,
      scroll: false,
      loading: false,
    };
  },
  mounted() {
    this.setFields();
  },
  activated() {
    if (this.$route.name == "create") {
      this.values = {};
      this.$forceUpdate();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // in case the user comes from "create" route, automatically scroll to bottom of screen.
      vm.scroll = from["name"] == "create";
    });
  },
  methods: {
    setFields() {
      this.loading = true;
      this.$http
        .get(this.$resource.getApiPath("forms/item/manage"), {
          params: this.$route.query,
        })
        .then(({ data: { data } }) => (this.fields = data))
        .catch(() =>
          this.$router.push({ name: "index", query: this.$route.query })
        )
        .finally(() => {
          this.loading = false;
          this.loaded = true;
        });
    },
    getLabels() {
      return _.mapValues(this.fields, (field) => _.get(field, "label"));
    },
    onFormChange() {
      this.$store.dispatch("form/set", {
        method: this.formMethod,
        url: this.$resource.getApiPath(),
        data: {
          labels: this.getLabels(),
          values: this.values,
        },
      });
    },
    onFormSuccess(identity) {
      if (_.isObject(identity)) return;
      this.$refs.$view.setView();
      this.$root.$emit("feedback");
      this.$root.$emit("updated-resource", this.$route.params["resource"]);
      // const reload = identity != this.$route.params["identity"];
      this.$router.push({ name: "edit", params: { identity } }).catch(() => {});
    },
    deleteData() {
      this.loading = true;
      this.$http
        .delete(this.$resource.getApiPath())
        .then(() => this.$router.push({ name: "index" }))
        .catch(() => this.$router.push({ name: "edit" }))
        .finally(() => (this.loading = false));
    },
    onItemFooterLoading(loading) {
      this.loading = loading;
    },
    onFormReady() {
      if (this.scroll) {
        this.$nextTick(() => {
          let $button = this.$el.querySelector('[type="submit"]');
          if ($button) $button.scrollIntoView({ behavior: "smooth" });
        });
      }
    },
  },
};
</script>