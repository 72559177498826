<template>
  <resource-view v-slot="view">
    <section class="content__container item__menu" :class="{ loading }">
      <!-- Item Header -->
      <item-header :view="view" :abilities="model['abilities']" />
      <!-- Item Menu -->
      <menu-tree
        :expand="true"
        v-model="value"
        :items="menu['items']"
        @select="onMenuItemSelect"
      />
    </section>
  </resource-view>
</template>

<script>
import ItemHeader from "@/components/item/ItemHeader";
import MenuTree from "@/components/menu/MenuTree";
export default {
  components: {
    ItemHeader,
    MenuTree,
  },
  data() {
    return {
      menu: {},
      value: {},
      model: {},
      loading: false,
      // dummy data below
      items: [
        {
          label: "Huurcontracten weergeven",
          icon: "fal fa-file-contract",
          action: "/contracts",
          items: [],
        },
        {
          label: "Werkordermeldingen weergeven",
          icon: "fal fa-ticket",
          action: "/workorder-reports",
          items: [],
        },
        {
          label: "Documenten",
          icon: "fal fa-file",
          action: "/documents",
          items: [
            {
              label: "Document soort 1",
              icon: "fal fa-file",
              action: "/type-documents-1",
              items: [
                {
                  label: "Document subsoort 1.1",
                  icon: "fal fa-file",
                  action: "/type-documents-1",
                },
              ],
            },
            {
              label: "Document soort 2",
              icon: "fal fa-file",
              action: "/type-documents-2",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.setModel();
    this.setData();
  },
  methods: {
    async setModel() {
      this.model = await this.$resource.getModel();
    },
    setData() {
      this.loading = true;
      this.$http
        .get(this.$resource.getApiPath("menus/item/local"))
        .then(({ data: { data } }) => (this.menu = data))
        .finally(() => (this.loading = false));
    },
    onMenuItemSelect(action) {
      const route = this.getActionRoute(action);
      this.$router.push(route);
    },
    getActionRoute({ action, foreign_key }) {
      let query = {};
      let column = foreign_key || this.model["foreign_key"];
      let filter = { [column]: this.$route.params["identity"] };
      let filterQuery = this.$objectToQueryParams(filter, "filter");
      if (_.isString(action)) {
        return { path: action, query: filterQuery };
      } else if (_.isObject(action)) {
        if (action["name"] == "index" || _.get(action, "path.0") == "/") {
          query = filterQuery;
        } else if (action["name"] == "create") {
          query = filter;
        }
        return _.merge({ query }, action);
      }
    },
  },
};
</script>