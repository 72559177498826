<template>
  <main class="grid__data" @keydown.enter="onCellDoubleClicked">
    <ag-grid-vue
      :overlay-loading-template="overlayLoadingTemplate"
      :overlay-no-rows-template="overlayNoRowsTemplate"
      :navigate-to-next-cell="navigateToNextCell"
      :framework-components="frameworkComponents"
      :auto-size-strategy="autoSizeStrategy"
      :suppress-column-virtualisation="true"
      :suppress-field-dot-notation="true"
      :suppress-no-rows-overlay="false"
      :suppress-loading-overlay="true"
      :default-col-def="defaultColDef"
      :header-height="headerHeight"
      :get-row-class="getRowClass"
      :column-defs="columnDefs"
      :row-drag-managed="true"
      :animate-rows="true"
      :row-height="rowHeight"
      :row-data="rowData"
      :context="context"
      :class="classes"
      v-bind="$attrs"
      ref="grid"
      @first-data-rendered="onFirstDataRendered"
      @cell-double-clicked="onCellDoubleClicked"
      @selection-changed="onSelectionChanged"
      @model-updated="onModelUpdated"
      @cell-focused="onCellFocused"
      @row-drag-end="onRowDragEnd"
      @grid-ready="onGridReady"
    />
  </main>
</template> 

<script>
import agColumnHeader from "@/components/grid/ag-Grid/agColumnHeader.js";
import { AgGridVue } from "ag-grid-vue";
import Mark from "mark.js";
export default {
  inheritAttrs: false,
  components: {
    agColumnHeader,
    AgGridVue,
    Mark,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
      required: false,
    },
    meta: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    sorts: {
      type: Object,
      default: () => ({}),
    },
    finder: {
      type: Object,
      default: () => ({}),
    },
    hidden: {
      type: Array,
      default: () => [],
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "sm",
      validator: function (value) {
        return ["sm", "md", "lg"].indexOf(value.toLowerCase()) !== -1;
      },
    },
  },
  data() {
    return {
      delay: 500,
      timeout: 0,
      context: {},
      rowData: {},
      gridApi: {},
      selected: {} /* single */,
      selection: [] /* multiple */,
      columnApi: {},
      columnDefs: [],
      defaultColDef: {
        resizable: true,
        sortable: false,
      },
    };
  },
  watch: {
    data: {
      immediate: true,
      handler: function (data) {
        this.setContext();
        this.rowData = data;
        this.setColumnDefs(data);
      },
    },
    selected: function (selected) {
      this.$emit("selected", selected);
    },
    selection: function (selection) {
      this.$emit("selection", selection);
    },
    sorts: function (sorts) {
      this.context.sorts = sorts;
      this.gridApi.refreshHeader();
    },
  },
  computed: {
    overlayLoadingTemplate() {
      let loadingText = !_.isString(this.loading)
        ? this.__("loading", "Loading")
        : this.loading;
      return `<span class="ag-overlay-loading-center">${loadingText}</span>`;
    },
    overlayNoRowsTemplate() {
      let noRowsText = this.__("noRowsAvailable");
      return `<span class="ag-overlay-loading-center">${noRowsText}</span>`;
    },
    gridIsAvailable() {
      return !_.isEmpty(this.gridApi) && !_.isEmpty(this.rowData);
    },
    headerHeight() {
      if (this.$attrs["header-height"]) {
        return this.$attrs["header-height"];
      }
      return this.gridIsAvailable ? true : 0;
    },
    rowHeight() {
      let sizes = { sm: 25, md: 30, lg: 35 };
      return _.get(sizes, this.size, sizes["sm"]);
    },
    gridIsOrderable() {
      return _.get(this.meta, "model.abilities.reorder", false);
    },
    gridIsSortable() {
      return _.get(this.meta, "model.abilities.sort", false);
    },
    firstColumnName() {
      let firstRowData = _.first(this.rowData);
      let columnNames = _.keys(firstRowData);
      let visibleCols = _.filter(columnNames, (column) => column[0] !== "_");
      let firstColumn = _.first(visibleCols);
      return firstColumn;
    },
    frameworkComponents() {
      return this.$attrs["custom-header"] ? { agColumnHeader } : {};
    },
    autoSizeStrategy() {
      return { type: "fitCellContents" };
    },
    useMultiRowSelection() {
      return this.$attrs["row-selection"] === "multiple";
    },
    classes() {
      return `ag-theme-balham size--${this.size}`;
    },
  },
  activated() {
    this.addActiveClasses();
  },
  methods: {
    onGridReady({ api, columnApi }) {
      this.setContext();
      this.gridApi = api;
      this.columnApi = columnApi;
      this.gridApi.showLoadingOverlay();
    },
    onModelUpdated({ columnApi }) {
      this.columnApi = columnApi;
      this.markKeywords();
      this.addActiveClasses();
      this.setActiveRowSelection();
    },
    onFirstDataRendered({ api }) {
      api.forEachNode((node) =>
        node.setSelected(this.$guessBoolean(node.data["_Selected"]))
      );
    },
    onCellDoubleClicked() {
      const { row } = this.getActiveRowCell();
      this.$emit("select", row["data"]);
    },
    onSelectionChanged() {
      this.selection = this.gridApi
        .getSelectedRows()
        .map((row) => _.get(row, "__data.value"));
    },
    onCellFocused() {
      if (!this.gridIsAvailable) return;
      const activeRowCell = this.getActiveRowCell();
      if (activeRowCell) {
        const { row, cell } = activeRowCell;
        const column = _.trimEnd(cell.column["colId"], "_1");
        const value = this.gridApi.getValue(cell["column"], row);
        const name = this.getNormalizedDataFromRow(row, "title");
        const id = this.getNormalizedDataFromRow(row, "value");
        this.selected = { column, value, name, id, data: row.data };
      }
    },
    onRowDragEnd({ api }) {
      let ids = api
        .getRenderedNodes()
        .map(({ data }) => _.get(data, "__data.value"));
      this.$parent.$emit("reorder", ids);
    },
    getActiveRowCell() {
      if (!this.gridIsAvailable) return;
      let cell = this.gridApi.getFocusedCell() || {};
      let row = this.gridApi.getDisplayedRowAtIndex(cell["rowIndex"]);
      return row && cell ? { row, cell } : null;
    },
    setActiveRowSelection() {
      if (!this.gridIsAvailable || false) return;
      this.gridApi.getDisplayedRowAtIndex(0).setSelected(true);
      this.gridApi.setFocusedCell(
        0,
        _.first(this.gridApi.getAllDisplayedColumns())
      );
    },
    setColumnDefs(data) {
      this.columnDefs = _.map(_.first(data), (key, column) => ({
        headerCheckboxSelection: this.useCheckboxSelection(column),
        checkboxSelection: this.useCheckboxSelection(column),
        cellRenderer: this.cellRenderer(column),
        editable: this.useEditable(column),
        rowDrag: this.useRowDrag(column),
        hide: this.hideColumn(column),
        cellEditorSelector: this.cellEditorSelector,
        valueParser: this.valueParser,
        cellEditor: "agTextCellEditor",
        headerName: column,
        field: column,
      }));
    },
    hideColumn(column) {
      return column[0] === "_" || this.hidden.includes(column);
    },
    useCheckboxSelection(columnName) {
      return this.useMultiRowSelection
        ? columnName == this.firstColumnName
        : columnName == "_Selected";
    },
    useAttribute(attribute, column) {
      let value = this.$attrs[attribute];
      if (_.isArrayLikeObject(value)) {
        return value.includes(column);
      } else {
        return value == column || value === true;
      }
    },
    useEditable(column) {
      return this.useAttribute("editable", column);
    },
    useRowDrag(column) {
      return column == this.firstColumnName && this.gridIsOrderable
        ? ({ node: { data } }) => this.rowIsOrderable(data)
        : false;
    },
    cellRenderer(column) {
      return this.useAttribute("html", column)
        ? ({ value }) => value
        : undefined;
    },
    // TODO: check if params are available below
    cellEditorSelector({ value }) {
      return {
        component: this.$isNumeric(value)
          ? "agNumberCellEditor"
          : "agTextCellEditor",
      };
    },
    valueParser({ newValue, data }) {
      this.$emit("input", data);
      return newValue;
    },
    setContext() {
      this.context = {
        grid: this,
        sorts: this.sorts,
        translations: {
          sortDescending: this.__("index.header.sort.desc"),
          sortAscending: this.__("index.header.sort.asc"),
          sortDefault: this.__("index.header.sort.default"),
        },
      };
    },
    getNormalizedDataFromRow({ data: { __data } }, key = null) {
      return _.get(__data, key, __data);
    },
    getColumnIdElements(column) {
      if (this.$refs["grid"]) {
        return this.$refs["grid"].$el.querySelectorAll(`[col-id="${column}"]`);
      }
    },
    getRowClass({ node: { data } }) {
      let parentClass = _.get(data, "__parent", false) ? "row--parent" : null;
      let childClass = _.get(data, "__child", false) ? "row--child" : null;
      return `${parentClass} ${childClass}`;
    },
    rowIsOrderable(data) {
      return _.get(data, "__child") || !_.get(data, "__parent");
    },
    addActiveClasses() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        _.forEach(this.finder, ({ column, keywords }) =>
          _.forEach(this.getColumnIdElements(column), ($el) => {
            if (_.isEmpty(_.trim(keywords))) {
              $el.classList.remove("finder--active");
            } else {
              $el.classList.add("finder--active");
            }
          })
        );
      }, this.delay);
    },
    navigateToNextCell({ key, previousCellPosition, nextCellPosition }) {
      switch (key) {
        case "ArrowDown":
          this.gridApi.forEachNode((node) => {
            if (
              previousCellPosition["rowIndex"] + 1 === node["rowIndex"] &&
              !this.useMultiRowSelection
            ) {
              node.setSelected(true);
            }
          });
          return nextCellPosition;
        case "ArrowUp":
          this.gridApi.forEachNode((node) => {
            if (
              previousCellPosition["rowIndex"] - 1 === node["rowIndex"] &&
              !this.useMultiRowSelection
            ) {
              node.setSelected(true);
            }
          });
          return nextCellPosition;
        case "ArrowRight":
        case "ArrowLeft":
          return nextCellPosition;
        default:
          throw false;
      }
    },
    markKeywords(keywords = null) {
      if (keywords) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          let $element = this.$el.querySelector(".ag-body-viewport");
          let instance = new Mark($element);
          instance.unmark().mark(keywords);
        }, this.delay);
      } else if (_.some(this.finder)) {
        _.forEach(this.finder, ({ keywords }) =>
          keywords ? this.markKeywords(keywords) : false
        );
      }
    },
  },
};
</script>