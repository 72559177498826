<template>
  <resource-view
    v-slot="view"
    :params="{ procedure: 'multi-year-import' }"
    base="activities"
    group="modules"
    name="import"
  >
    <div>
      <!-- Header -->
      <app-header v-bind="view" />
      <!-- Separator -->
      <hr />
      <!-- Form -->
      <app-form
        v-if="useImport"
        class="mb-3"
        method="get"
        v-model="values"
        :loader="false"
        :feedback="true"
        :fieldset="fields"
        @change="setData"
      />
      <div
        :style="`
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - ${useImport ? '270' : '190'}px);
        `"
      >
        <template v-if="loading">
          <!-- Loading Feedback -->
          <b-alert show variant="primary" class="text-center">
            <i class="fal fa-spinner fa-spin mr-2"></i>
            {{ __("Bezig met laden... | Busy loading...") }}
          </b-alert>
        </template>
        <!-- Data Loaded -->
        <template v-if="loaded">
          <!-- Data -->
          <data-select
            @select="(data) => (selected = data)"
            selection="multiple"
            :layout="layout"
            :meta="meta"
            :data="data"
            class="mt-2"
            style="height: 100vh"
          />
        </template>
        <!-- Footer -->
        <footer class="mt-4">
          <!-- Error Feedback -->
          <template v-if="!processed && message">
            <b-alert show variant="danger" class="text-center">
              <template v-if="_.some(errors)">
                <span class="d-block" v-for="(error, key) in errors" :key="key">
                  {{ error.join(", ") }}
                </span>
              </template>
              <template v-else>
                <i class="fal fa-exclamation-triangle mr-2"></i>
                {{ message }}
              </template>
            </b-alert>
          </template>
          <!-- Success Feedback -->
          <template v-if="processed && message">
            <b-alert
              show
              variant="success"
              class="text-center mb-3"
              style="white-space: pre-line"
            >
              {{ message }}
            </b-alert>
          </template>
          <!-- Actions -->
          <div class="d-flex justify-content-end">
            <!-- Submit button -->
            <b-button
              class="mr-4"
              @click.prevent="onSubmit"
              :variant="useImport ? 'primary' : 'danger'"
              :disabled="loading || _.isEmpty(selected)"
            >
              {{ submitLabel }}
            </b-button>
            <!-- Close button -->
            <b-button
              variant="outline-secondary"
              @click.prevent="closed = true"
            >
              {{ __("Afsluiten | Close") }}
            </b-button>
          </div>
          <!-- Processed -->
          <input
            type="hidden"
            id="processed"
            name="processed"
            :value="processed ? 'true' : 'false'"
          />
          <!-- Closed -->
          <input
            type="hidden"
            id="closed"
            name="closed"
            :value="closed ? 'true' : 'false'"
          />
        </footer>
      </div>
    </div>
  </resource-view>
</template>

<script>
import DataSelect from "@/components/data/DataSelect";
import AppHeader from "@/components/app/AppHeader";
import AppForm from "@/components/app/AppForm";
import { mapGetters } from "vuex";
export default {
  components: {
    DataSelect,
    AppHeader,
    AppForm,
  },
  data() {
    return {
      processed: false,
      selected: [],
      message: null,
      loading: false,
      closed: false,
      loaded: false,
      fields: {},
      values: {},
      error: null,
      data: [],
      meta: {},
    };
  },
  beforeMount() {
    this.useImport ? this.setFields() : this.setData();
  },
  computed: {
    layout() {
      return _.get(this.$route, "query.layout", "grid");
    },
    useImport() {
      return this.$route.name == "multi-year-planning/import";
    },
    useDelete() {
      return this.$route.name == "multi-year-planning/delete";
    },
    submitLabel() {
      if (this.useImport) return this.__("Importeren | Import");
      return this.__("Verwijderen | Delete");
    },
    Obj_Code_To() {
      return _.get(
        this.values,
        "Obj_Code_To.value",
        this.$route.query["Obj_Code_To"]
      );
    },
    Obj_Code_From() {
      return this.useImport
        ? _.get(this.values, "Obj_Code.value")
        : this.$route.query["Obj_Code_From"];
    },
    ...mapGetters("auth", ["authUser"]),
  },
  methods: {
    setFields() {
      this.$http
        .get("activities/forms/modules/import?procedure=multi-year-import")
        .then(({ data: { data } }) => (this.fields = data));
    },
    setData() {
      if (!this.Obj_Code_From) return;
      this.selected = [];
      this.loading = true;
      this.loaded = false;
      this.error = null;
      this.$http
        .get("activities", {
          params: new URLSearchParams({
            "filter[Obj_Code_From]": this.Obj_Code_From,
            "filter[Action]": "fillgrid-mjop",
            procedure: "multi-year-import",
            "page[size]": -1,
          }),
        })
        .then(({ data: { data, meta } }) => {
          this.data = data;
          this.meta = meta;
        })
        .catch((this.processed = false))
        .finally(() => {
          this.loading = false;
          this.loaded = true;
        });
    },
    onSubmit() {
      if (!this.confirmSubmission()) return;
      if (_.isEmpty(this.selected)) return;
      this.message = this.error = null;
      this.loading = true;
      this.$http
        .post("modules/multi-year-planning", {
          Action: this.useImport ? "execute-import" : "execute-delete",
          Obj_Code_From: this.Obj_Code_From,
          Obj_Code_To: this.Obj_Code_To,
          Elm_Id_Filter: this.selected,
        })
        .then(({ data: { message } }) => {
          this.message = message;
          this.processed = true;
          if (this.useDelete) {
            this.selected = [];
            this.setData();
          }
        })
        .catch(({ response: { data } }) => {
          this.message = data["message"];
          this.errors = data["errors"];
          this.processed = false;
        })
        .finally(() => (this.loading = false));
    },
    confirmSubmission() {
      return this.useImport && this.processed
        ? confirm(
            "U heeft zojuist een aantal elementen reeds geïmporteerd. Weet u zeker dat u deze elementen nogmaals wilt importeren?"
          )
        : true;
    },
  },
};
</script>