<template>
  <header class="page__navigation">
    <b-navbar :toggleable="$isMobile() || 'lg'">
      <!-- Module Title -->
      <b-navbar-brand class="navigation__title">
        <i :class="`mr-2 fal fa-${module['icon']}`" />
        {{ module["label"] }}
      </b-navbar-brand>
      <!-- Toggle Button -->
      <b-navbar-toggle
        class="px-2 ml-auto"
        target="navbar-toggle-collapse"
        ref="$toggle"
      >
        <i class="fal fa-bars" />
      </b-navbar-toggle>
      <!-- Primary Menu -->
      <b-collapse id="navbar-toggle-collapse" is-nav>
        <template v-for="(menu, menuKey) in menus">
          <!-- Menu -->
          <b-navbar-nav
            :key="menuKey"
            :id="`menu${_.upperFirst(menuKey)}`"
            :class="{
              'order-1 mr-4': menuKey == 'module',
              'order-2 mr-4': menuKey == 'primary',
              'order-3 ml-auto': menuKey == 'user',
            }"
          >
            <template v-for="(item, itemKey) in menu">
              <template v-if="_.size(item['items']) == 0">
                <!-- Item -->
                <li
                  :key="itemKey"
                  @click="onItemClick(item)"
                  :class="{ active: isActive(item) }"
                  class="nav-link d-inline"
                >
                  <i :class="`fal fa-${item['icon']} mr-1`" />
                  {{ item["label"] }}
                  <app-badge :badge="getBadge(item)" />
                </li>
              </template>
              <template v-else>
                <!-- Item Children -->
                <b-nav-item-dropdown left :key="itemKey">
                  <template v-slot:button-content>
                    <span
                      class="nav-link d-inline"
                      :class="{ active: isActive(item) }"
                    >
                      <template v-if="menuKey == 'user' && authUser.image">
                        <img
                          :src="authUser.image"
                          :alt="authUser.name"
                          class="mr-2 avatar"
                        />
                      </template>
                      <template v-else>
                        <i :class="`fal fa-${item['icon']} mr-1`" />
                      </template>
                      {{ item["label"] }}
                      <app-badge :badge="getBadge(item)" />
                    </span>
                  </template>
                  <template v-for="(subitem, subitemKey) in item['items']">
                    <b-dropdown-item
                      :class="{ active: isActive(subitem) }"
                      @click="onItemClick(subitem)"
                      :key="subitemKey"
                    >
                      <i
                        :class="`fal fa-${subitem['icon']} text-center`"
                        style="width: 25px; margin-left: -10px"
                      />
                      {{ subitem["label"] }}
                      <app-badge :badge="getBadge(subitem, true)" />
                    </b-dropdown-item>
                  </template>
                </b-nav-item-dropdown>
              </template>
            </template>
          </b-navbar-nav>
        </template>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import AppBadge from "@/components/app/AppBadge";
import { mapGetters } from "vuex";
export default {
  components: {
    AppBadge,
  },
  data() {
    return {
      menus: {},
      module: {},
    };
  },
  beforeMount() {
    this.setPrimaryMenu();
    this.setColorScheme();
    this.setModuleMenu();
    this.setUserMenu();
  },
  computed: {
    ...mapGetters("auth", ["authUser", "loggedIn"]),
  },
  methods: {
    setPrimaryMenu() {
      this.$http
        .get("provim/menus/page/primary")
        .then(({ data: { data } }) => (this.menus["primary"] = data["items"]))
        .finally(() => this.$forceUpdate());
    },
    setColorScheme() {
      if (this.loggedIn) {
        document.documentElement.classList.add(this.authUser.color_scheme);
      }
    },
    setModuleMenu() {
      this.$http
        .get("provim/menus/page/module")
        .then(({ data: { data } }) => (this.menus["module"] = data["items"]))
        .finally(() => {
          this.$forceUpdate();
          this.setModule();
        });
    },
    setUserMenu() {
      this.$http
        .get("provim/menus/page/user")
        .then(({ data: { data } }) => (this.menus["user"] = data["items"]))
        .finally(() => this.$forceUpdate());
    },
    setModule() {
      this.module = _.first(this.menus["module"]);
    },
    onItemClick({ action }) {
      if (this.$getDevice() !== "desktop") this.closeMenu();
      if (_.isString(action)) {
        if (action.indexOf("https://") === 0 || action.indexOf("http://") === 0) {
          return (window.location["href"] = action);
        }
        action = "/" + _.trimStart(action, "/");
      } else if (_.isObject(action)) {
        action.path = "/" + _.trimStart(action.path, "/");
      }
      this.$router.push(action).catch(() => {});
    },
    getBadge(item) {
      item = _.isEmpty(item["items"]) ? item : _.find(item["items"], "badge");
      return _.get(item, "badge", 0);
    },
    isActive({ action, items }) {
      const { protocol, host } = window.location;
      const { path, params, query } = this.$route;
      let actionPath = _.get(action, "path", action);
      let actionQuery = _.get(action, "query", {});
      let isActiveUrl = protocol + "//" + host == action;
      let isActiveRoot = actionPath == "/" && path == "/";
      let isActivePath = _.trim(actionPath, "/") == params.resource;
      let isActivePage = isActivePath && _.isEqual(actionQuery, query);
      let isActiveParent = items && items.some((item) => this.isActive(item));
      return isActiveUrl || isActiveRoot || isActivePage || isActiveParent;
    },
    closeMenu() {
      this.$refs.$toggle.$el.click();
    },
  },
};
</script>