import { apiClient, csrfToken } from '@/services/ApiService';

export default {
	async user() {
		try { return await apiClient.get("/system/user"); } catch { };
	},
	async login(payload) {
		await csrfToken();
		return await apiClient.post("/vendor/login", payload);
	},
	async logout() {
		try { return await apiClient.post("/vendor/logout") } catch { };
	},
	async twoFactor(code) {
		await apiClient.post("/vendor/two-factor-challenge", { code });
	},
	async forgotPassword(email, params = {}) {
		await csrfToken();
		return await apiClient.post("/vendor/forgot-password", { email, ...params });
	},
	async resetPassword(token, email, password, password_confirmation, payload = {}) {
		await csrfToken();
		return await apiClient.post("/vendor/reset-password", {
			token, email, password, password_confirmation,
			...payload
		});
	},
	async verifyEmail(id, hash, expires, signature) {
		await apiClient.post(`/vendor/email/verify/${id}/${hash}`, {
			expires, signature
		});
	},
	// async updatePassword(payload) {
	// 	await apiClient.put("/user/password", payload);
	// },
	// async registerUser(payload) {
	// 	await csrfToken();
	// 	await apiClient.post("/register", payload);
	// },
	// async sendVerification(payload) {
	// 	await apiClient.post("/email/verification-notification", payload);
	// },
	// async updateUser(payload) {
	// 	await apiClient.put("/user/profile-information", payload);
	// },
};
