<template>
  <form method="post" @submit.prevent="send">
    <template v-if="!success">
      <!-- Email field -->
      <form-field
        v-model="email"
        :errors="_.isEmpty(errors['email'])"
        class="mb-4"
        :field="{
          name: 'email',
          label: __('E-mailadres | Email address'),
          placeholder: __('Voer uw e-mailadres in | Enter your email address'),
          autofocus: true,
          required: true,
          control: 'input',
          type: 'email',
          class: 'd-block text-center',
        }"
      />
      <!-- Button -->
      <submit-button
        :label="__('Doorgaan | Continue')"
        icon="arrow-right"
        :loading="loading"
        @submit="send"
      />
    </template>
    <!-- Message -->
    <b-alert
      class="mt-3 mb-0"
      :show="!_.isEmpty(message)"
      :variant="success ? 'success' : 'danger'"
    >
      {{ message }}
    </b-alert>
    <!-- Footer -->
    <auth-reset-footer />
  </form>
</template>

<script>
import AuthResetFooter from "@/components/auth/AuthResetFooter";
import SubmitButton from "@/components/form/SubmitButton";
import FormField from "@/components/form/FormField";
import AuthCard from "@/components/auth/AuthCard";
import AuthService from "@/services/AuthService";
export default {
  components: {
    AuthResetFooter,
    SubmitButton,
    FormField,
    AuthCard,
  },
  data() {
    return {
      email: this.$route.query.username,
      message: null,
      success: false,
      loading: false,
      errors: [],
    };
  },
  methods: {
    send() {
      this.errors = [];
      this.message = null;
      this.loading = true;
      this.success = false;
      AuthService.forgotPassword(this.email, { lang: this.$lang() })
        .then(({ data: { message } }) => {
          this.message = message;
          this.success = true;
        })
        .catch(({ response: { data } }) => {
          this.message = data.message;
          this.errors = data.errors || true;
          this.success = false;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>