<template>
  <nav>
    <form-button
      :key="index"
      v-for="(button, index) in buttons"
      :disabled="disabled || button.disabled"
      :variant="button.variant"
      :tooltip="button.tooltip"
      :classes="button.class"
      :label="button.label"
      :action="button.action"
      :align="button.align"
      :type="button.type"
      :icon="button.icon"
      @submit="$emit('submit')"
      @emit="emit"
    />
  </nav>
</template>

<script>
import FormButton from "@/components/form/FormButton";
export default {
  components: {
    FormButton,
  },
  props: {
    buttons: {
      type: Array,
      default: () => [],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emit(emit) {
      this.$emit("emit", emit);
    },
  },
};
</script>