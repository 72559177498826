export default {
    template: `
        <div class="custom sort ag-header-cell-text" :class="className" @click="onHeaderClicked(params)">
            <span v-b-tooltip.bottom.hover :title="sortLabel">{{ params.displayName }}</span>
        </div>
    `,
    data: function () {
        return {
            sortDirection: null
        };
    },
    mounted() {
        let column = this.params['displayName'];
        this.sortDirection = this.params.context.sorts[column];
    },
    computed: {
        className() {
            let sortClass = `sort--active sort--${this.sortDirection || 'default'}`;
            return { [sortClass]: this.sortDirection };
        },
        sortLabel() {
            let translations = this.params.context['translations'];
            if (!translations) return;
            const { sortDescending, sortAscending, sortDefault } = translations;
            switch (this.sortDirection) {
                case 'desc': return sortDefault;
                case 'asc': return sortDescending;
                default: return sortAscending;
            }
        },
    },
    methods: {
        onHeaderClicked(params) {
            let sorts = params.context['sorts'];
            let column = params['displayName'];
            var direction = 'asc';
            if (sorts[column]) {
                direction = sorts[column] === 'asc' ? 'desc' : null;
            }
            this.sortDirection = direction;
            this.params.context.sorts = { [column]: direction };
            params.context['grid'].$emit('add-sort', { column, direction });
        }
    },
};