<script>
import ResourceService from "@/services/ResourceService";
export default {
  props: {
    name: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      model: {},
    };
  },
  created() {
    this.setModel();
  },
  render() {
    return this.$scopedSlots.default(this.model);
  },
  methods: {
    async setModel() {
      this.model = await ResourceService.getModel(this.name);
    },
  },
};
</script>