// Globals Constants
window._ = require('lodash');

import axios from "axios";
window.axios = axios;

// Scss Styles
import "@/assets/sass/screen.scss";

// Node Packages
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueSignaturePad from "vue-signature-pad";

// Local Files
import "@/filters";
import "@/directives";
import store from "@/store";
import router from "@/router";
import helpers from "@/utils/helpers";
import App from "@/components/app/App";

// Services/Providers
import { apiClient } from '@/services/ApiService';
import ResourceView from "@/components/app/ResourceView";
import ResourceModel from "@/components/app/ResourceModel";
import ResourceService from "@/services/ResourceService";
import ResourceButtons from "@/components/app/ResourceButtons";

// Vue Global Properties
Vue.prototype._ = _;
Vue.prototype.$http = apiClient;
Vue.prototype.$resource = ResourceService;

// Vue Mixins/Plugins
Vue.mixin(helpers);
Vue.use(BootstrapVue);
Vue.use(VueSignaturePad);

// Vue Global Components
Vue.component('resource-view', ResourceView);
Vue.component('resource-model', ResourceModel);
Vue.component('resource-buttons', ResourceButtons);

// Vue Configuration
Vue.config.productionTip = false;

// Vue Initialization
new Vue({ store, router, render: (h) => h(App) }).$mount("#app");
