<template>
  <form method="post" @submit.prevent="check">
    <!-- Field -->
    <form-field
      v-model="value"
      :errors="error"
      class="d-block mb-4"
      :field="{
        name: 'code',
        label: __('Uw tweefactorcode | Your two-factor code'),
        placeholder: __('Voer hier uw code in | Enter your code here'),
        control: 'input',
        type: 'number',
        icon: 'qrcode',
        required: true,
        autofocus: true,
        class: 'text-center field--code',
        style: 'font-size: 15px;',
      }"
    />
    <!-- Button -->
    <submit-button
      :label="__('Controleren | Verify')"
      icon="arrow-right"
      :loading="loading"
    />
  </form>
</template>

<script>
import SubmitButton from "@/components/form/SubmitButton";
import FormField from "@/components/form/FormField";
import AuthService from "@/services/AuthService";
export default {
  components: {
    SubmitButton,
    FormField,
  },
  data() {
    return {
      error: {},
      value: null,
      loading: false,
    };
  },
  beforeMount() {
    this.redirectIfLoggedIn();
  },
  methods: {
    check() {
      this.error = {};
      this.loading = true;
      const nextRoute = this.$isRoot() ? "module" : "dashboard";
      AuthService.twoFactor(this.value)
        .then(() => this.$router.push({ name: nextRoute }))
        .catch(({ response: { data } }) => {
          this.error = _.get(data, "errors.code");
        })
        .finally(() => (this.loading = false));
    },
    async getUser() {
      const response = await AuthService.user();
      return _.get(response, "data.data", {});
    },
    async redirectIfLoggedIn() {
      const { home_path } = await this.getUser();
      if (home_path) this.$router.push(home_path);
    },
  },
};
</script>