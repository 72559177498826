import { apiClient } from '@/services/ApiService';
import router from '@/router';

export default {
	getApiPath(append_path = '', include_root = false) {
		const { resource, identity } = this.getRoute('params');
		const apiRoot = include_root ? apiClient.defaults['baseURL'] : '';
		return _.filter([apiRoot, resource, identity, append_path]).join('/');
	},
	getRoute(property) {
		let route = router.currentRoute;
		return _.get(route, property) || route;
	},
	async getModel(resource = null) {
		let params = { info: true, ...this.getRoute('query') };
		return await apiClient
			.get(resource || this.getRoute('params.resource'), { params })
			.then(({ data: { meta } }) => meta['model']);
	},
	async getView(base = this.getApiPath(), name = this.getRoute('name'), group = "item", params = {}) {
		params = { ...params, ...this.getRoute('query') };
		return await apiClient
			.get(`${base}/views/${group}/${name}`, { params })
			.then(({ data: { data } }) => data);
	},
	async getButtons(base = this.getApiPath(), name = '', group = "item", params = {}) {
		params = { ...params, ...this.getRoute('query') };
		return await apiClient
			.get(`${base}/buttons/${group}/${name}`, { params })
			.then(({ data: { data } }) => data);
	},
};