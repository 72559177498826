<template>
  <b-alert show variant="primary" class="mb-0 mt-2">
    <i class="fal fa-spinner fa-spin" />
    {{ __("Een moment geduld a.u.b.... | Please wait...") }}
  </b-alert>
</template>

<script>
import AuthService from "@/services/AuthService";
export default {
  props: {
    view: {
      type: Object,
      default: () => {},
    },
  },
  async beforeMount() {
    await AuthService.logout();
    this.$router.push({ name: "login" });
  },
};
</script>