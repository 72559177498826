<template>
  <!-- File Viewer -->
  <div :class="{ loading }" class="file__viewer">
    <!-- File Contents -->
    <iframe v-if="isPdf && url" :src="url" class="file__contents"></iframe>
    <img v-if="isImage" :src="url" class="file__contents" />
    <!-- File Download -->
    <b-alert :show="isOther && downloaded" variant="info" size="lg">
      <i class="fal fa-file-download"></i> &nbsp;
      {{ __("Uw bestand is gedownload. | Your file has been downloaded.") }}
    </b-alert>
    <!-- Back Button -->
    <!-- <b-button
      variant="secondary"
      @click="$router.go(-1)"
      v-if="!loading && hasHistory"
    >
      <i class="fal fa-arrow-left mr-1" />
      {{ __("Terug | Back") }}
    </b-button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      downloaded: false,
      loading: false,
      type: "",
      name: "",
      mail: "",
      url: "",
    };
  },
  beforeMount() {
    this.fetchFile();
  },
  computed: {
    isPdf() {
      return this.type == "application/pdf";
    },
    isImage() {
      return this.type.startsWith("image/");
    },
    isJson() {
      return this.type == "application/json";
    },
    isOther() {
      return !this.isPdf && !this.isImage && !this.isJson;
    },
    fileName() {
      const link = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(this.name);
      return link ? link[1].replace(/['"]/g, "") : "document";
    },
    autoDownload() {
      return _.get(this.$route, "query.download", false) == "true";
    },
    hasHistory() {
      return window.history.length > 1;
    },
  },
  methods: {
    fetchFile() {
      this.downloaded = false;
      this.loading = true;
      this.$http
        .get(this.$route.fullPath, { responseType: 'blob' })
        .then(({ data, headers }) => {
          this.type = headers["content-type"];
          this.name = headers["content-disposition"];
          const blob = new Blob([data], { type: this.type });
          this.url = URL.createObjectURL(blob);
          if (this.isOther || this.autoDownload) {
            this.downloadFile();
          }
        })
        .finally(() => (this.loading = false));
    },
    async downloadFile() {
      const link = document.createElement("a");
      link.href = this.url;
      link.download = this.fileName;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        this.downloaded = true;
        if (this.autoDownload) {
          window.close();
        }
      }, 100);
    },
  },
};
</script>

<style scoped>
.file__viewer {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
}

.file__contents {
  width: 100%;
  height: 100%;
}

img.file__contents {
  object-fit: scale-down;
  width: auto;
  height: auto;
}

.alert {
  width: auto;
  align-self: center;
}

.btn {
  position: absolute;
  left: 30px;
  bottom: 30px;
}
</style>