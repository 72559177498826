var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',[(!_vm.success)?[_c('form-field',{staticClass:"mt-6",attrs:{"errors":_vm._.isEmpty(_vm.errors['email']),"field":{
        name: 'email',
        label: _vm.__('E-mailadres | Email address'),
        placeholder: _vm.__(
          'Voer uw e-mailadres in | Please enter your email address'
        ),
        required: true,
        control: 'input',
        type: 'email',
        class: 'd-block text-center',
      }},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('form-field',{staticClass:"d-block mt-3",attrs:{"errors":_vm._.isEmpty(_vm.errors['password']),"field":{
        name: 'password',
        label: _vm.__('Kies nieuw wachtwoord | Choose new password'),
        placeholder: _vm.__(
          'Voer uw nieuwe wachtwoord in | Please enter your new password'
        ),
        autofocus: true,
        required: true,
        control: 'input',
        type: 'password',
        class: 'text-center',
      }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('form-field',{staticClass:"d-block mt-3",attrs:{"errors":_vm._.isEmpty(_vm.errors['password_confirmation']),"field":{
        name: 'password',
        label: _vm.__('Bevestig nieuw wachtwoord | Confirm new password'),
        placeholder: _vm.__(
          'Voer uw nieuwe wachtwoord opnieuw in | Please confirm your new password'
        ),
        required: true,
        control: 'input',
        type: 'password',
        class: 'text-center',
      }},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}}),_c('b-button',{staticClass:"mt-4",attrs:{"block":true,"variant":"primary","disabled":_vm.loading},on:{"click":_vm.resetPassword}},[(_vm.loading)?[_vm._v(" "+_vm._s(_vm.__("Bezig met laden... | Loading..."))+" ")]:[_vm._v(" "+_vm._s(_vm.__("Nieuw wachtwoord opslaan | Save new password"))+" ")]],2)]:_vm._e(),_c('b-alert',{staticClass:"mt-3 mb-0",attrs:{"show":!_vm._.isEmpty(_vm.message),"variant":_vm.success ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('auth-reset-footer')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }