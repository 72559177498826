<template>
  <!-- Grid Area -->
  <section class="grid__area" @contextmenu.prevent="showContextMenu">
    <!-- Grid Header -->
    <grid-header
      v-if="showGridHeader"
      v-bind="finderProps"
      :columns="columns"
      :filters="filters"
      :meta="meta"
      :view="view"
      @remove-finder="removeFinder"
      @change-finder="changeFinder"
      @clear-finder="clearFinder"
      @add-finder="addFinder"
      @searching="(keywords) => $refs['data'].markKeywords(keywords)"
      @filter="(filter) => $emit('filter', filter)"
      @search="$emit('search', finder)"
      @export="exportData"
      @action="onAction"
      ref="header"
    />
    <!-- Grid Data -->
    <grid-data
      :data="data"
      :meta="meta"
      :sorts="sorts"
      :hidden="hidden"
      :finder="finder"
      :html="view['html']"
      :custom-header="true"
      :row-selection="rowSelection"
      @add-sort="addSort"
      @selected="onGridSelected"
      @selection="onGridSelection"
      @select="(value) => $emit('select', value)"
      @reorder="(value) => $emit('reorder', value)"
      ref="data"
    />
    <!-- Grid Footer -->
    <slot name="footer" />
    <!-- Grid Context Menu -->
    <grid-context-menu
      @remove-finder="removeFinder"
      @clear-finder="clearFinder"
      @add-finder="addFinder"
      @add-sort="addSort"
      @search="$emit('search', finder)"
      @export="exportData"
      @action="onAction"
      v-bind="finderProps"
      :sorts="sorts"
      :meta="meta"
      ref="context-menu"
    />
  </section>
</template> 

<script>
import GridContextMenu from "@/components/grid/GridContextMenu";
import GridHeader from "@/components/grid/GridHeader";
import GridData from "@/components/grid/GridData";
import moment from "moment";
export default {
  components: {
    GridContextMenu,
    GridHeader,
    GridData,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    meta: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
      required: false,
    },
    abilities: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Array,
      default: () => [],
    },
    view: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sorts: {},
      finder: {},
      selected: {} /* single */,
      selection: [] /* multiple */,
      operatorsOptions: {
        1: "equalTo",
        2: "lessThan",
        3: "greaterThan",
        4: "unequalTo",
        5: "startsWith",
        6: "contains",
      },
    };
  },
  watch: {
    view: {
      immediate: true,
      handler: function () {
        this.hasCustomFinders()
          ? this.setCustomFinders()
          : this.setDefaultFinder();
      },
    },
  },
  computed: {
    activeFinder() {
      return (
        _.find(this.finder, {
          column: this.selected["column"],
          keywords: this.selected["value"],
        }) || {}
      );
    },
    operators() {
      return _.map(this.operatorsOptions, (text, value) => ({
        disabled: !this.isOperatorEnabled(value),
        active: this.isOperatorActive(value),
        text: this.__(`operators.${text}`),
        value,
      }));
    },
    finderProps() {
      return {
        operators: this.operators,
        abilities: this.abilities,
        selected: this.selected,
        columns: this.columns,
        finder: this.finder,
      };
    },
    columns() {
      let columns = _.get(this.meta, "model.columns", null);
      let fallback = _.keys(_.first(this.data));
      return _.filter(
        columns || fallback,
        (column) => column[0] !== "_" && !this.hidden.includes(column)
      );
    },
    showGridHeader() {
      return _.some(this.data) || _.some(this.meta);
    },
    rowSelection() {
      return _.get(this.view, "selection", "single");
    },
    hidden() {
      return _.get(this.view, "hidden", []);
    },
  },
  methods: {
    onGridSelected(selected) {
      this.selected = selected;
      this.$emit("input", selected);
    },
    onGridSelection(selection) {
      this.selection = selection;
      this.$emit("selection", selection);
    },
    addSort({ column, direction }, single = true) {
      if (single) this.sorts = {};
      this.sorts[column] = direction;
      this.$emit("sort", this.sorts);
    },
    isOperatorActive(operator) {
      return _.toString(this.activeFinder["operator"]) === _.toString(operator);
    },
    isOperatorEnabled(operator) {
      var operator = parseInt(operator);
      let value = this.selected["value"];
      let numericOperator = [2, 3].includes(operator);
      if (!numericOperator) return true;
      let numericValue = this.$isNumeric(value);
      let dateValue = moment(value, "DD-MM-YYYY", true).isValid();
      return dateValue || numericValue;
    },
    addFinder({ column, operator, keywords, focus }) {
      this.finder[column] = { column, operator, keywords, focus };
      this.$refs["header"].forceUpdate();
    },
    changeFinder(oldColumn, finder) {
      delete this.finder[oldColumn];
      let newColumn = finder["column"];
      this.finder[newColumn] = finder;
      this.$refs["header"].forceUpdate();
    },
    removeFinder(column) {
      if (column) delete this.finder[column];
      this.$refs["header"].forceUpdate();
    },
    clearFinder() {
      this.finder = {};
      this.setDefaultFinder();
      this.$refs["header"].forceUpdate();
    },
    setDefaultFinder() {
      this.finder[null] = {
        column: null,
        operator: 6,
        keywords: null,
      };
    },
    setCustomFinders() {
      let finders = this.view["finders"];
      let isFixed = this.hasFixedFinders();
      if (isFixed) delete this.finder[null];
      _.forEach(finders, (finder) => {
        let fixed = _.get(finder, "fixed", isFixed);
        let column = _.get(finder, "column", finder);
        let keywords = _.get(finder, "keywords", "");
        let operator = _.get(finder, "operator", 6);
        this.finder[column] = { fixed, column, keywords, operator };
      });
    },
    showContextMenu(event) {
      if(event.target.closest('.grid__header')) return;
      this.$refs["context-menu"].open(event);
    },
    onAction(name) {
      const { data, id } = this.selected;
      const value = _.size(this.selection) > 1 ? this.selection : id;
      this.$emit("action", { name, value, data });
    },
    hasFixedFinders() {
      return _.isArrayLikeObject(this.view["finders"]) === true;
    },
    hasCustomFinders() {
      return !_.isEmpty(this.view["finders"]);
    },
    exportData(type) {
      return type == "excel"
        ? this.exportDataToExcel()
        : this.exportDataToPdf();
    },
    exportDataToExcel() {
      // Ref. https://github.com/jecovier/vue-json-excel/blob/master/package.json
      console.log("exporting to excel", this.selection);
    },
    exportDataToPdf() {
      // Ref. https://stackoverflow.com/questions/47668546/generate-pdf-with-jspdf-and-vue-js
      console.log("exporting to pdf", this.selection);
    },
    exportDataToWord() {
      // Ref.  ??
      console.log("exporting to word", this.selection);
    },
  },
};
</script>