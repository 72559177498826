export const namespaced = true;

export const state = {
	errors: {},
	data: {},
	method: null,
	saved: null,
	url: null,
};

export const mutations = {
	SET_FORM(state, newState) {
		state = _.merge(state, newState);
	},
	FLAG_FORM(state, value) {
		state.saved = value;
	},
	SET_ERRORS(state, value) {
		state.errors = value;
	},
};

export const actions = {
	set(context, value) {
		context.commit('SET_FORM', value);
	},
	flag(context, value) {
		context.commit('FLAG_FORM', value);
	},
	save(context, callback) {
		this._vm.$http({
			url: context.state.url,
			data: context.state.data,
			method: context.state.method,
		})
			.then(({ data }) => callback(data, null))
			.catch(({ response }) => {
				context.commit('SET_ERRORS', _.get(response, 'data.errors'));
				callback({}, response);
			});
	}
};