<template>
  <item-button v-bind="button" type="submit" @click="$emit('submit')" />
</template>

<script>
import ItemButton from "@/components/item/Buttons/ItemButton";
export default {
  components: {
    ItemButton,
  },
  props: {
    button: {
      type: Object,
      default: () => {},
    },
  },
};
</script>