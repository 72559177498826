<template>
  <section class="index__header d-flex flex-wrap mb-4">
    <!-- Index Header -->
    <app-header v-bind="view" />
    <!-- Header Controls -->
    <form v-if="!_.isEmpty(abilities)" class="index__controls">
      <!-- Search Control -->
      <b-input-group
        class="control__field field--search"
        v-if="abilities['search']"
      >
        <!-- Search Input -->
        <b-input
          type="text"
          v-model="keywords"
          class="search__input"
          :placeholder="__('search')"
          @keydown.enter.prevent="$emit('search', keywords)"
        />
        <b-input-group-append>
          <!-- Search Clear Button -->
          <span
            class="search__clear"
            v-if="!_.isEmpty(keywords)"
            v-b-tooltip.hover="__('erase')"
          >
            <i @click="clearKeywords" class="fal fa-times-circle" />
          </span>
          <!-- Search Submit Button -->
          <b-button
            variant="secondary"
            class="search__button"
            @click="$emit('search', keywords)"
            v-b-tooltip.hover="__('search')"
          >
            <i class="fal fa-search" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <!-- Filter Control -->
      <b-select
        v-model="filter"
        v-if="_.some(filters)"
        :options="filterOptions"
        @change="$emit('filter', filter)"
        class="control__field field--filter"
        v-b-tooltip.hover="__('index.setFilterView')"
      >
      </b-select>
    </form>
    <nav class="index__buttons">
      <!-- Header Action Buttons -->
      <resource-buttons name="create" v-slot="button">
        <b-button
          class="ml-auto"
          v-if="abilities['create']"
          :variant="button['variant']"
          v-b-tooltip.hover="button['tooltip']"
          @click="$router.push({ ...$route, name: 'create' })"
        >
          <i :class="`fal fa-${button['icon']} mr-1`" />
          {{ button["label"] }}
        </b-button>
      </resource-buttons>
    </nav>
  </section>
</template>

<script>
import AppHeader from "@/components/app/AppHeader";
export default {
  inheritAttrs: false,
  components: {
    AppHeader,
  },
  props: {
    view: {
      type: Object,
      default: () => ({}),
    },
    abilities: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filter: null,
      keywords: null,
    };
  },
  mounted() {
    if (!_.isEmpty(this.filters)) {
      _.forEach(this.filters, ({ selected, value }) => {
        if (selected) this.filter = value;
      });
    }
  },
  methods: {
    clearKeywords() {
      this.keywords = null;
      this.$emit("search", null);
    },
  },
  computed: {
    filterOptions() {
      return [{ text: this.__("everything") }, ...this.filters];
    },
  },
};
</script>