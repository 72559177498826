<template>
  <resource-model v-slot="model">
    <resource-view v-slot="view" @updated="setData">
      <section class="content__container item__notes" :class="{ loading }">
        <!-- Item Header -->
        <item-header :view="view" :abilities="model['abilities']" />
        <!-- Item Notes -->
        <template v-if="data['notes']">
          <div v-html="data['notes']" class="notes__text" />
        </template>
        <template v-else>
          <!-- Empty Notice -->
          <b-alert show variant="info">
            {{ __("noNotesFound") }}
          </b-alert>
        </template>
      </section>
    </resource-view>
  </resource-model>
</template>

<script>
import DataTableVert from "@/components/data/DataTableVert";
import ItemHeader from "@/components/item/ItemHeader";
export default {
  components: {
    DataTableVert,
    ItemHeader,
  },
  data() {
    return {
      data: {},
      loading: false,
    };
  },
  beforeMount() {
    this.setData();
  },
  methods: {
    setData() {
      this.loading = true;
      this.$http
        .get(this.$resource.getApiPath("notes"), {
          params: { view: true, ...this.$route.query },
        })
        .then(({ data }) => (this.data = data))
        .finally(() => (this.loading = false));
    },
  },
};
</script>