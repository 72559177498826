import { apiClient } from '@/services/ApiService';
export const namespaced = true;
export const state = {
	labels: {},
};
export const mutations = {
	SET_LABELS(state, labels) {
		state.labels = labels;
	},
};
export const actions = {
	getLabels({ commit }, callback) {
		return apiClient.get('provim/labels')
			.then(({ data: { data } }) => commit("SET_LABELS", data))
			.catch(({ response }) => console.warn('Provim: error while loading labels', response.data))
			.finally(() => callback());
	},
};
export const getters = {
	labels: (state) => {
		return state.labels;
	}
};
