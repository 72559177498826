<template>
  <resource-buttons v-slot="buttons">
    <app-header v-bind="view">
      <template
        slot="buttons"
        v-if="$route.name !== 'create' && _.some(actions)"
      >
        <!-- Header Menu -->
        <ul class="header__menu">
          <template v-for="(name, index) in actions">
            <template v-if="![$route.name, 'create'].includes(name)">
              <!-- Menu Item -->
              <li :key="index" v-if="buttons[name]">
                <b-button
                  @click="$router.push({ name })"
                  :variant="buttons[name]['variant']"
                  v-b-tooltip.hover="buttons[name]['tooltip']"
                >
                  <i :class="`mr-1 fal fa-${buttons[name]['icon']}`" />
                  {{ buttons[name]["label"] }}
                </b-button>
              </li>
            </template>
          </template>
        </ul>
      </template>
    </app-header>
  </resource-buttons>
</template>

<script>
import AppHeader from "@/components/app/AppHeader";
export default {
  inheritAttrs: false,
  props: {
    view: {
      type: Object,
      default: () => ({}),
    },
    abilities: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    AppHeader,
  },
  computed: {
    actions() {
      return this.$getActionRoutes(this.abilities);
    },
  },
};
</script>