<template>
  <section class="form__wrapper">
    <form
      v-bind="$attrs"
      class="form"
      method="post"
      :action="action"
      :class="formClass"
      :data-method="method"
      @keydown.enter.prevent="() => {}"
      @submit.prevent="submit(undefined)"
      v-if="_.some(fields) || !!$slots"
    >
      <slot></slot>
      <b-tabs
        active-tab-class="d-flex row form__elements"
        :content-class="_.size(tabs) > 1 ? 'mt-3 ' : ''"
        :nav-wrapper-class="_.size(tabs) === 1 ? 'd-none' : ''"
      >
        <template v-for="tab in tabs">
          <b-tab v-if="showTab(tab)" :key="tab.key">
            <template #title>
              <i v-if="tab.icon" :class="`fal fa-${tab.icon} mr-2`" />
              <figure class="error-count" v-if="issues[tab.key]">
                {{ issues[tab.key] }}
              </figure>
              <span>{{ tab.title }}</span>
            </template>
            <template #default>
              <template v-for="(field, name) in tab.fields">
                <section
                  :key="`control-${_.get(field, 'name', name)}`"
                  :class="`form__element ${getElementClass(field)}`"
                  v-show="isVisible(field) && field.control !== 'tab'"
                  :id="!field.repeat ? _.get(field, 'name', name) : null"
                >
                  <template v-if="isBlockElement(field)">
                    <Block v-bind="field" />
                  </template>
                  <template v-else-if="field.control !== 'tab'">
                    <template v-if="_.has(repeaters, field.repeat)">
                      <template
                        v-for="({ _deleted }, id) in repeaters[field.repeat][
                          'values'
                        ]"
                      >
                        <div
                          class="field__group row fadeIn"
                          :key="id"
                          v-show="!_deleted"
                        >
                          <form-field
                            v-for="(repeat, name) in repeaters[field.repeat]
                              .fields"
                            :key="name + id"
                            :auth="auth"
                            :size="size"
                            :form="values"
                            :width="width"
                            :field="repeat"
                            :labels="labels"
                            :class="getElementClass(repeat)"
                            :name="_.get(repeat, 'name', name)"
                            :errors="
                              errors[`${id}.${_.get(repeat, 'name', name)}`]
                            "
                            v-model.lazy="
                              repeaters[field.repeat]['values'][id][name]
                            "
                            @values="
                              (values, override) =>
                                updateValues(
                                  values,
                                  override,
                                  `${field.repeat}.values.${id}`
                                )
                            "
                            @toggling="onFieldToggling"
                            @change="onFieldChange"
                            @focus="onFieldFocus"
                            @toggle="onToggle"
                            @fail="onFail"
                            @emit="emit"
                          />
                          <!-- Remove Button-->
                          <b-btn
                            size="sm"
                            variant="danger"
                            class="position-absolute"
                            @click="removeRepeater(field.repeat, id)"
                            :title="getRepeaterLabel(field.repeat, 'delete')"
                            style="
                              top: -0.5rem;
                              right: -0.5rem;
                              border-radius: 50%;
                            "
                          >
                            <i class="fal fa-trash" />
                          </b-btn>
                        </div>
                      </template>
                      <!-- Add Repeater-->
                      <b-btn
                        size="sm"
                        class="mb-4"
                        style="margin-left: -15px"
                        variant="outline-secondary"
                        @click="addRepeater(field.repeat)"
                      >
                        <i class="fal fa-plus mr-1" />
                        {{ getRepeaterLabel(field.repeat, "add") }}
                      </b-btn>
                    </template>
                    <template v-else>
                      <form-field
                        :auth="auth"
                        :size="size"
                        :form="values"
                        :width="width"
                        :field="field"
                        :labels="labels"
                        :name="_.get(field, 'name', name)"
                        :errors="errors[_.get(field, 'name', name)]"
                        v-model.lazy="values[_.get(field, 'name', name)]"
                        @enter="(e) => $emit('enter', e)"
                        @toggling="onFieldToggling"
                        @change="onFieldChange"
                        @values="updateValues"
                        @focus="onFieldFocus"
                        @toggle="onToggle"
                        @fail="onFail"
                        @emit="emit"
                      />
                    </template>
                  </template>
                </section>
              </template>
            </template>
          </b-tab>
        </template>
      </b-tabs>
      <slot name="footer">
        <footer class="form__footer" v-if="footer">
          <b-button
            block
            type="submit"
            variant="primary"
            class="footer__submit-button"
          >
            {{ confirm || __("save") }}
          </b-button>
        </footer>
      </slot>
    </form>
    <slot name="fallback" v-else></slot>
  </section>
</template>

<script>
import FormField from "@/components/form/FormField";
import Block from "@/components/form/Block";
import moment from "moment";
import { _ } from "core-js";
export default {
  inheritAttrs: false,
  components: {
    FormField,
    Block,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    fieldset: {
      type: Object | Array,
      default: () => ({}),
    },
    remote: {
      type: [String, Array, Object, Boolean],
      default: false,
    },
    method: {
      type: String,
      default: "post",
      validator: function (value) {
        return (
          ["get", "post", "put", "delete"].indexOf(value.toLowerCase()) !== -1
        );
      },
    },
    invalid: {
      type: Object | Array,
      default: () => ({}),
    },
    confirm: {
      type: String,
      default: null,
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    width: {
      type: Number | String,
      default: null,
    },
    loader: {
      type: Boolean,
      default: true,
    },
    raw: {
      type: Boolean,
      default: false,
    },
    loaded: {
      type: Boolean,
      default: true,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    updateable: {
      type: Boolean,
      default: true,
    },
    auth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: {},
      rules: {},
      fields: {},
      values: {},
      labels: {},
      errors: {},
      issues: {},
      blocks: ["line"],
      initial: {},
      visible: {},
      loading: false,
      toggling: false,
      feedback: {},
      repeaters: {},
      systemtab: "__default",
    };
  },
  beforeMount() {
    this.setFields();
    this.setValues();
  },
  watch: {
    values: {
      deep: true,
      handler: function (values) {
        this.$emit("input", values);
      },
    },
    invalid: {
      deep: true,
      handler: function (errors) {
        this.errors = errors;
      },
    },
    fieldset: {
      deep: true,
      handler: function (fieldset) {
        this.setFields(fieldset);
      },
    },
    params: {
      deep: true,
      handler: function (params) {
        this.customPayload = params;
      },
    },
    loaded: function (loaded) {
      this.loading = loaded;
    },
    loading: function (loading) {
      this.$emit("loading", loading);
    },
    "$route.query": function (to, from) {
      let trimmedValues = _.mapKeys(to, (val, key) => _.trimStart(key, "_"));
      this.updateValues(trimmedValues);
    },
    toggling: function (toggling) {
      if (toggling) {
        this.loading = true;
        setTimeout(() => {
          this.toggling = false;
          this.loading = false;
        }, 1000);
      }
    },
  },
  computed: {
    input() {
      return _.mapValues(this.values, (value) => value || null);
    },
    payload() {
      return this.raw
        ? this.values
        : {
            values: this.values,
            labels: this.labels,
            groups: _.map(this.repeaters, ({ values }) => values),
            ...this.customPayload,
          };
    },
    contextPayload() {
      return this.$objectToQueryParams(this.$context(), "context");
    },
    formClass() {
      return {
        // toggling: this.toggling,
        loading: this.loader && this.loading,
      };
    },
  },
  methods: {
    setValue(field, value) {
      this.values[field] = value;
    },
    setValues(values = this.value) {
      this.values = values;
      this.customPayload = this.params;
    },
    setFields(fields = this.fieldset) {
      if (!_.isEmpty(fields)) {
        this.fields = fields;
        this.setLabels(fields);
        this.setInitialFieldsets(fields);
        this.setRepeaterFields(fields);
        this.setRepeaterValues();
        this.setTabs(fields);
        this.$emit("ready", fields);
      }
    },
    setLabels(fields) {
      if (_.isEmpty(this.labels)) {
        this.labels = this.mapFieldProps(fields, "label");
      }
    },
    setInitialFieldsets(fields) {
      var name = null;
      _.forEach(fields, (field, key) => {
        if (field.control == "tab" || field.control == "group") {
          name = field.name;
          this.initial[name] = {};
        } else if (name) {
          this.initial[name][key] = field;
        }
      });
    },
    setRepeaterFields(fields) {
      var repeat = null;
      _.forEach(fields, (field, key) => {
        if ((repeat = field.repeat)) {
          if (this.repeaters[repeat]) delete this.fields[key];
          _.set(this.repeaters, `${repeat}.fields.${key}`, field);
        }
      });
    },
    setRepeaterValues() {
      _.forEach(this.repeaters, (repeater, repeat) => {
        var uniqid = this.$uniqid();
        _.forEach(repeater.fields, (field, name) => {
          var prefix = name.split("_")[0];
          if (field.value && _.isArrayLikeObject(field.value)) {
            _.forEach(field.value, (value) => {
              var id = prefix + "_" + this.$uniqid();
              _.set(this.repeaters, `${repeat}.values.${id}.${name}`, value);
            });
            delete this.fields[name]["value"];
          } else {
            var value = field.value || "";
            var id = prefix + "_" + uniqid;
            _.set(this.repeaters, `${repeat}.values.${id}.${name}`, value);
          }
        });
      });
    },
    getRepeaterValues() {
      return _.map(this.repeaters, ({ values }) => values);
    },
    addRepeater(repeat) {
      const uniqid = this.$uniqid();
      _.forEach(this.repeaters[repeat]["fields"], (field, name) => {
        var id = name.split("_")[0] + "_" + uniqid;
        _.set(this.repeaters, `${repeat}.values.${id}.${name}`, null);
      });
      this.$forceUpdate();
    },
    removeRepeater(repeat, id) {
      if (!this.isRepeaterEmpty(repeat, id)) {
        if (!this.$confirm()) return;
      }
      this.repeaters[repeat]["values"][id]["_deleted"] = true;
      this.$forceUpdate();
    },
    isRepeaterEmpty(repeat, id) {
      return _.every(this.repeaters[repeat]["values"][id], _.isEmpty);
    },
    getRepeaterLabel(repeat, type) {
      const nl = `${repeat} ${type == "add" ? "toevoegen" : "verwijderen"}`;
      const en = `${repeat} ${type == "add" ? "Add" : "Remove"}`;
      return this.__(`${nl} | ${en}`);
    },
    setTabs(fields) {
      if (!_.isEmpty(fields)) {
        var name = null;
        let key = this.systemtab;
        this.tabs[key] = { key, fields };
        _.forEach(fields, (field, key) => {
          if (field.control == "tab") {
            name = field.name;
            this.tabs[name] = {
              key: field.name,
              icon: field.icon,
              title: field.label,
              lazy: field.type == "lazy",
              disabled: field.disabled,
              fields: {},
            };
          } else if (name) {
            this.tabs[name]["fields"][key] = field;
          }
        });
      }
    },
    showTab({ key }) {
      return _.size(this.tabs) === 1 || key !== this.systemtab;
    },
    hasErrors({ key }) {
      return (
        !_.isEmpty(this.issues) &&
        _.intersection(Object.keys(this.initial[key]), Object.keys(this.issues))
          .length > 0
      );
    },
    updateValues(values = {}, override = false, repeater = false) {
      if (repeater) {
        _.forEach(values, (value, field) =>
          _.set(this.repeaters, `${repeater}.${field}`, value)
        );
        return;
      }
      if (_.isEqual(this.values, values)) return;
      if (this.updateable && _.isObject(values) && !_.isEmpty(values)) {
        _.forEach(values, (value, field) => {
          let preField = _.get(this.fields, field);
          let preValue = _.get(preField, "value", null);
          let oldField = _.get(this.values, field);
          let oldValue = _.get(oldField, "value", oldField);
          let nonField = _.has(this.fields, field) === false;
          let fallback = _.get(values, _.get(preField, "fallback"));
          if (
            _.isEmpty(oldValue) ||
            _.isEmpty(preValue) ||
            nonField ||
            override
          ) {
            this.values[field] = value || fallback;
          }
        });
      }
    },
    submit(payload = this.payload) {
      if (this.valid) {
        this.errors = this.issues = {};
        this.$emit("submit", payload);
        if (this.action) {
          this.loading = true;
          this.$http({
            method: this.method,
            url: this.action,
            data: payload,
          })
            .then(({ data }) => this.$emit("success", data))
            .catch(({ response }) => {
              this.rememberPayload(payload, this.action);
              this.$root.$emit("feedback", response);
              this.setErrors(response.data);
              this.$nextTick(() =>
                this.$el
                  .querySelector(".is-invalid")
                  ?.scrollIntoView({ behavior: "smooth" })
              );
            })
            .finally((response) => {
              this.$emit("response", response);
              this.loading = false;
            });
        }
      }
    },
    setErrors({ errors }) {
      this.errors = errors;
      this.$emit("fail", errors);
      _.forEach(errors, (message, field) => {
        var field = field.substring(field.indexOf(".") + 1);
        _.forEach(this.initial, (tab, key) => {
          if (tab[field] && tab !== this.systemtab) {
            !this.issues.hasOwnProperty(key)
              ? (this.issues[key] = 1)
              : this.issues[key]++;
          }
        });
      });
    },
    rememberPayload(data, path) {
      // TODO: comment out in production
      // TODO: design a way to retrieve local backups
      return;
      const date = moment().format("YYYY-MM-DD_HH:mm:ss");
      const key = `${path}_${date}`;
      const value = JSON.stringify(data);
      localStorage.setItem(key, value);
    },
    mapFieldProps(fields = {}, prop = null) {
      return _.omitBy(
        _.mapValues(fields, (field) => field[prop] || null),
        _.isNil
      );
    },
    clear(focus = true) {
      this.values = {};
      this.$el.reset();
      if (focus) this.$el.querySelector("input").focus();
    },
    onFieldFocus(name) {
      this.errors[name] = {};
    },
    onFieldChange(value, field) {
      this.$emit("change", value, field);
    },
    onFieldToggling(toggling = true) {
      this.toggling = toggling;
    },
    getFieldWidth(field) {
      let defaultWidth = field["control"] === "lookup" ? 1 : this.width;
      return _.get(field, "width", defaultWidth);
    },
    isBlockElement(field = null) {
      return _.includes(this.blocks, _.get(field, "control"));
    },
    isVisible(field) {
      let fieldName = _.get(field, "name", field);
      let isVisible = this.visible[fieldName];
      return isVisible !== false;
    },
    isLazyTab({ fields }) {
      let preload = ["autocomplete", "signature"];
      return _.every(fields, ({ control }) => !preload.includes(control));
    },
    getElementClass(field) {
      return `${_.get(field, "visible", true) == false ? "d-none" : ""} ${
        this.isBlockElement(field) ? "element--block" : "element--field"
      } col-lg-${this.getFieldWidth(field) || 12} ${
        field.repeat ? "element--repeater" : ""
      }`;
    },
    onFail(errors) {
      this.errors = _.merge(errors, this.errors);
    },
    onToggle(name, visible) {
      if (!_.isEqual(this.visible[name], visible)) {
        this.visible[name] = visible;
        this.$forceUpdate();
      }
    },
    emit(emit) {
      this.$emit("emit", emit);
      this.$emit(emit);
    },
  },
};
</script>