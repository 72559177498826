<template>
  <resource-view base="provim" group="page" name="not_found" v-slot="view">
    <section
      class="
        page--login page--standalone
        justify-content-center
        align-items-center
        d-flex
        vh-100
      "
    >
      <main class="text-center fadeIn">
        <b-card class="border-0 rounded shadow p-1 m-2">
          <header>
            <h4 class="mb-4">
              <i class="fal mr-1" :class="`fa-${view['icon']}`" />
              {{ view["title"] }}
            </h4>
          </header>
          <p class="text-muted" v-html="view['description']" />
          <b-button
            class="mt-3"
            variant="primary"
            @click="$router.push({ name: 'dashboard' })"
          >
            {{ __("Doorgaan | Continue") }}
            <i class="fal fa-arrow-right ml-1" />
          </b-button>
        </b-card>
      </main>
    </section>
  </resource-view>
</template>

<script>
export default {};
</script>