<template>
  <b-card class="border-0 shadow p-1">
    <header>
      <h4>
        <i v-if="icon" :class="`mr-1 fal fa-${icon}`" />
        {{ title }}
      </h4>
      <p class="text-muted" v-if="description" v-html="description" />
      <hr v-if="title || description" />
    </header>
    <slot />
  </b-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>