<template>
  <!-- Submit -->
  <b-button
    type="submit"
    :block="true"
    variant="primary"
    :disabled="loading"
    @click="$emit('submit')"
  >
    <!-- Label -->
    <template v-if="loading">
      <i class="fal fa-spinner fa-spin" />
      {{ __("Bezig met laden... | Loading...") }}
    </template>
    <template v-else>
      <slot>
        {{ label }}
        <i v-if="icon" :class="`ml-1 fal fa-${icon}`" />
      </slot>
    </template>
  </b-button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "OK",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>