<template>
    <main :class="{ loading }">
      <app-form
        v-model="input"
        :loader="false"
        :invalid="invalid"
        :fieldset="fieldset"
        @input="$emit('input', input)"
        @ready="$emit('mounted')"
        @fail="$emit('fail')"
      />
    </main>
  </resource-view>
</template>

<script>
const AppForm = () => import("@/components/app/AppForm");
export default {
  components: {
    AppForm,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    request: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object | Array,
      default: null,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      input: {},
      invalid: {},
      fieldset: {},
      loading: false,
    };
  },
  watch: {
    errors: {
      deep: true,
      handler: function (value) {
        this.invalid = _.mapKeys(value, (v, key) => {
          return key.replace("filter.", "");
        });
      },
    },
  },
  beforeMount() {
    this.setFieldset(this.replaceValues);
  },
  methods: {
    setFieldset(callback) {
      this.loading = true;
      this.$http
        .get(`${this.request["url"]}/forms/item/lookup`, {
          params: { ...this.$objectToQueryParams(this.values, "values") },
        })
        .then(({ data: { data } }) => {
          this.loading = false;
          this.fieldset = data;
          callback(data);
        });
    },
    replaceValues() {
      _.forEach(this.$var2val(this.fieldset, this.form), (value, field) => {
        this.fieldset[field].value = value;
      });
    },
  },
};
</script>