<template>
  <resource-view
    ref="$view"
    :parent="true"
    :group="group"
    :base="base"
    :name="name"
    v-slot="view"
  >
    <article :class="`content--${name || $route['name']}`">
      <app-header v-bind="view" :size="size" :border="true" />
      <app-form
        v-bind="$attrs"
        v-model="values"
        :fieldset="fields"
        :feedback="true"
        :loader="false"
        :footer="true"
        :method="method"
        :action="action"
        @change="(value, field) => $emit('change', value, field)"
        @submit="(data) => $emit('submit', data)"
        @success="onFormSuccess"
        @emit="emit"
      >
        <slot></slot>
      </app-form>
    </article>
  </resource-view>
</template>

<script>
import AppHeader from "@/components/app/AppHeader";
import AppForm from "@/components/app/AppForm";
export default {
  inheritAttrs: false,
  components: {
    AppHeader,
    AppForm,
  },
  props: {
    base: {
      type: String,
      default: "",
    },
    group: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: undefined,
    },
    method: {
      type: String,
      default: "put",
    },
    size: {
      type: String,
      default: "sm",
    },
  },
  beforeMount() {
    this.setFields();
  },
  data() {
    return {
      fields: {},
      values: {},
    };
  },
  computed: {
    action() {
      return _.filter([
        this.base,
        this.group,
        this.name || this.$route["name"],
      ]).join("/");
    },
    fieldsUrl() {
      return `${this.base}/forms/${this.group}/${
        this.name || this.$route["name"]
      }`;
    },
  },
  methods: {
    setFields() {
      this.$http.get(this.fieldsUrl).then(({ data: { data } }) => {
        this.loading = false;
        this.fields = data;
      });
    },
    onFormSuccess() {
      this.$root.$emit("feedback", { group: this.group });
      this.$emit("success", this.values);
      this.$refs.$view.setView();
    },
    emit(emit) {
      this.$emit("emit", emit);
      this.$emit(emit);
    },
  },
};
</script>