<template>
  <section class="content--default">
    <main class="content__container">
      <article class="content__body">
        <keep-alive max="10">
          <router-view :key="$route.fullPath" />
        </keep-alive>
      </article>
    </main>
  </section>
</template>

<script>
export default {}
</script>