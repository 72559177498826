<template>
  <article>
    <template v-if="!success">
      <!-- Email field -->
      <form-field
        v-model="email"
        :errors="_.isEmpty(errors['email'])"
        class="mt-6"
        :field="{
          name: 'email',
          label: __('E-mailadres | Email address'),
          placeholder: __(
            'Voer uw e-mailadres in | Please enter your email address'
          ),
          required: true,
          control: 'input',
          type: 'email',
          class: 'd-block text-center',
        }"
      />
      <!-- Password field -->
      <form-field
        v-model="password"
        :errors="_.isEmpty(errors['password'])"
        class="d-block mt-3"
        :field="{
          name: 'password',
          label: __('Kies nieuw wachtwoord | Choose new password'),
          placeholder: __(
            'Voer uw nieuwe wachtwoord in | Please enter your new password'
          ),
          autofocus: true,
          required: true,
          control: 'input',
          type: 'password',
          class: 'text-center',
        }"
      />
      <!-- Confirm password field -->
      <form-field
        v-model="password_confirmation"
        :errors="_.isEmpty(errors['password_confirmation'])"
        class="d-block mt-3"
        :field="{
          name: 'password',
          label: __('Bevestig nieuw wachtwoord | Confirm new password'),
          placeholder: __(
            'Voer uw nieuwe wachtwoord opnieuw in | Please confirm your new password'
          ),
          required: true,
          control: 'input',
          type: 'password',
          class: 'text-center',
        }"
      />
      <!-- Reset button -->
      <b-button
        class="mt-4"
        :block="true"
        variant="primary"
        :disabled="loading"
        @click="resetPassword"
      >
        <template v-if="loading">
          {{ __("Bezig met laden... | Loading...") }}
        </template>
        <template v-else>
          {{ __("Nieuw wachtwoord opslaan | Save new password") }}
        </template>
      </b-button>
    </template>
    <!-- Message -->
    <b-alert
      class="mt-3 mb-0"
      :show="!_.isEmpty(message)"
      :variant="success ? 'success' : 'danger'"
    >
      {{ message }}
    </b-alert>
   <!-- Footer -->
   <auth-reset-footer />
  </article>
</template>

<script>
import AuthResetFooter from "@/components/auth/AuthResetFooter";
import FormField from "@/components/form/FormField";
import AuthCard from "@/components/auth/AuthCard";
import AuthService from "@/services/AuthService";
export default {
  components: {
    AuthResetFooter,
    FormField,
    AuthCard,
  },
  data() {
    return {
      email: this.$route.query.username,
      token: this.$route.query.token,
      password: null,
      password_confirmation: null,
      message: null,
      success: false,
      loading: false,
      errors: [],
    };
  },
  methods: {
    resetPassword() {
      this.errors = [];
      this.message = null;
      this.loading = true;
      this.success = false;
      AuthService.resetPassword(
        this.token,
        this.email,
        this.password,
        this.password_confirmation,
        { lang: this.$lang() }
      )
        .then(({ data: { message } }) => {
          this.message = message;
          this.success = true;
        })
        .catch(({ response: { data } }) => {
          this.message = data.message;
          this.errors = data.errors || true;
          this.success = false;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>