<template>
  <header
    class="content__header fadeIn"
    :class="{ 'header--border': border }"
    v-if="title || description || icon"
  >
    <div class="d-flex flex-wrap justify-content-between align-items-center">
      <div class="header__info d-flex align-items-center">
        <b-img
          :rounded="image['circle'] ? 'circle' : true"
          v-if="_.get(image, 'source', false)"
          blank-src="/img/placeholder.jpg"
          class="info__image mr-4"
          :src="image['source']"
        />
        <div class="info__text d-flex align-items-center flex-wrap pb-0">
          <component :is="headingTag" class="text__heading">
            <span class="heading__icon" v-if="icon">
              <i class="fal" :class="`fa-${icon}`" />
            </span>
            <span class="heading__text">{{ title | ucfirst }}</span>
            <small class="heading__description mb-0">
              {{ description }}
            </small>
          </component>
        </div>
      </div>
      <nav class="header__buttons" v-if="!!$scopedSlots['buttons']">
        <slot name="buttons"></slot>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: null,
    },
    image: {
      type: Object | Boolean,
      default: () => ({}),
    },
    border: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  computed: {
    headingTag() {
      return (
        {
          sm: "h4",
          md: "h3",
          lg: "h2",
        }[this.size] || "md"
      );
    },
  },
};
</script>
