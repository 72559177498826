<template>
  <section class="app__alert" style="z-index: 999999999">
    <template v-if="layout == 'modal' && title">
      <app-modal
        class="alert--modal"
        id="alertModal"
        :front="true"
        :size="size"
        :note="note"
        :icon="icon"
        :title="title"
        :align="align"
        :resize="resize"
        @close="$emit('close')"
      >
        <slot>
          <b-alert
            show
            align="center"
            :variant="variant"
            v-html="description"
            class="mb-0 alert--modal"
          />
        </slot>
        <template slot="footer">
          <b-button block @click="$emit('close')" variant="outline-secondary">
            {{ __("close") }}
          </b-button>
        </template>
      </app-modal>
    </template>
    <template v-else-if="layout == 'panel'">
      <slot>
        <b-alert
          show
          :fade="fade"
          :variant="variant"
          :dismissible="dismissible"
          @dismissed="$emit('closed')"
          class="mb-0 alert--default text-center"
        >
          <i :class="`fal fa-${icon}`" v-if="icon" /> &nbsp;
          <span class="mb-0">{{ description }}</span>
        </b-alert>
      </slot>
    </template>
    <template v-else-if="layout == 'toast'">
      <slot>
        <div class="alert--toast">
          <!-- COMING SOON -->
        </div>
      </slot>
    </template>
  </section>
</template>

<script>
import AppModal from "@/components/app/AppModal";
export default {
  components: {
    AppModal,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "info-circle",
    },
    variant: {
      type: String,
      default: "info",
    },
    layout: {
      type: String,
      default: "modal",
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    note() {
      return _.get(this.settings, "note", null);
    },
    size() {
      return _.get(this.settings, "size", "xs");
    },
    fade() {
      return _.get(this.settings, "fade", false);
    },
    align() {
      return _.get(this.settings, "align", "center");
    },
    resize() {
      return _.get(this.settings, "resize", false);
    },
    dismissible() {
      return _.get(this.settings, "dismissible", false);
    },
  },
};
</script>