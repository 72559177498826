<template>
  <item-manage />
</template>

<script>
import ItemManage from "@/views/item/ItemManage";
export default {
  name: "ItemCreate",
  components: {
    ItemManage,
  },
};
</script>