<template>
  <section class="mr-4 d-flex">
    <!-- Managing Data (CRUD) -->
    <b-button-group
      size="sm"
      class="mt-2 mr-2 align-self-start"
      v-if="_.some(actionRoutes)"
    >
      <template v-for="(action, index) in actionRoutes">
        <b-button
          :key="index"
          @click="$emit('action', action)"
          v-b-tooltip.bottom.hover="__(`actions.${action}`)"
          v-if="meta['total'] > 0 || action == 'create'"
        >
          <i
            class="fal"
            :class="{
              'fa-list-alt': action == 'show',
              'fa-plus': action == 'create',
              'fa-pen': action == 'edit',
              'fa-trash': action == 'delete',
            }"
          />
          <span v-if="meta['total'] == 0 && action == 'create'">
            &nbsp;{{ __("Toevoegen | Add") }}
          </span>
        </b-button>
      </template>
    </b-button-group>
    <!-- Custom Buttons -->
    <resource-buttons v-slot="buttons" :base="resource" group="list">
      <b-button-group
        size="sm"
        class="mt-2 mr-2 align-self-start"
        v-if="_.some(buttons)"
      >
        <b-button
          :key="index"
          :variant="button.variant"
          v-for="(button, index) in buttons"
          @click="onCustomButtonClick(button)"
          v-b-tooltip.bottom.hover="button.tooltip"
          v-show="button.admin_only ? isAdmin : true"
        >
          <i v-if="button.icon" :class="`fal fa-${button.icon}`" />
        </b-button>
      </b-button-group>
    </resource-buttons>
    <!-- Exporting Data -->
    <b-button-group size="sm" class="mt-2 mr-2 align-self-start" v-if="false">
      <!-- Copy Button -->
      <!-- <b-button
        @click="$copy(selected['value'])"
        v-b-tooltip.bottom.hover="__('actions.copy')"
      >
        <i class="fal fa-copy" />
      </b-button> -->
      <!-- Dropdown Toggle Button -->
      <b-dropdown
        right
        size="sm"
        v-b-tooltip.right.hover="__('actions.exportTo')"
      >
        <template slot="button-content">
          <i class="fal fa-download" />
        </template>
        <template v-for="(option, index) in ['excel', 'word', 'pdf']">
          <!-- File Export Button -->
          <b-dropdown-item :key="index" @click="$emit('export', option)">
            <i :class="`fal fa-file-${option} mr-1`" />
            {{ __(`files.${option}`) }}
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </b-button-group>
  </section>
</template>

<script>
import { getApiBaseUrl } from "@/services/ApiService";
import { mapGetters } from "vuex";
export default {
  props: {
    abilities: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    actionRoutes() {
      return this.$getActionRoutes(this.abilities);
    },
    resource() {
      let path = this.meta["path"];
      let resource = path.replace(getApiBaseUrl() + "/", "", path);
      return resource;
    },
    ...mapGetters("auth", ["isAdmin"]),
  },
  methods: {
    onCustomButtonClick(button) {
      if (!button["confirm"] || this.$confirm(button["confirm"])) {
        this.$emit("action", {
          route: button["action"],
          method: button["method"],
          params: button["params"],
          target: button["target"],
          query: button["query"],
        });
      }
    },
  },
};
</script>