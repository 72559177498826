<template>
  <section class="content__container" :class="{ loading }">
    <slot name="header" />
    <main class="data__frame">
      <b-table
        small
        striped
        selectable
        sticky-header
        no-border-collapse
        class="datatable"
        select-mode="single"
        selected-variant="active"
        :items="data"
        :fields="fields"
        @row-selected="onRowSelected"
        ref="table"
      >
        <template v-slot:head()="data">
          <span
            class="sort"
            @click="onHeaderClick(data)"
            v-b-tooltip.hover="getTooltip(data)"
            :class="{
              'sort--active': sorts[data['column']],
              'sort--desc': sorts[data['column']] === 'desc',
              'sort--asc': sorts[data['column']] === 'asc',
            }"
          >
            <span class="header__text">
              {{ data["label"] }}
            </span>
          </span>
        </template>
        <template v-slot:cell()="data">
          <text-highlight :queries="highlightQueries" v-html="data['value']" />
        </template>
      </b-table>
    </main>
    <slot name="footer" />
  </section>
</template>

<script>
// replace with Mark.js to allow for parent highlighting, including Cards component?
import TextHighlight from "vue-text-highlight";
export default {
  inheritAttrs: false,
  components: {
    TextHighlight,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
      required: false,
    },
    keywords: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sorts: {},
    };
  },
  computed: {
    fields() {
      return _.filter(
        _.keys(_.first(this.data)),
        (column) => column.indexOf("_") !== 0
      );
    },
    highlightQueries() {
      return !_.isEmpty(this.keywords) ? [this.keywords] : [];
    },
  },
  methods: {
    getTooltip({ column }) {
      let direction = this.getSortDirection(this.sorts[column]) || "default";
      return this.__(`index.header.sort.${direction}`);
    },
    onRowSelected(row) {
      this.$refs["table"].clearSelected();
      if (_.first(row)) this.$emit("select", _.first(row));
    },
    getSortDirection(oldDirection) {
      let newDirection = "asc";
      if (oldDirection) newDirection = oldDirection === "asc" ? "desc" : null;
      return newDirection;
    },
    onHeaderClick({ column }) {
      let direction = this.getSortDirection(this.sorts[column]);
      this.$emit("sort", { [column]: direction });
      this.sorts[column] = direction;
    },
  },
};
</script>