<script>
import moment from "moment";
export default {
  methods: {
    // get a specific configuration dataset from the store
    $config(key) {
      return _.get(this.$store.getters, `config/${key}`, {});
    },
    // get a specific configuration dataset from the store
    $user(property = null) {
      const user = _.get(this.$store.getters, "auth/authUser", {});
      return _.get(user, property, user);
    },
    // get a specific label from the configured labels
    __(key, fallback = "") {
      if (key.includes(" | ")) {
        const [nl, en] = key.split(" | ");
        return this.$lang() == "nl" ? nl : en;
      } else if (_.has(this.$config("labels"), key)) {
        return _.get(this.$config("labels"), key, fallback);
      }
      console.warn(key + " was not defined");
    },
    // check if provided string is code
    $isId(input = null) {
      if (!input) return false;
      return this.$isNumeric(input.toString().replace(/\./g, ""));
    },
    // check if the provided input is numeric
    $isNumeric(input) {
      if (typeof input == "string") {
        return !isNaN(input) && !isNaN(parseFloat(input));
      } else {
        return typeof input == "number";
      }
    },
    // get the action routes based on the provided abilities
    $getActionRoutes(abilities = {}) {
      return _.filter(
        _.map(
          {
            view: "show",
            create: "create",
            update: "edit",
            delete: "delete",
          },
          (route, action) => {
            if (_.get(abilities, action, false)) return route;
          }
        )
      );
    },
    // check if provided element has overflowing content
    $hasOverflow({ clientWidth, clientHeight, scrollWidth, scrollHeight }) {
      return scrollHeight > clientHeight || scrollWidth > clientWidth;
    },
    // remove all markup from provided input
    $removeMarkup(input) {
      return _.isString(input) ? input.replace(/<\/?[^>]+(>|$)/g, "") : input;
    },
    // map all field values to new object
    $mapFieldValues(fields) {
      return _.isObject(fields)
        ? _.omitBy(
            _.mapValues(fields, (field) => _.get(field, "value")),
            _.isNil
          )
        : fields;
    },
    // replace all variables defined using curly braces
    $var2val(fields, values) {
      var output = {};
      var fields = this.$mapFieldValues(fields);
      _.forEach(fields, (fieldValue, fieldName) => {
        var newFieldValue = fieldValue;
        if (this.$hasVars(fieldValue)) {
          let variableNames = this.$findVars(fieldValue);
          _.forEach(variableNames, (variableName) => {
            let searchValue = `{${variableName}}`;
            let searchRegex = new RegExp(searchValue, "g");
            let replaceValue = _.get(values, variableName, "");
            let replaceValueHuman = this.$getHumanValue(
              replaceValue,
              variableName
            );
            newFieldValue = newFieldValue.replace(
              searchRegex,
              replaceValueHuman
            );
          });
        }
        output[fieldName] = newFieldValue;
      });
      return output;
    },
    // check if the input has variables defined using curly braces
    $hasVars(input) {
      return /{([^}]+)}/g.test(input);
    },
    // find variables defined using curly braces
    $findVars(input) {
      var words = [];
      var match = null;
      let regex = /{([^}]+)}/g;
      while ((match = regex.exec(input))) {
        words.push(match[1]);
      }
      return words;
    },
    // get the human readable version of the provided value
    $getHumanValue(value, field) {
      if (_.isString(value)) {
        if (/(\r\n|\n|\r)/gm.test(value)) {
          return value.replace(/(\r\n|\n|\r)/gm, "<br>");
        } else if (field === "identity") {
          return this.$sysShort(value, 6);
        } else if (moment(value, "YYYY-MM-DD", true).isValid()) {
          return moment(value).format("DD-MM-YYYY");
        } else if (["true", "false"].includes(value)) {
          return this.$config("labels")[value];
        }
      } else if (_.isInteger(value) || _.isNumber(value)) {
        return value.toString();
      } else if (_.isBoolean(value)) {
        return this.$getHumanValue(value ? "true" : "false");
      } else if (_.isObject(value) && _.has(value, "value")) {
        let innerValue = _.get(value, "value", "");
        return this.$getHumanValue(innerValue);
      }
      return value;
    },
    // get the device type
    $getDevice() {
      if (this.$isTablet()) {
        return "tablet";
      } else if (this.$isMobile()) {
        return "phone";
      } else {
        return "desktop";
      }
    },
    // check if current device is mobile
    // ref. https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
    $isMobile() {
      var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
      var mq = function (query) {
        return window.matchMedia(query).matches;
      };
      if (
        "ontouchstart" in window ||
        (window.DocumentTouch && document instanceof DocumentTouch)
      ) {
        return true;
      }
      var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
        ""
      );
      return mq(query);
    },
    // check if current device is tablet
    // ref. https://stackoverflow.com/questions/50195475/detect-if-device-is-tablet
    $isTablet() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      return isTablet;
    },
    // get the context or meta information
    $context() {
      return JSON.stringify({
        application: "Provim Web v4",
        component: this.$options._componentTag,
        device: this.$getDevice(),
        route: this.$route["name"],
        path: this.$route["path"],
        lang: this.$lang(),
      });
    },
    // copy provided string to clipboard
    $copy(string) {
      // Create new element
      var el = document.createElement("textarea");
      // Set value (string to be copied)
      el.value = string;
      // Set non-editable to avoid focus and move outside of view
      el.setAttribute("readonly", "");
      el.style = { position: "absolute", left: "-9999px" };
      document.body.appendChild(el);
      // Select text inside element
      el.select();
      // Copy text to clipboard
      document.execCommand("copy");
      // Remove temporary element
      document.body.removeChild(el);
    },
    // transform a identity value to 'sys short'
    $sysShort(identity, amount) {
      identity = identity.toString();
      return identity.length < amount
        ? this.$sysShort("0" + identity, amount)
        : identity;
    },
    // convert object to URL parameters string
    $objectToQueryParams(input, name, assoc_value = false) {
      if (_.isString(input)) {
        if (!assoc_value) return { [name]: input };
        var input = [input];
      }
      return _.mapKeys(input, (val, key) =>
        key && val ? `${name}[${_.isArray(input) ? val : key}]` : null
      );
    },
    // convert URL parameters string to object
    $queryParamsToObject(string) {
      const params = new URLSearchParams(string);
      const entries = params.entries();
      const result = {};
      for (const [key, value] of entries) {
        result[key] = value;
      }
      return result;
    },
    // check if the current route queries a resource variant
    $hasResourceVariantQuery() {
      return (
        !this.$route.query["view"] &&
        !this.$route.query["table"] &&
        !this.$route.query["function"] &&
        !this.$route.query["procedure"]
      );
    },
    // get the current domain optionally with protocol
    $getDomain(full = true) {
      return (full ? location.protocol + "//" : "") + location.host;
    },
    // get the key codes for every arrow key
    $getKeyCodes() {
      return { left: 37, up: 38, right: 39, down: 40 };
    },
    $uniqid(length = 6) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    // get the language of the user
    $lang(fallback = "en") {
      let lang =
        _.get(this.$user(), "language") ||
        navigator.userLanguage ||
        navigator.language ||
        fallback;
      return lang.substring(0, 2);
    },
    $route2url(route) {
      const resolved = this.$router.resolve(route);
      const fullPath = resolved.route.fullPath;
      const baseUrl = window.location.origin;
      return `${baseUrl}${fullPath}`;
    },
    // determine if the provided value is 'boolish'
    $guessBoolean(value) {
      if (["1", "on", "yes", "true", "enabled"].includes(_.lowerCase(value))) {
        return true;
      } else if (
        ["0", "off", "no", "false", "disabled"].includes(_.lowerCase(value))
      ) {
        return false;
      }
      return null;
    },
    // ask for confirmation
    $confirm(text = null) {
      const fallback = "Weet u het zeker? | Are you sure?";
      const message = _.isString(text) ? text : fallback;
      return confirm(this.__(message));
    },
    // check if provided text is JSON
    $isJSON(value) {
      try {
        JSON.parse(value);
        return true;
      } catch (e) {
        return false;
      }
    },
    // flatten the provided object
    $flatten(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    // log the provided value
    $log(value) {
      if (_.isObject(value) || _.isArray(value)) {
        console.log(JSON.parse(JSON.stringify(value)));
      } else {
        console.log(value);
      }
    },
    // get the root url of the application
    $rootUrl() {
      return process.env.VUE_APP_ROOT_URL;
    },
    // check if the current app is in fact the root
    $isRoot() {
      return this.$rootUrl() == this.$getDomain();
    },
  },
};
</script>
