<template>
  <!-- Security form -->
  <view-form
    group="user"
    base="provim"
    name="security"
    @manage-recovery-codes="
      showTwoFactorAuth = true;
      showTwoFactorCodes = true;
    "
    @manage-sessions="showSessions = true"
    @logout-devices="showLogoutDevices = true"
    @setup-two-factor-auth="showTwoFactorAuth = true"
    @disable-two-factor-auth="showTwoFactorAuth = true"
    :data-two-factor-auth="authUser['2fa'] ? 'true' : 'false'"
  >
    <!-- Two-Factor Authentication -->
    <user-two-factor-auth
      v-if="showTwoFactorAuth"
      :recovery="showTwoFactorCodes"
      @close="
        $emit('updated');
        showTwoFactorAuth = false;
        showTwoFactorCodes = false;
      "
    />
    <!-- Logout Devices -->
    <user-logout-devices
      v-if="showLogoutDevices"
      @close="showLogoutDevices = false"
    />
    <!-- Sessions -->
    <user-sessions
      v-if="showSessions"
      @close="showSessions = false"
    />
  </view-form>
</template>

<script>
import UserTwoFactorAuth from "@/components/user/UserTwoFactorAuth";
import UserLogoutDevices from "@/components/user/UserLogoutDevices";
import UserSessions from "@/components/user/UserSessions";
import ViewForm from "@/components/form/ViewForm";
import { mapGetters } from "vuex";
export default {
  components: {
    UserTwoFactorAuth,
    UserLogoutDevices,
    UserSessions,
    ViewForm,
  },
  computed: mapGetters("auth", ["authUser"]),
  data() {
    return {
      showTwoFactorCodes: false,
      showTwoFactorAuth: false,
      showLogoutDevices: false,
      showSessions: false,
    };
  },
};
</script>

<style>
[data-two-factor-auth="false"] .two-factor-auth--setup {
  display: block;
}
[data-two-factor-auth="false"] .two-factor-auth--disable,
[data-two-factor-auth="false"] .two-factor-auth--recovery-codes {
  display: none;
}
[data-two-factor-auth="true"] .two-factor-auth--setup {
  display: none;
}
[data-two-factor-auth="true"] .two-factor-auth--disable,
[data-two-factor-auth="true"] .two-factor-auth--recovery-codes {
  display: block;
}
</style>