<template>
  <div>
    <!-- Pad -->
    <VueSignaturePad
      width="600px"
      height="250px"
      class="signature"
      ref="signaturePad"
      :class="classNames"
      :options="options"
    />
    <!-- Controls -->
    <footer class="mt-3 mb-1 d-flex" style="gap: 1rem">
      <!-- Clear Button -->
      <b-button size="sm" variant="outline-secondary" @click="clear">
        <i class="fal fa-eraser mr-1"></i>
        {{ __("Wissen | Clear") }}
      </b-button>
      <!-- Lock Button -->
      <b-button @click="lock" size="sm" variant="outline-secondary">
        <i :class="`fal fa-${lockIcon}`"></i>
        {{ __(lockLabel) }}
      </b-button>
    </footer>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value(value) {
      this.input = value;
    },
    input(input) {
      this.$emit("change", input);
    },
  },
  data() {
    return {
      input: "",
      locked: false,
      options: {
        penColor: "#00295a",
        onBegin: () => this.pad.resizeCanvas(),
        onEnd: () => this.toData(),
      },
    };
  },
  computed: {
    pad() {
      return this.$refs["signaturePad"];
    },
    lockIcon() {
      return this.locked ? "unlock" : "lock";
    },
    lockLabel() {
      return this.locked ? "Ontgrendelen | Unlock" : "Vergrendelen | Lock";
    },
    classNames() {
      return { "signature--locked": this.locked };
    },
  },
  mounted() {
    if (!_.isEmpty(this.value)) {
      this.input = JSON.parse(this.value);
      this.pad.fromData(this.input);
      setTimeout(() => this.$refs["signaturePad"].resizeCanvas(), 0);
    }
  },
  methods: {
    toData() {
      this.input = JSON.stringify(this.pad.toData());
    },
    lock() {
      if (this.locked) {
        this.pad.openSignaturePad();
        this.locked = false;
      } else {
        this.pad.lockSignaturePad();
        this.locked = true;
      }
    },
    clear() {
      this.pad.clearSignature();
      this.input = null;
    },
  },
};
</script>