<template>
  <resource-model v-slot="model">
    <resource-view v-slot="view" @updated="setData">
      <!-- Item Show -->
      <section class="content__container item__show" :class="{ loading }">
        <!-- Item Header -->
        <item-header :view="view" :abilities="model['abilities']" />
        <!-- Vertical Data Grid -->
        <data-table-vert
          :data="data"
          :html="view['html']"
          class="content__body"
        />
      </section>
    </resource-view>
  </resource-model>
</template>

<script>
import DataTableVert from "@/components/data/DataTableVert";
import ItemHeader from "@/components/item/ItemHeader";
export default {
  components: {
    DataTableVert,
    ItemHeader,
  },
  data() {
    return {
      data: {},
      loading: false,
    };
  },
  beforeMount() {
    this.setData();
  },
  computed: {
    viewPayload() {
      return this.$hasResourceVariantQuery() ? { view: true } : {};
    },
  },
  methods: {
    setData() {
      this.loading = true;
      this.data = {};
      this.$http
        .get(this.$resource.getApiPath(), {
          params: {
            ...this.viewPayload,
            ...this.$route["query"],
          },
        })
        .then(({ data: { data } }) => (this.data = data))
        .finally(() => (this.loading = false));
    },
  },
};
</script>