<template>
  <article class="datatable datatable--vertical" v-if="_.some(data)">
    <table>
      <tbody>
        <template v-for="(value, field) in data">
          <tr :key="field" v-if="showField(field)">
            <td>
              <strong>{{ field }}</strong>
            </td>
            <td class="w-100 pl-5">
              <template v-if="html.includes(field) || html === true">
                <span v-html="value" />
              </template>
              <template v-else>
                {{ value }}
              </template>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </article>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    html: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  methods: {
    showField(field) {
      return (
        field.includes("Sys_") === false &&
        field.indexOf("_") !== 0 &&
        field !== "EmptyCol"
      );
    },
  },
};
</script>