<template>
  <article class="item__index">
    <resource-view :parent="true" v-slot="view" @updated="$refs.$data.resetData()">
      <index-data
        :request="{ url: $route.params['resource'] }"
        class="index__data"
        :view="view"
        ref="$data"
      />
    </resource-view>
  </article>
</template>

<script>
import IndexData from "@/components/index/IndexData";
export default {
  components: {
    IndexData,
  },
};
</script>