import Vue from "vue";
import store from "@/store/index";
import routes from '@/router/routes';
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	routes,
	// this ensures the previous scrolling position is retained
	scrollBehavior(to, from, savedPosition) {
		return savedPosition || { x: 0, y: 0 };
	}
});

router.beforeEach((to, from, next) => {
	// Authentication Middleware
	const user = store.getters["auth/authUser"];
	// check if the route has the authentication middleware set
	const auth = to.matched.some((record) => record.meta['auth']);
	// allow request if authentication is not required or the user is already logged in 
	if (!auth || user) return next();
	// check if we're not already on the login page
	if (to['name'] !== 'login') {
		// request the authenticated user via AJAX
		store.dispatch("auth/getAuthUser").then(() => {
			// determine if we're dealing with an actual module or the application root
			const modules = _.get(store.getters["auth/authUser"], "modules", []);
			const hosts = _.map(modules, (module) => module['host']);
			const isModule = hosts.some(host => window.location.href.includes(host));
			// determine if the user is logged in by referring to the global state
			const isLoggedIn = !_.isEmpty(store.getters["auth/authUser"]);
			// get the original path that was requested by the user
			const query = to.fullPath.indexOf("/auth") === -1 && to.fullPath != '/' ? { redirect: to.fullPath } : {};
			// handle next request by redirecting and optionally adding the originally requested path
			next(isModule && isLoggedIn ? { path: from.query.redirect } : { name: "login", query });
		});
	}
});

export default router;
