// ---------------------------------------------
// Application VueJS Filters
// Version: 1.0
// Author: M. Nuyens <mark@emozio-interactive.nl
// Website: https://emozio-interactive.nl
// ---------------------------------------------

import Vue from 'vue';
import moment from 'moment';
import { _ } from 'core-js';

// Highlight specific words in provided string
Vue.filter('highlight', function (value, mark) {
	if (!value) return value;
	return value.replace(new RegExp(mark, 'gi'), function (match) {
		return match.trim() ? `<mark>${match}</mark>` : '';
	});
});

// convert provided value to JSON
Vue.filter('json', function (value) {
	return JSON.stringify(value);
});

// get first name of provided full name
Vue.filter('first_name', function (value) {
	return value ? value.split(' ').slice(0, 1).join() : '';
});

// trim provided value from any spaces
Vue.filter('trim', function (value) {
	return value.replace(/(\r\n|\n|\r|\t)/gm, " ");
});

// trim provided value from any spaces
Vue.filter('human', function (value) {
	if (_.isString(value)) {
		if (moment(value, "YYYY-MM-DD", true).isValid()) {
			return moment(value).format("DD-MM-YYYY");
		} else if (["true", "false"].includes(value)) {
			return __(value);
		}
	} else if (_.isBoolean(value)) {
		return this.__(value ? 'true' : 'false');
	}
	return value;
});

// remove specific characters from string
Vue.filter('remove', function (value, find) {
	return _.isString(value) ? value.replace(new RegExp(find, 'g'), '') : value;
});

// limit provided text and append "...""
Vue.filter('readMore', function (text, length = 40, suffix = '...') {
	return _.isString(text) ? text.substring(0, length) + suffix : text;
});

// extract a property values from an array of objects
Vue.filter('pluck', function (objects, key) {
	return objects.map(function (object) {
		return object[key];
	});
});

// return the first element of an array
Vue.filter('first', function (values) {
	if (Array.isArray(values)) {
		return values[0];
	}
	return values;
});

// return the last element of an array
Vue.filter('last', function (values) {
	if (Array.isArray(values)) {
		return values[values.length - 1];
	}
	return values;
});

// return a copy of the array without the given elements
Vue.filter('without', function (values, exclude) {
	return values.filter(function (element) {
		return !exclude.includes(element);
	});
});

// remove duplicated elements in an array
Vue.filter('unique', function (values, unique) {
	return values.filter(function (element, index, self) {
		return index == self.indexOf(element);
	});
});

// reverse the order of the array
Vue.filter('reverse', function (value) {
	return _.reverse(value);
});

// normalize value (e.g. from boolean to "yes" or "no")
Vue.filter('normalize', function (value) {
	if (value === true) return 'Ja';
	if (value === false) return 'Nee';

	return value;
});

// convert the first character of the provided string to uppercase 
Vue.filter('ucfirst', function (value) {
	return _.upperFirst(value);
});