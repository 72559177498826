<template>
  <resource-view name="flag" v-slot="view">
    <app-modal
      size="xs"
      v-bind="view"
      :confirm="__('saveChanges')"
      @confirm="onModalSubmit"
      @close="$emit('close')"
    >
      <article :class="{ loading }">
        <app-form
          v-model="input"
          :loader="false"
          :invalid="errors"
          :fieldset="fieldset"
        />
      </article>
    </app-modal>
  </resource-view>
</template>

<script>
const AppForm = () => import("@/components/app/AppForm");
import AppModal from "@/components/app/AppModal";
export default {
  components: {
    AppModal,
    AppForm,
  },
  beforeMount() {
    this.setForm();
  },
  data() {
    return {
      input: {},
      errors: {},
      fieldset: {},
      loading: false,
    };
  },
  methods: {
    setForm() {
      this.loading = true;
      this.$http
        .get(this.$resource.getApiPath("forms/item/flag"))
        .then(({ data: { data } }) => (this.fieldset = data))
        .finally(() => (this.loading = false));
    },
    onModalSubmit() {
      this.loading = true;
      this.$http
        .post(this.$resource.getApiPath("flag"), this.input)
        .then(() => {
          this.$root.$emit("feedback", { key: "flag" });
          this.$emit("close");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>