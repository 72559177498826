<template>
  <item-button v-bind="button" @click="$router.go(-1)" class="mr-auto" />
</template>

<script>
import ItemButton from "@/components/item/Buttons/ItemButton";
export default {
  components: {
    ItemButton,
  },
  props: {
    button: {
      type: Object,
      default: () => {},
    },
  },
};
</script>