<template>
  <b-form-group
    :invalid-feedback="fieldErrors"
    :data-chars-left="charsLeft"
    :label-cols-lg="labelWidth"
    :label-class="labelClass"
    :label-size="fieldSize"
    :state="fieldState"
    :class="fieldClass"
    v-show="isVisible"
    v-if="isActive"
    :id="fieldId"
    class="field fadeIn"
  >
    <template slot="label">
      <label
        :for="`${fieldName}_input`"
        class="label__text"
        v-if="fieldLabel"
        v-html="fieldLabel"
        :title="fieldLabelTitle"
      />
      <template v-if="field['control'] == 'icon'">
        <code class="ml-2">
          <a href="https://fontawesome.com/v5/search?s=light" target="_blank"
            >FontAwesome</a
          >
        </code>
      </template>
      <span
        href="#"
        v-if="hasHelpText"
        class="label--help-button"
        @click="showHelpText = !showHelpText"
        v-b-tooltip.html.hover="helpTextTooltip || 'Help'"
      >
        <i class="fal fa-question-circle" />
      </span>
      <div
        class="label--help-text"
        v-if="showHelpText && helpTextBody"
        v-html="helpTextBody"
      />
    </template>
    <template slot="description">
      <span
        v-if="fieldDescription"
        v-html="fieldDescription"
        class="field__description"
      ></span>
    </template>
    <slot></slot>
    <b-input-group
      :prepend-html="fieldPrepend"
      :append-html="field.append"
      v-show-but-keep-inner="useInputGroup"
    >
      <b-form-checkbox
        v-if="field.control == 'checkbox' && multiValue"
        :indeterminate="indeterminate"
        :size="fieldControlSize"
        v-model="allSelected"
        @change="toggleAll"
        class="mr-3"
      >
        <b>{{ __("all") }}</b>
      </b-form-checkbox>
      <component
        v-if="field.control && field.control !== 'button'"
        :is="fieldComponent"
        :type="fieldType"
        :name="fieldName"
        :value="fieldValueProperty"
        :initial-value="initialFieldValueProperty"
        v-b-tooltip.html.hover="field.tooltip"
        :minlength="field.minlength"
        :maxlength="field.maxlength"
        :readonly="field.readonly"
        :select-size="field.height"
        :style="field.style"
        :button-variant="fieldButtonVariant"
        :autocomplete="fieldAutosuggest"
        :before-upload="onBeforeUpload"
        :placeholder="fieldPlaceholder"
        :autofocus="fieldAutoFocus"
        :required="fieldRequired"
        :disabled="fieldDisabled"
        :separator="fieldSeparator"
        :size="fieldControlSize"
        :buttons="fieldButtons"
        :options="fieldOptions"
        :pattern="fieldPattern"
        :data-1p-ignore="!auth"
        :height="fieldHeight"
        :rows="fieldHeight"
        :state="fieldState"
        :id="fieldControlId"
        :labels="labels"
        :form="fieldForm"
        :errors="errors"
        :max="fieldMax"
        :field="field"
        v-model.lazy="fieldValue"
        v-bind="fieldSettings"
        @values="updateFormValues"
        @change="onFieldChange"
        @focus="onFieldFocus"
        @input="setCharsLeft"
        @blur="onFieldBlur"
        @fail="onFail"
        class="field__control"
        autocapitalize="none"
        spellcheck="false"
        autocorrect="off"
        ref="$field"
      >
        <template
          v-slot:first
          v-if="fieldComponent == 'b-form-select' && !field.required"
        >
          <b-form-select-option selected value="">
            &ndash; {{ field.placeholder || __("selectOption") }}
          </b-form-select-option>
        </template>
        <span
          v-if="
            _.includes(['checkbox', 'radio'], field.control) &&
            _.has(field, 'settings.option')
          "
          class="control__option"
          >{{ field.settings.option }}</span
        >
      </component>
      <form-buttons
        class="input-group-append"
        :buttons="field.buttons"
        @emit="emit"
      />
    </b-input-group>
    <!-- Directory toggle -->
    <b-form-checkbox
      id="multiFile"
      name="multiFile"
      class="text-secondary mt-2"
      @change="onFileDirectoryToggle"
      v-model="fileControlSettings['upload-directories']"
      v-if="field.control == 'files'"
    >
      {{ __("Mappen uploaden | Upload directories") }}
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
import { MediaLibraryAttachment } from "@spatie/media-library-pro-vue2-attachment";
import { MediaLibraryCollection } from "@spatie/media-library-pro-vue2-collection";
import { getXsrfToken } from "@/services/ApiService";

import Autocomplete from "@/components/form/FieldAutocomplete";
import Datepicker from "@/components/form/FieldDatepicker";
import Signature from "@/components/form/FieldSignature";
import FormButtons from "@/components/form/FormButtons";
import Editor from "@/components/form/FieldEditor";
import Custom from "@/components/form/FieldCustom";
import Grid from "@/components/form/FieldGrid";

export default {
  components: {
    MediaLibraryAttachment,
    MediaLibraryCollection,
    Autocomplete,
    FormButtons,
    Datepicker,
    Signature,
    Editor,
    Custom,
    Grid,
  },
  props: {
    name: {
      type: String | Number,
      default: null,
    },
    value: {
      type: Object | Array | String | Boolean,
      default: "",
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: Object | String,
      default: () => ({}),
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    labels: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: String | Array,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    auth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: null,
      charsLeft: null,
      maxlength: null,
      fieldValue: null,
      isDisabled: false,
      allSelected: false,
      showHelpText: false,
      indeterminate: false,
      customComponents: [
        "autocomplete",
        "datepicker",
        "signature",
        "editor",
        "custom",
        "grid",
      ],
      multiFieldControls: ["checkbox", "select", "radio", "file", "tags"],
      fileControlSettings: {
        "upload-domain": process.env.VUE_APP_UPLOAD_URL,
        translations: this.__("media-library"),
        "route-prefix": "vendor/media",
        "with-credentials": true,
        "upload-directories": false,
      },
      lookupControlSettings: { compact: true },
      comboboxControlSettings: {
        editable: false,
        combobox: true,
        preload: true,
        remote: false,
        lookup: false,
      },
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
    fieldValue(newValue, oldValue) {
      this.setDebugger(newValue);
      // this.$emit("input", newValue);
      this.setCheckboxProps(newValue);
      this.setCloneValues(newValue, oldValue);
    },
    form: {
      deep: true,
      immediate: true,
      handler: function (form) {
        this.$emit("toggling");
        setTimeout(() => {
          this.setVisibility(form, (visible) => {
            this.$emit("toggle", this.fieldName, visible);
          });
        });
      },
    },
  },
  mounted() {
    this.$emit("toggle", this.fieldName, this.isVisible);
    this.$emit("input", this.getValue());
    this.setValue(this.getValue());
  },
  computed: {
    fieldComponent() {
      if (this.hasCustomComponent) {
        return this.field.control;
      } else if (_.includes(["combobox", "lookup"], this.field.control)) {
        return "autocomplete";
      } else if (this.field.control == "toggle") {
        return `b-form-${
          this.field.type == "horizontal" ? "radio-group" : "select"
        }`;
      } else if (this.field.control == "file") {
        return "media-library-attachment";
      } else if (this.field.control == "files") {
        return "media-library-collection";
      } else if (this.field.control == "icon") {
        return "b-form-input";
      } else {
        let isGroup =
          _.includes(["checkbox", "radio"], this.field.control) &&
          _.isArray(this.fieldOptions);
        return `b-form-${this.field.control || "input"}${
          isGroup ? "-group" : ""
        }`;
      }
    },
    toggleFieldOptions() {
      return [
        {
          value: "1",
          text: this.__("true"),
          disabled: this.fieldLocked,
        },
        {
          value: "0",
          text: this.__("false"),
          disabled: this.fieldLocked,
        },
      ];
    },
    fieldOptions() {
      if (this.field.control == "toggle") {
        return this.toggleFieldOptions;
      }
      let options = _.get(this.field, "options", []);
      let disabled = this.fieldLocked;
      if (_.some(options)) {
        if (_.isArrayLikeObject(options)) {
          return _.map(options, (option) => ({
            text: option["text"] || option,
            value: option["value"] || option,
            disabled,
            ...option,
          }));
        } else if (_.isObject(options)) {
          return _.map(options, (text, value) => ({ text, value, disabled }));
        }
      }
      // [!] REMOVE CODE BELOW WHEN NO ISSUES OCCUR WITH THIS FIELD
      // } else if (_.isString(_.first(options))) {
      //   return _.map(options, (option) => ({
      //     text: option,
      //     value: option,
      //     disabled: this.fieldLocked,
      //   }));
      // }
    },
    fieldErrors() {
      if (this.field.control !== "custom") {
        var errors = "";
        if (_.isArray(this.errors) && _.some(this.errors)) {
          var errors = this.errors.join("; ");
        } else if (_.isString(this.errors)) {
          var errors = this.errors;
        }
        // remove any HTML characters from label
        return errors.replace(/<[^>]*>?/gm, "");
      }
    },
    fieldType() {
      return _.has(this.field, "type") && !this.field["hidden"]
        ? this.field["type"]
        : "text";
    },
    fieldPattern() {
      if (this.field["pattern"]) {
        return this.field["pattern"];
      } else if (this.isNumericInput) {
        return "[0-9]+";
      }
    },
    fieldName() {
      return this.field["name"] || this.name;
    },
    fieldValueProperty() {
      return this.field["value"] || this.value;
    },
    initialFieldValueProperty() {
      return _.isEmpty(this.fieldValueProperty) ? [] : this.fieldValueProperty;
    },
    fieldLabel() {
      let icon = this.field["icon"]
        ? `<i class="label__icon fal fa-${this.field["icon"]}"></i>&nbsp; `
        : "";
      let fallbackLabel = _.isString(this.field) ? this.field : "&nbsp;";
      let label =
        this.field.control === "lookup"
          ? fallbackLabel
          : _.get(this.field, "label", fallbackLabel);

      return icon + label;
    },
    fieldLabelTitle() {
      return this.field["required"] ? this.__("requiredField") : "";
    },
    labelClass() {
      return {
        field__label: true,
        "label--empty": this.emptyFieldLabel,
        "label--static": !this.field["control"],
        required: this.field.required,
      };
    },
    labelWidth() {
      let width = _.get(this.field, "inline", false);
      return width === true ? "2" : width;
    },
    emptyFieldLabel() {
      return _.isEmpty(this.fieldLabel) || this.fieldLabel == "&nbsp;";
    },
    fieldClass() {
      var fieldClass = `field--${this.fieldSize} `;
      fieldClass += `field--${this.field["control"]}`;
      fieldClass += this.isToggleField
        ? ` toggle--${this.field["type"] || "vertical"}`
        : "";
      fieldClass += " " + _.get(this.field, "class", "");
      return fieldClass;
    },
    fieldId() {
      return this.field.identity || `${this.fieldName}_field`;
    },
    fieldControlId() {
      return _.has(this.field, "settings.identity")
        ? this.field.settings["identity"]
        : `${this.fieldName}_control`;
    },
    fieldState() {
      return _.isEmpty(this.errors) ? undefined : false;
    },
    fieldRequired() {
      if (!this.isVisible) {
        return false;
      }

      return this.field.required;
    },
    fieldSize() {
      return this.field["size"] || this.size || "md";
    },
    fieldControlSize() {
      return this.size
        ? this.size
        : _.has(this.field, "settings.size")
        ? this.field.settings["size"]
        : this.field.size || "md";
    },
    fieldAutoFocus() {
      return _.has(this.field, "autofocus") ? this.field["autofocus"] : false;
    },
    fieldAutosuggest() {
      return this.field.autosuggest
        ? ""
        : this.field["type"] == "password" || this.field["type"] == "email"
        ? "new-password"
        : "off";
    },
    fieldDescription() {
      return this.description || this.field.description;
    },
    isActive() {
      return _.get(this.field, "active", true);
    },
    fieldButtons() {
      return _.has(this.field, "settings.buttons") &&
        this.field.settings.buttons
        ? true
        : false;
    },
    fieldButtonVariant() {
      return _.has(this.field, "settings.buttons") &&
        _.isString(this.field.settings.buttons)
        ? this.field.settings.buttons
        : null;
    },
    fieldPlaceholder() {
      if (this.field.control == "tags" && _.isEmpty(this.field.placeholder)) {
        return this.__("actions.create") + "...";
      }
      return this.field.placeholder;
    },
    fieldDisabled() {
      if (this.field.control == "tags") {
        return this.field.readonly;
      }
      if (_.isObject(this.field.disabled)) {
        var disabled = false;
        _.forEach(this.field.disabled, (value, field) => {
          let formValue = _.get(this.form, field);
          disabled = _.isBoolean(value)
            ? _.isEmpty(formValue)
            : formValue == value;
          if (disabled) return true;
        });
        return disabled;
      } else if ([1, 0].includes(this.field.disabled)) {
        return this.field.disabled == 1 ? true : false;
      } else if (_.isBoolean(this.field.disabled)) {
        return this.field.disabled;
      }
      return false;
    },
    fieldLocked() {
      return this.field.readonly || this.field.disabled;
    },
    fieldSeparator() {
      if (this.field.control == "tags") {
        return _.get(this.field, "settings.tags.separator", " ,;");
      }
    },
    tagsFieldDuplicateTagText() {
      if (this.field.control == "tags") {
        return _.get(
          this.field,
          "settings.tags.duplicate-tag-text",
          this.__("alreadyExists")
        );
      }
    },
    tagsFieldAddButtonText() {
      if (this.field.control == "tags") {
        return _.get(
          this.field,
          "settings.tags.add-button-text",
          this.__("actions.create")
        );
      }
    },
    tagsFieldRemoveOnDelete() {
      if (this.field.control == "tags") {
        return _.get(this.field, "settings.tags.remote-on-delete", true);
      }
    },
    hasCustomComponent() {
      return _.includes(this.customComponents, this.field.control);
    },
    isMultiField() {
      return _.includes(this.multiFieldControls, this.field.control);
    },
    isToggleField() {
      return this.field.control == "toggle";
      // return ["toggle", "radio"].includes(this.field.control);
    },
    hasNoValue() {
      return (
        _.isEmpty(_.toString(this.field.value)) &&
        _.isEmpty(_.toString(this.fieldValue))
      );
    },
    fieldForm() {
      if (["autocomplete", "custom", "grid"].includes(this.fieldComponent)) {
        return this.form;
      }
    },
    autocompleteControlSettings() {
      return _.get(this.field, "settings.autocomplete", {});
    },
    fieldSettings() {
      switch (this.field.control) {
        case "autocomplete":
          return this.autocompleteControlSettings;
          break;
        case "lookup":
          return {
            ...this.lookupControlSettings,
            ...this.autocompleteControlSettings,
          };
          break;
        case "combobox":
          return {
            ...this.comboboxControlSettings,
            ...this.autocompleteControlSettings,
          };
        case "datepicker":
          return _.get(this.field, "settings.datepicker", {});
          break;
        case "files":
        case "file":
          return this.fileControlSettings;
          break;
        case "tags":
          return this.tagsControlSettings;
          break;
        default:
          return _.get(this.field, "settings", {});
          break;
      }
    },
    tagsControlSettings() {
      return {
        "duplicate-tag-text": this.tagsFieldDuplicateTagText,
        "remove-on-delete": this.tagsFieldRemoveOnDelete,
        "add-button-text": this.tagsFieldAddButtonText,
        "add-on-change": this.tagsFieldAddOnChange,
      };
    },
    fieldMax() {
      if (this.field.type === "number") {
        return "9".repeat(this.field.maxlength || 12);
      }
    },
    fieldOverride() {
      return _.get(
        this.field,
        "override",
        this.fieldComponent == "autocomplete"
      );
    },
    multiValue() {
      return _.get(this.field, "settings.multiple", false);
    },
    useInputGroup() {
      return this.fieldPrepend || this.field.append || this.field.buttons;
    },
    fieldHeight() {
      return this.field.control == "textarea" && _.isString(this.value)
        ? this.value.split(/\r\n|\r|\n/).length
        : this.field.height || 2;
    },
    hasHelpText() {
      return _.has(this.field, "help") && !_.isEmpty(this.field.help);
    },
    helpTextBody() {
      if (this.hasHelpText && _.size(this.field.help) > 100) {
        return this.field.help;
      }
    },
    helpTextTooltip() {
      if (this.hasHelpText && _.size(this.field.help) < 100) {
        return this.field.help;
      }
    },
    fieldPrepend() {
      if (this.field["control"] == "icon") {
        let iconClass =
          _.isEmpty(_.trim(this.fieldValue)) || this.fieldValue.includes(":")
            ? "question text-muted"
            : this.fieldValue;
        return `<i class="fal fa-${iconClass} fadeIn"></i>`;
      }
      return this.field["prepend"];
    },
    isNumericInput() {
      return this.field["control"] == "input" && this.field["type"] == "number";
    },
  },
  methods: {
    setValue(value = null) {
      this.fieldValue = value;
      if (this.field.control == "textarea" && _.isObject(this.fieldValue)) {
        this.fieldValue = JSON.stringify(value, 0, 4);
      } else if (this.isToggleField && !this.fieldValue) {
        this.fieldValue = this.field.required ? 0 : _.toString(this.fieldValue);
      } else if (this.isMultiField && this.hasNoValue) {
        let fieldOptions = _.get(this.field, "options", []);
        if (this.field.required && _.some(fieldOptions)) {
          let firstOption = _.first(fieldOptions);
          this.fieldValue = _.get(firstOption, "value", firstOption);
        } else {
          this.fieldValue = this.isMultiField ? [] : "";
        }
        // Ref. https://bootstrap-vue.js.org/docs/components/form-select#value-in-multiple-mode
        if (this.multiValue) {
          this.fieldValue = _.castArray(this.fieldValue) || [];
        } else {
          this.fieldValue = this.fieldValue || this.value || "";
        }
      }
    },
    setVisibility(form, callback = null) {
      let visibility = this.isVisible;
      let visible = _.get(this.field, "visible", null);
      if (_.isBoolean(visible) || this.$isNumeric(visible)) {
        this.isVisible = visible ? true : false;
      } else if (_.isString(visible)) {
        this.isVisible = !_.isEmpty(_.get(form, visible));
      } else if (_.isObject(visible)) {
        this.isVisible = false;
        _.forEach(visible, (val, key) => {
          var isVisible = false;
          let fieldList = _.isNumber(key) && _.isString(val);
          var formValue = _.get(form, fieldList ? val : key);
          if (_.isObject(val) && _.has(val, "value")) {
            let fieldName = _.get(val, "field", key);
            var formValue = _.get(form, fieldName);
            let operator = _.get(val, "operator", "==");
            let value = _.get(val, "value");
            isVisible = new Function(
              `return ${formValue} ${operator} ${value}`
            )();
          } else {
            isVisible =
              (_.isArrayLikeObject(val) && val.includes(formValue)) ||
              (_.isBoolean(val) && _.isEmpty(formValue) == !val) ||
              _.toString(formValue) == _.toString(val) ||
              (fieldList && !_.isEmpty(formValue));
          }
          return (this.isVisible = isVisible);
        });
        if (!this.isVisible) {
          if (
            ["autocomplete", "grid"].includes(this.fieldComponent) &&
            this.$refs.$field &&
            this.$refs.$field.input
          ) {
            this.$refs.$field.clear();
          } else {
            this.$emit("input", null);
          }
        }
        if (callback) callback(this.isVisible);
      }
    },
    setCheckboxProps(value) {
      if (
        this.field.control == "checkbox" &&
        this.multiValue &&
        _.isArray(value)
      ) {
        if (_.isEmpty(value)) {
          this.indeterminate = false;
          this.allSelected = false;
        } else if (_.size(value) === _.size(this.field.options)) {
          this.indeterminate = false;
          this.allSelected = true;
        } else {
          this.indeterminate = true;
          this.allSelected = false;
        }
      }
    },
    setCloneValues(newValue, oldValue) {
      let clone = _.get(this.field, "clone", null);
      if (!_.isEmpty(clone)) {
        var values = {};
        let fields = _.castArray(clone);
        _.forEach(fields, (field) => {
          let formValue = _.get(this.form, field);
          if (_.isEmpty(_.trim(formValue)) || formValue === oldValue) {
            values[field] = newValue;
          }
        });
        this.updateFormValues(values, true);
      }
    },
    toggleAll(checked) {
      this.fieldValue = checked ? _.map(this.field.options, "value") : [];
    },
    onBeforeUpload(_, options) {
      options.axiosInstance.interceptors.request.use(function (request) {
        request.headers.common[request.xsrfHeaderName] = getXsrfToken(request);
        return request;
      });
    },
    toggleFileDirectoryAttributes(state = true) {
      if (this.field.control !== "files") return;
      const $uploader = document.querySelector(".media-library-hidden");
      if (state == true) {
        $uploader.setAttribute("webkitdirectory", "");
        $uploader.setAttribute("directory", "");
      } else {
        $uploader.removeAttribute("webkitdirectory");
        $uploader.removeAttribute("directory");
      }
    },
    onFileDirectoryToggle(state) {
      this.toggleFileDirectoryAttributes(state);
    },
    onFieldFocus() {
      this.$emit("focus", this.fieldName);
      this.setCharsLeft();
    },
    onFieldChange(value) {
      this.fieldValue = value;
      this.$emit("input", value);
      this.$emit("change", value, this.fieldName);
    },
    onFieldBlur() {
      this.charsLeft = "";
      this.$emit("blur");
    },
    setCharsLeft() {
      this.charsLeft =
        this.field.control == "input" && this.field.maxlength && this.fieldValue
          ? this.field.maxlength - (this.fieldValue.length || 0)
          : "";
    },
    updateFormValues(values = {}, override = this.fieldOverride) {
      this.$emit("values", values, override);
    },
    getValue() {
      if (this.field.value === 0) {
        return "0";
      }
      return (
        this.value ||
        this.field.value ||
        _.get(this.$route.query, this.fieldName) ||
        _.get(this.$route.query, `_${this.fieldName}`) ||
        ""
      );
    },
    setDebugger(data) {
      if (this.field.debug) {
        console.log(data);
      }
    },
    emit(emit) {
      this.$emit("emit", emit);
    },
    onFail(errors) {
      this.$emit("fail", errors);
    },
  },
};
</script>