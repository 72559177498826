<template>
  <section>
    <!-- Search Field -->
    <grid-search-field
      :key="column"
      v-for="(find, column) in finder"
      :expandable="isExpandable(find)"
      :columns="columnOptions"
      :operators="operators"
      v-model="finder[column]"
      @search="$emit('search')"
      @add-finder="addFinder()"
      @remove-finder="removeFinder"
      @change-finder="changeFinder(column)"
      @searching="(keywords) => $emit('searching', keywords)"
    />
    <!-- Add Finder Button -->
    <b-button
      size="sm"
      class="mt-2"
      @click="addFinder()"
      variant="outline-secondary"
      :disabled="!nextAvailableColumn"
      v-b-tooltip.bottom.hover="__('finder.search_queries.add')"
      v-if="!hasFixedFinders"
    >
      <i class="fal fa-plus" />
    </b-button>
    <!-- Submit Button -->
    <b-button
      size="sm"
      class="mt-2 ml-2"
      type="submit"
      @click="$emit('search')"
      v-b-tooltip.bottom.hover="__('finder.search_queries.execute')"
    >
      <i class="fal fa-search" />
    </b-button>
    <!-- Clear Button -->
    <b-button
      size="sm"
      class="mt-2 ml-2"
      variant="secondary"
      @click="clearFinder"
      v-if="_.size(value) > 1 && !hasFixedFinders"
      v-b-tooltip.bottom.hover="__('finder.search_queries.clear')"
    >
      <i class="fal fa-times-circle" />
    </b-button>
  </section>
</template>

<script>
import GridSearchField from "@/components/grid/GridSearchField";
export default {
  components: {
    GridSearchField,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Array,
      default: () => [],
    },
    operators: {
      type: Array,
      default: () => [],
    },
    view: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      finder: {},
    };
  },
  watch: {
    value: {
      deep: true,
      handler: function (value) {
        this.finder = value;
      },
    },
    finder: {
      deep: true,
      handler: function (finder) {
        this.$emit("input", finder);
      },
    },
  },
  beforeMount() {
    this.finder = this.value;
  },
  computed: {
    activeColumns() {
      return _.map(this.finder, (find) => find["column"]);
    },
    nextAvailableColumn() {
      return _.first(
        _.filter(this.columns, (column) => {
          return !this.activeColumns.includes(column);
        })
      );
    },
    columnOptions() {
      const columnOptions = _.map(this.columns, (column) => ({
        text: _.trim(column),
        value: column,
        disabled: !_.isEmpty(this.finder[column]),
      }));
      let globalOption = {
        text: this.__("everything"),
        value: null,
        disabled: !_.isEmpty(this.finder[null]),
      };
      return [globalOption, ...columnOptions];
    },
    hasFixedFinders() {
      let finders = this.view["finders"];
      if (_.isArrayLikeObject(finders)) {
        return true;
      } else if (_.isObject(finders)) {
        return finders.every(({ fixed }) => fixed);
      }
      return false;
    },
  },
  methods: {
    addFinder(find) {
      this.$emit("add-finder", {
        column: _.get(find, "column", this.nextAvailableColumn),
        operator: _.get(find, "operator", 6),
        keywords: _.get(find, "keywords", null),
        fixed: false,
        focus: true,
      });
    },
    changeFinder(column) {
      let find = this.finder[column];
      this.$emit("change-finder", column, find);
    },
    removeFinder(column) {
      this.$emit("remove-finder", column);
      this.$emit("search");
    },
    clearFinder() {
      this.$emit("clear-finder");
      this.$emit("search");
    },
    getFinderOperatorClass({ disabled, active }) {
      return { disabled, active };
    },
    isExpandable(find) {
      return (
        _.findLast(this.finder) == find && !_.isEmpty(this.nextAvailableColumn)
      );
    },
  },
};
</script>