<template>
  <section class="control--autocomplete">
    <template v-if="!compact">
      <b-input-group
        tabindex="0"
        :size="size"
        :class="inputGroupClass"
        @keydown.up="classic ? onArrowUp() : false"
        @keydown.down="classic ? onArrowDown() : false"
        @keydown.space="classic ? toggleOptions() : false"
        @keydown.enter.prevent="classic ? onEnter() : false"
      >
        <template
          v-slot:prepend
          v-if="_.some(apiFilters) && !disabled && !combobox"
        >
          <b-input-group-text
            v-if="editableFilter"
            v-b-tooltip.hover="filterTooltip"
            class="autocomplete__filter"
            @click="clearFilters"
            :class="filterClass"
            ref="clearFilters"
          >
            <span class="filter--active">
              <i class="fal fa-filter" />
            </span>
            <span class="filter--clear" v-if="editableFilter">
              <i class="fal fa-filter" />
            </span>
          </b-input-group-text>
        </template>
        <template v-if="multiple">
          <b-form-tags
            v-model="active"
            :separator="tagSeparator"
            :tag-validator="tagValidator"
            :add-on-change="tagAddOnChange"
            :no-add-on-enter="tagNoAddOnEnter"
            :remove-on-delete="tagRemoteOnDelete"
            :duplicate-tag-text="tagDuplicateTagText"
          >
            <template v-slot="{ tags, removeTag, inputAttrs, inputHandlers }">
              <b-form-tag
                :key="tag"
                v-for="tag in tags"
                @remove="removeTag(tag)"
              >
                {{ tag }}
              </b-form-tag>
              <input
                v-model="input"
                v-bind="inputAttrs"
                v-on="inputHandlers"
                @blur="onBlur"
                @focus="onFocus"
                @change="onChange"
                @click="toggleOptions"
                @input="searchLocally"
                @keydown.esc="exit"
                @keydown.tab="onBlur"
                @keydown.up="onArrowUp"
                @keydown.down="onArrowDown"
                @keydown.enter.prevent="onEnter"
                :pattern="tagInputRegexPattern"
                :type="tagInputType"
                :name="`${name}_label`"
                :state="state"
                :disabled="disabled"
                :readonly="readonly"
                :required="required"
                :autofocus="autofocus"
                :data-1p-ignore="!field.auth"
                :placeholder="placeholderText"
                :class="inputClass"
                class="autocomplete__input input--multiple"
                :id="`${name}_input`"
                autocomplete="off"
              />
            </template>
          </b-form-tags>
        </template>
        <template v-else>
          <b-form-input
            v-model="input"
            @blur="onBlur"
            @focus="onFocus"
            @change="onChange"
            @click="toggleOptions"
            @input="onInput"
            @keydown.esc="exit"
            @keydown.tab="onBlur"
            @keydown.up="onArrowUp"
            @keydown.down="onArrowDown"
            @keydown.enter.prevent="onEnter"
            :name="`${name}_label`"
            :state="state"
            :disabled="disabled"
            :readonly="readonly"
            :required="required"
            :autofocus="autofocus"
            :placeholder="placeholderText"
            :class="inputClass"
            class="autocomplete__input"
            :id="`${name}_input`"
            autocomplete="off"
          />
          <input type="hidden" :name="name" :value="active['value']" />
        </template>
        <ul
          v-show="open && !readonly && !disabled"
          class="autocomplete__options"
          tabindex="0"
        >
          <li
            :key="index"
            v-for="(option, index) in optionList"
            :class="getOptionClass(option, index)"
            @click="setActiveOption(option)"
            class="autocomplete__option"
          >
            <span
              v-if="!_.isEmpty(getOption(option, 'image'))"
              class="option__image mr-2"
            >
              <i :class="`fal fa-${getOption(option, 'image')}`" />
            </span>
            <span
              :inner-html.prop="getOption(option, 'title') | highlight(input)"
            />
            <small
              class="option__meta"
              v-if="
                !combobox &&
                getOption(option, 'label') !== getOption(option, 'title')
              "
              :inner-html.prop="getOption(option, 'label') | highlight(input)"
            />
          </li>
          <li
            class="autocomplete__feedback feedback--lazyloading"
            v-if="lazyloading && !classic"
          >
            <i class="fal fa-spinner fa-spin mr-2" />
            {{ __("loading") }}
          </li>
          <li
            class="autocomplete__feedback feedback--no-options"
            v-if="noOptions"
            @click="exit"
          >
            {{ __("noOptionsFound") }}
          </li>
        </ul>
        <b-input-group-append class="autocomplete__meta">
          <b-input-group-text class="meta__status">
            <i
              v-if="showClearButton"
              class="status__clear fal fa-times-circle"
              :title="__('erase')"
              @click="
                clear();
                focus();
              "
            />
            <i
              v-b-tooltip.hover="__('loading')"
              class="status__loader fal fa-spinner fa-spin"
              v-if="loading && !classic"
            />
          </b-input-group-text>
          <b-button
            v-if="lookup"
            v-b-tooltip.hover="__('lookup')"
            :disabled="disabled || readonly"
            @click="$refs.$lookup.open()"
            class="meta__lookup-btn"
          >
            <i class="lookup__icon fal fa-binoculars" />
          </b-button>
          <b-input-group-text
            v-if="!combobox"
            class="meta__title"
            v-b-tooltip.hover="tooltipTitle"
            @click="classic ? toggleOptions() : false"
          >
            <span v-if="!_.isEmpty(active['image'])" class="title__icon mr-2">
              <i :class="`fal fa-${active['image']}`" />
            </span>
            <span>{{ active["title"] }}</span>
          </b-input-group-text>
          <b-button
            tabindex="-1"
            v-if="useDropdown && hasItems"
            :disabled="disabled || readonly || loading"
            @click="toggleOptions"
            @keydown.esc="exit"
          >
            <i class="fal fa-sort" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </template>
    <template v-else>
      <b-button
        block
        v-if="lookup"
        variant="outline-secondary"
        :disabled="disabled || readonly"
        v-b-tooltip.hover="__('lookup')"
        @click="$refs.$lookup.open()"
      >
        <i class="fal fa-binoculars" />
      </b-button>
    </template>
    <app-lookup
      v-model="lookupValue"
      v-bind="getLookupProps()"
      @input="onLookupInput"
      :field="field"
      ref="$lookup"
    />
  </section>
</template>

<script>
// TODO: add validator based on optional regex provider: did not work as expected
// TODO: add support for multiple lookup values (using checkboxes?)
// TODO: dynamically bind props and listeners (v-bind, v-on) to stay DRY (https://stackoverflow.com/questions/48250832/programmatically-bind-custom-events-for-dynamic-components-in-vuejs/48251122)
// TODO: tags: solve bug that sets wrong index after setting active option (index difference of option and index: 1 & 0)
const FormField = () => import("@/components/form/FormField");
import AppLookup from "@/components/app/AppLookup";
export default {
  components: {
    AppLookup,
    FormField,
  },
  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
    source: {
      type: String,
      default: null,
    },
    resource: {
      type: Object | String,
      default: () => ({}),
    },
    value: {
      type: Object | String,
      default: () => ({}),
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    labels: {
      type: Object,
      default: () => ({}),
    },
    limit: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "md",
    },
    remote: {
      type: Boolean,
      default: true,
    },
    lookup: {
      type: Object | Boolean | String,
      default: true,
    },
    preload: {
      type: Boolean | String | Array | Object,
      default: null,
    },
    prefill: {
      type: Boolean | String | Array | Object,
      default: null,
    },
    editable: {
      type: Boolean | String,
      default: "",
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    classic: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    combobox: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Array | Object,
      default: null,
    },
    select: {
      type: Array | Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    multiple: {
      type: Boolean | String,
      default: false,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    output: {
      type: Object | String,
      default: () => ({}),
    },
    sync: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      page: 1,
      meta: {},
      open: false,
      index: -1,
      items: [],
      delay: 500,
      input: null,
      timer: null,
      errors: {},
      active: {},
      options: [],
      loading: false,
      lazyload: true,
      inputEl: ".autocomplete__input",
      optionsEl: ".autocomplete__options",
      preloader: {},
      apiMethod: "get",
      apiFilters: {},
      lazyOffset: 200,
      apiTimeout: 20000,
      lookupValue: null,
      usePreload: false,
      resetIndex: false,
      useDropdown: false,
      lazyloading: false,
      defaultLimit: 25,
      preloadLimit: 200,
      apiOperators: {},
      editableFilter: true,
      clearingFilters: false,
      useIndexAsValue: false,
      tagAddOnChange: true,
      tagNoAddOnEnter: false,
      tagRemoteOnDelete: true,
      tagSeparator: [" ", ",", ";", "|", "/", "\\", "+", "&"],
      tagDuplicateTagText: this.__("alreadyExists"),
    };
  },
  watch: {
    value(value) {
      this.setInput(_.isNumber(value) ? _.toString(value) : value);
    },
    form: {
      deep: true,
      handler: function () {
        this.handleFilters();
        this.handlePreloader();
      },
    },
    index() {
      this.setActiveOptionElement();
      this.scrollToActiveOption();
    },
    input(input) {
      if (this.hasEditableField) this.handleEditableField(input);
    },
    open(open) {
      if (this.resetIndex) {
        this.index = open && (!this.hasActiveOption || this.multiple) ? 0 : -1;
      }
      if (this.remote && !this.usePreload) {
        this.scrollToOptionsTop();
      }
    },
    active(activeNew, activeOld) {
      if (!_.isEqual(activeOld, activeNew)) {
        this.$emit("change", activeNew);
        this.lookupValue = activeNew;
        if (this.multiple) {
          this.$emit("input", activeNew);
          this.input = "";
        }
      }
    },
  },
  beforeMount() {
    this.setActiveProperty();
  },
  mounted() {
    this.setEventListeners();
    this.initializePreload();
  },
  destroyed() {
    this.unsetEventListeners();
  },
  computed: {
    // To be removed
    // requestParams() {
    //   return {
    //     ...this.$route.query,
    //     ...this.form,
    //   };
    // },
    apiKeywordsParam() {
      return _.trim(this.input);
    },
    apiValueParam() {
      return this.multiple ? null : this.fieldValue;
    },
    useLazyload() {
      return (
        this.remote &&
        this.lazyload &&
        !this.usePreload &&
        !this.lazyloading &&
        _.size(this.options) >= this.limit
      );
    },
    optionList() {
      if (!this.multiple) return this.options;
      return this.options.filter((option) => {
        return !_.includes(this.active, this.getOptionInput(option));
      });
    },
    filterFieldLabelValueSet() {
      if (_.some(this.apiFilters)) {
        return _.map(this.filterFieldNames, (field) => {
          var label = _.get(this.labels, field, field);
          var label = this.$removeMarkup(label);
          var value = _.get(this.form, field, "");
          var value = _.get(value, "value", value);
          return `${label} = ${value}`;
        });
      }
    },
    filterFieldNames() {
      return _.map(this.filters, (value, index) => {
        return _.isString(index) ? value : _.get(value, "field", value);
      });
    },
    useAutoSearch() {
      return (
        !this.multiple &&
        this.hasInput &&
        (!this.$isId(this.input) || !this.loading)
      );
    },
    inputGroupClass() {
      return {
        "autocomplete--readonly": this.readonly,
        "autocomplete--disabled": this.disabled,
        "autocomplete--multiple": this.multiple,
        "autocomplete--no-title": this.combobox,
        "autocomplete--no-input": this.classic,
      };
    },
    filterClass() {
      return {
        "filter--clearing": this.clearingFilters,
        "filter--editable": this.editableFilter,
      };
    },
    inputClass() {
      return {
        "input--selected": this.active["label"],
      };
    },
    filterTooltip() {
      let labels = this.filterFieldLabelValueSet;
      return !_.isEmpty(labels) ? labels.toString() : "";
    },
    remoteDelay() {
      return this.focused ? this.delay : 0;
    },
    apiLimitParam() {
      if (this.limit) return this.limit;
      return this.preload && !this.remote
        ? this.preloadLimit
        : this.defaultLimit;
    },
    noOptions() {
      return !this.loading && _.isEmpty(this.options) && _.isEmpty(this.value);
    },
    tooltipTitle() {
      if (_.size(this.active["title"]) > 25 && !this.classic) {
        return this.active["title"];
      }
    },
    fieldLabel() {
      return _.get(this.field, "label");
    },
    hasFilters() {
      return !_.isEmpty(this.filters);
    },
    hasItems() {
      return !_.isEmpty(this.items);
    },
    hasOptions() {
      return !_.isEmpty(this.options);
    },
    hasEditableField() {
      return _.isString(this.editable) && !_.isEmpty(_.trim(this.editable));
    },
    hasInput() {
      return _.trim(this.input) !== "";
    },
    hasActiveOption() {
      return !_.isEmpty(this.active);
    },
    fieldValue() {
      return _.get(this.field, "value", "");
    },
    hasOptionList() {
      return !_.isEmpty(this.optionList);
    },
    showClearButton() {
      return (
        !this.loading &&
        !this.disabled &&
        !this.readonly &&
        !this.classic &&
        !_.isEmpty(this.active)
      );
    },
    inputOptionKey() {
      if (!this.combobox) return "label";
      return this.multiple ? "value" : "title";
    },
    tagInputType() {
      return _.isString(this.multiple) ? this.multiple : null;
    },
    tagInputRegexPattern() {
      if (this.tagInputType === "email") {
        return "/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/";
      }
    },
    placeholderText() {
      if (this.clearingFilters) {
        return this.__("clearFilter");
      } else if (this.loading) {
        return this.__("loading");
      } else if (this.placeholder) {
        return this.placeholder;
      }
      return this.customPlaceholderText;
    },
    customPlaceholderText() {
      var subitem = "default";
      if (this.disabled) {
        var subitem = "disabled";
      } else if (this.readonly) {
        var subitem = "select";
      } else if (this.editable) {
        var subitem = "optional";
      }
      return this.getPlaceholder(subitem);
    },
  },
  methods: {
    search() {
      this.page = 1;
      this.index = -1;
      this.setActiveProperty();
      // if (this.remote && !this.useFilters()) {
      if (this.remote) {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.searchRemotely, this.remoteDelay);
      } else if (this.hasItems) {
        this.searchLocally();
      }
    },
    listRemotely(callback) {
      this.searchRemotely((items) => {
        this.setItems(items);
        this.searchLocally(null, true);
        if (_.isFunction(callback)) callback(items);
      }, this.getApiRequestWithoutKeywords());
    },
    searchRemotely(callback, customRequest = {}) {
      if (!this.loading) {
        this.items = [];
        this.loading = true;
        this.setActiveProperty();
        this.$http({ ...this.getApiRequest(), ...customRequest })
          .then(({ data }) => {
            var data = _.get(data, "data", data);
            _.isFunction(callback) ? callback(data) : this.handleMatches(data);
          })
          .catch(({ data }) => {
            var data = _.get(data, "data", data);
            this.handleErrors(data);
          })
          .finally(() => (this.loading = false));
      }
    },
    searchLocally(callback, strict = false) {
      if (!this.hasInput && _.isFunction(callback)) callback([]);
      let matches = this.items.filter((item) => {
        var match = false;
        _.forEach(this.getOption(item), (value, name) => {
          if (this.$isId(this.input) || strict) {
            // use exact comparison in case of numeric input
            if (value == this.input) {
              match = true;
            }
          } else if (_.isString(this.input)) {
            // use loose comparison in case of textual input
            if (_.includes(_.lowerCase(value), _.lowerCase(this.input))) {
              match = true;
            }
          }
        });
        if (match) return item;
      });
      _.isFunction(callback) ? callback(matches) : this.handleMatches(matches);
    },
    handleMatches(matches = this.options, callback) {
      this.options = matches;
      if (this.focused || this.editable) {
        this.open = _.size(matches) > 0;
      } else if (_.size(matches) === 1 && this.hasInput) {
        this.setFirstOption();
      } else if (_.isEmpty(this.active) && !this.editable) {
        this.clear();
      }
      if (_.isFunction(callback)) callback(this.hasActiveOption);
    },
    handleErrors(data) {
      var errors = _.get(data, "errors", {});
      this.errors = _.mapKeys(errors, (v, key) => key.replace("filter.", ""));
      this.$emit("fail", this.errors);
    },
    lazyloadData() {
      if (this.useLazyload) {
        this.page += 1;
        this.lazyloading = true;
        this.searchRemotely((items) => {
          this.options = _.union(this.options, items);
          this.lazyloading = false;
        });
      }
    },
    setActiveOption(option) {
      let active = this.getActiveOption(option);
      let input = this.getOptionInput(option);
      if (!_.isEqual(this.active, active)) {
        this.updateFormValues(option);
        this.$emit("input", active);
        this.active = active;
        this.input = input;
      }
      this.multiple ? this.close() : this.exit();
    },
    setInput(input) {
      if (_.isEmpty(input)) return;
      if (this.multiple && _.isArray(input)) {
        this.active = input;
        this.input = "";
      } else if (_.isString(input)) {
        this.input = input;
        this.search();
      }
    },
    setItems(items) {
      if (!_.isArray(items)) this.items = [];
      this.items = _.uniqBy(items, (item) => this.getOption(item, "value"));
    },
    getFilter(val, key) {
      let column = _.isString(key) ? key : _.get(val, "column", val);
      let field = _.isString(key) ? val : _.get(val, "field", val);
      var value = this.$isId(val) ? val : _.get(this.form, field);
      var value = _.isEmpty(value) ? null : _.toString(_.get(value, "value", value));
      let preload = _.get(val, "preload") && value;
      let editable = _.get(val, "editable", false);
      let operator = _.get(val, "operator");
      let updated = !_.isEqual(this.apiFilters[column], value);
      return { value, editable, preload, updated, column, operator };
    },
    handleFilters() {
      _.forEach(this.filters, (val, key) => {
        var reload = this.remote;
        let filter = this.getFilter(val, key);
        let column = filter["column"];
        this.editableFilter = filter["editable"];
        this.apiFilters[column] = null;
        if (!_.isEmpty(filter["value"]) && !_.isObject(filter["value"])) {
          this.apiFilters[column] = filter["value"];
          if (!_.isEmpty(filter["operator"])) {
            this.apiOperators[column] = filter["operator"];
          }
          reload = filter["updated"] && filter["preload"];
        } else if (this.hasActiveOption) {
          this.loading ? this.searchLocally() : this.clear();
        } else if (this.hasOptions) {
          this.items = [];
        }
        if (reload) {
          this.listRemotely(() => {
            this.useDropdown = true;
            this.usePreload = true;
          });
        }
      });
    },
    handlePreloader(preloader = this.getPreloader()) {
      if (!preloader) return;
      this.preloader = preloader;
      this.listRemotely();
    },
    getPreloader() {
      if (!_.isString(this.preload)) return;
      let preloadValue = _.get(this.form, this.preload, {});
      let newPreloader = this.getOption(preloadValue, "value");
      let oldPreloader = this.getOption(this.preloader, "value");
      if (!_.isEmpty(newPreloader) && !_.isEqual(newPreloader, oldPreloader)) {
        return newPreloader;
      }
    },
    initializePreload() {
      if (this.preload) {
        this.handleFilters();
        this.usePreload = true;
        this.useDropdown = true;
        if (this.preload === true) {
          this.searchRemotely((items) => {
            this.setItems(items);
            this.usePrefill() ? this.prefillOption() : this.searchLocally();
          });
        } else {
          this.handlePreloader();
        }
      }
    },
    clear() {
      this.input = this.multiple ? [] : "";
      this.open = this.focused = false;
      this.setActiveProperty();
      this.$emit("input", this.input);
    },
    clearFilters() {
      if (this.editableFilter) {
        this.useDropdown = false;
        this.usePreload = false;
        this.apiOperators = {};
        this.apiFilters = {};
        this.items = [];
      }
    },
    handleEditableField(value = this.input) {
      this.$emit("values", { [this.editable]: value });
    },
    getApiRequest() {
      return {
        url: this.source,
        method: this.apiMethod,
        params: this.getApiParams(),
        timeout: this.apiTimeout,
      };
    },
    getApiParams() {
      return {
        view: true,
        search: true,
        value: this.apiValueParam,
        keywords: this.apiKeywordsParam,
        "page[size]": this.apiLimitParam,
        "page[number]": this.page,
        ...this.getApiOperatorParams(),
        ...this.getApiContextParams(),
        ...this.getApiSelectParams(),
        ...this.getApiFilterParams(),
        ...this.getApiOutputParams(),
        ...this.params,
      };
    },
    getApiRequestWithoutKeywords() {
      return { params: _.omit(this.getApiParams(), "keywords") };
    },
    getLookupProps() {
      let lookupProps = _.isObject(this.lookup) ? this.lookup : {};
      return {
        request: this.getApiRequest(),
        filters: this.apiFilters,
        title: this.fieldLabel,
        form: this.form,
        ...lookupProps,
      };
    },
    getActiveOption(option) {
      return this.multiple ? this.getTags(option) : this.getOption(option);
    },
    getOption(item, key = null, fallback = "") {
      if (!_.isObject(item)) return item || fallback;
      let option = {
        label: _.get(item, "__data.label", item["label"] || null),
        title: _.get(item, "__data.title", item["title"] || null),
        value: _.get(item, "__data.value", item["value"] || null),
        image: _.get(item, "__data.image", item["image"] || null),
      };
      return key ? option[key] || fallback : option;
    },
    getOptionInput(input) {
      return this.getOption(input, this.inputOptionKey);
    },
    prefillOption(items = this.items) {
      if (this.prefill === true) {
        this.setFirstOption(items);
      } else if (_.isObject(this.prefill)) {
        _.forEach(this.prefill, (value, column) => {
          let needle = { [column]: value };
          let result = _.forEach(items, (item) => {
            let match = _.get(item, column) === value;
            if (match) this.setActiveOption(item);
          });
        });
      }
    },
    getTags(item) {
      return _.union(
        _.castArray(this.active),
        _.castArray(this.getOptionInput(item))
      );
    },
    updateFormValues(item) {
      if (_.has(item, "__data") && this.sync) {
        let fallback = _.omit(item, "__data");
        let values = _.get(item, "__values", fallback);
        this.$emit("values", values);
      }
    },
    itemExists(haystack = this.items) {
      let needle = this.active || { value: this.input };
      var items = _.map(haystack, (item) => {
        return _.get(item, "__data", item);
      });
      return _.find(items, needle);
    },
    getApiSelectParams() {
      return _.isObject(this.select)
        ? this.$objectToQueryParams(this.select, "select", false)
        : { select: this.select };
    },
    getApiOperatorParams() {
      return this.$objectToQueryParams(this.apiOperators, "operator");
    },
    getApiFilterParams() {
      return this.$objectToQueryParams(this.apiFilters, "filter");
    },
    getApiOutputParams() {
      return this.$objectToQueryParams(this.output, "output");
    },
    getApiContextParams() {
      return this.$objectToQueryParams(this.$context(), "context");
    },
    toggleOptions() {
      if (
        (!this.remote || this.usePreload) &&
        !this.disabled &&
        !this.readonly &&
        this.hasItems
      ) {
        this.focus();
        this.open = !this.open;
        this.options = this.items;
      }
    },
    handleScroll() {
      let optionsEl = this.$el.querySelector(this.optionsEl);
      if (
        optionsEl.scrollTop + optionsEl.offsetHeight >
        optionsEl.scrollHeight - this.lazyOffset
      ) {
        this.lazyloadData();
      }
    },
    setActiveOptionElement() {
      if (!this.compact) {
        let $optionsEl = this.$el.querySelector(this.optionsEl);
        this.$activeOption = $optionsEl.children[this.index];
      }
    },
    getOptionClass(item, index) {
      return {
        "option--active":
          _.isEqual(this.getOption(item), this.active) ||
          _.isEqual(index, this.index),
      };
    },
    useFilters() {
      return _.some(this.apiFilters);
    },
    setFirstOption(options = this.options) {
      this.setActiveOption(_.first(options));
    },
    scrollToOptionsTop() {
      this.$el.querySelector(this.optionsEl).scrollTop = 0;
    },
    usePrefill() {
      return (
        this.prefill &&
        !this.hasInput &&
        (_.size(this.items) === 1 || !_.isBoolean(this.prefill))
      );
    },
    tagValidator() {
      return true;
      if (!this.tagInputRegexPattern) return true;
      return this.tagInputRegexPattern.test(this.input);
    },
    exit() {
      this.page = 1;
      this.open = false;
      // this.index = -1;
      this.focused = false;
      this.clearingFilters = false;
      this.removeFocus();
    },
    close() {
      return (this.open = false);
    },
    onInput() {
      if (this.hasInput) this.search();
    },
    onLookupInput(value) {
      this.setActiveOption(value);
    },
    onArrowDown() {
      if (this.open) {
        if (this.index < _.size(this.optionList) - 1) {
          this.index = this.index + 1;
        } else if (!this.remote) {
          this.index = 0;
        }
      } else {
        this.toggleOptions();
      }
    },
    onArrowUp() {
      if (this.index > 0) this.index = this.index - 1;
    },
    onEnter() {
      if (this.index > -1) this.setActiveOption(this.optionList[this.index]);
    },
    onFocus() {
      this.focused = true;
      this.$emit("focus");
    },
    onChange() {
      if (_.isEmpty(this.input)) {
        this.clear();
      }
    },
    onBlur(e) {
      this.$emit("blur");
      this.focused = false;
      if (!this.useAutoSearch || this.hasActiveOption) {
        return;
      } else if (!e.relatedTarget || !this.$el.contains(e.relatedTarget)) {
        this.handleMatches();
      }
    },
    focus() {
      let el = this.$el.querySelector(this.inputEl);
      if (el) el.focus();
    },
    removeFocus() {
      let el = this.$el.querySelector(this.inputEl);
      if (el) el.blur();
    },
    scrollToActiveOption() {
      if (this.$activeOption) {
        this.$activeOption.scrollIntoView({
          block: "nearest",
        });
      }
    },
    handleClickOutside(e) {
      if (!this.$el.contains(e.target)) this.exit();
    },
    setActiveProperty() {
      this.active = this.multiple ? [] : {};
    },
    getPlaceholder(key) {
      return this.__(`autocomplete.placeholders.${key}`);
    },
    setEventListeners() {
      if (!this.compact) {
        this.$el
          .querySelector(this.optionsEl)
          .addEventListener("scroll", this.handleScroll);
        document.addEventListener("click", this.handleClickOutside);
      }
    },
    unsetEventListeners() {
      if (!this.compact) {
        this.$el
          .querySelector(this.optionsEl)
          .removeEventListener("scroll", this.handleScroll);
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
  },
};
</script>