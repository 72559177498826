<template>
  <resource-view base="provim" group="user" name="logout-devices" v-slot="view">
    <!-- Logout Devices modal-->
    <app-modal
      size="xs"
      v-bind="view"
      :confirm="status == 'done' ? __('Afronden | Finish') : null"
      @confirm="status == 'done' ? $emit('close') : logoutDevices()"
      @close="$emit('close')"
    >
      <!-- Password Confirmation form -->
      <app-form
        v-if="status == 'init'"
        v-model="values"
        :invalid="errors"
        :fieldset="fields"
      />
      <!-- Success Feedback text -->
      <b-alert
        class="mb-0"
        align="center"
        :show="status == 'done'"
        :variant="status == 'done' ? 'success' : 'danger'"
      >
        {{
          __(
            status == "done"
              ? "U bent succesvol uitgelogd op alle andere apparaten | You have successfully logged out on other devices."
              : "Er is helaas iets fout gegaan, probeer het later opnieuw. | Something has gone wrong, please try again later."
          )
        }}
      </b-alert>
    </app-modal>
  </resource-view>
</template>

<script>
import AppModal from "@/components/app/AppModal";
import AppForm from "@/components/app/AppForm";
export default {
  components: {
    AppModal,
    AppForm,
  },
  data() {
    return {
      status: null,
      fields: {},
      values: {},
      errors: {},
    };
  },
  beforeMount() {
    this.setFields();
  },
  methods: {
    setFields() {
      this.$http
        .get("provim/forms/user/logout-devices")
        .then(({ data: { data } }) => {
          this.status = "init";
          this.fields = data;
        });
    },
    logoutDevices() {
      this.$http
        .post("provim/user/logout-devices", {
          password: this.values["password"],
        })
        .then(() => (this.status = "done"))
        .catch(({ response: { data } }) => {
          this.errors = data.errors || {};
          this.status = "error";
        });
    },
  },
};
</script>