<template>
  <resource-view name="print" v-slot="view">
    <app-modal
      size="xl"
      v-bind="view"
      :front="true"
      :buttons="false"
      :padding="false"
      @close="$emit('close')"
      @open="$emit('loading', true)"
    >
      <article :class="{ loading }" style="min-height: 80vh">
        <iframe
          class="position-absolute w-100 h-100 border-0"
          :src="source"
          @load="onFrameLoaded"
          ref="$iframe"
          id="iframe"
        ></iframe>
      </article>
      <template slot="note">
        <small class="w-100 text-center text-muted">
          <i class="fal fa-info-circle mr-2" />
          {{ __("pdfBrowserWarning") }}
        </small>
      </template>
    </app-modal>
  </resource-view>
</template>

<script>
import AppModal from "@/components/app/AppModal";
export default {
  components: {
    AppModal,
  },
  props: {
    source: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      loaded: false,
    };
  },
  methods: {
    onFrameLoaded() {
      this.$emit("loading", false);
      this.$emit("loaded", true);
      this.autoClose();
    },
    autoClose() {
      try {
        let docUrl = _.get(this.$refs, "$iframe.contentWindow.document.URL");
        if (docUrl == "about:blank") this.$emit("close");
      } catch (e) {}
    },
  },
};
</script>