<template>
  <section id="data" v-bind="$attrs">
    <!-- Data Frame -->
    <div v-if="!_.isEmpty(data)" class="data__frame rounded">
      <!-- Grid Data -->
      <grid-data
        v-if="layout == 'grid'"
        :data="data"
        :meta="meta"
        :loading="loading"
        :header-height="true"
        :size="$attrs.size || 'md'"
        :editable="$attrs.editable || false"
        :row-selection="$attrs.selection || 'single'"
        :row-multi-select-with-click="$attrs.selection == 'multiple'"
        @input="(data) => $emit('input', data)"
        @selection="(data) => $emit('select', data)"
      />
      <!-- Data Table -->
      <b-table
        v-else-if="layout == 'table'"
        @row-selected="(data) => $emit('select', data)"
        class="datatable mb-0"
        select-mode="multi"
        :selectable="true"
        responsive="sm"
        :fields="columns"
        :items="data"
        :small="true"
        ref="grid"
      >
        <!-- Select all rows -->
        <template #head(selected)="{ selectAllRows, clearSelected }">
          <input
            type="checkbox"
            @change="
              ({ target: { checked } }) =>
                checked ? selectAllRows() : clearSelected()
            "
          />
        </template>
        <!-- Select single row -->
        <template #cell(selected)="{ rowSelected, selectRow, unselectRow }">
          <input
            :checked="rowSelected"
            type="checkbox"
            @change="
              ({ target: { checked } }) =>
                checked ? selectRow() : unselectRow()
            "
          />
        </template>
      </b-table>
    </div>
    <!-- Data Feedback -->
    <b-alert
      v-else-if="loading"
      show
      variant="default"
      class="mb-0 d-flex h-100 justify-content-center align-items-center"
    >
      <i class="fal fa-spinner fa-spin mr-2"></i>
      {{ __("Bezig met laden... | Loading...") }}
    </b-alert>
    <!-- Data Feedback -->
    <b-alert
      v-else
      show
      variant="warning"
      class="mb-0 d-flex h-100 justify-content-center align-items-center"
    >
      <i class="fal fa-frown mr-2"></i>
      {{ __("Geen data beschikbaar. | No data available") }}
    </b-alert>
  </section>
</template>

<script>
import GridData from "@/components/grid/GridData";
export default {
  components: {
    GridData,
  },
  props: {
    layout: {
      type: String,
      default: "grid",
      validator: function (value) {
        return ["grid", "table"].indexOf(value.toLowerCase()) !== -1;
      },
    },
    data: {
      type: Array,
      default: () => [],
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    columns() {
      var columns = _.filter(
        _.keys(_.first(this.data)),
        (column) => column.indexOf("_") !== 0
      );
      columns.unshift("selected");
      return columns;
    },
  },
};
</script>

<style scoped>
#data {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: scroll;
}
</style>