import Vue from "vue";
import Vuex from "vuex";

import * as config from "@/store/modules/Config";
import * as auth from "@/store/modules/Auth";
import * as form from "@/store/modules/Form";

Vue.use(Vuex);

export default new Vuex.Store({
	strict: true,
	modules: {
		config,
		auth,
		form,
	},
});
