<template>
  <main class="app__page page--standalone" style="background: #000">
    <!-- Container -->
    <div style="position: relative; z-index: 1">
      <header class="mb-4">
        <!-- Title -->
        <h1 class="page__title">
          <i :class="`fal fa-${module.icon || app.icon}`" />
          {{ module.title || app.title }}
        </h1>
      </header>
      <main>
        <keep-alive max="5">
          <resource-view
            v-slot="view"
            base="provim"
            group="auth"
            :params="{ lang: $lang() }"
            :key="$route.path"
          >
            <!-- Card -->
            <auth-card
              class="fadeIn"
              :key="$route.path"
              v-bind="$route.query.uuid ? {} : view"
            >
              <!-- Content -->
              <router-view :app="app" :view="view" :module="module" />
            </auth-card>
          </resource-view>
        </keep-alive>
      </main>
      <!-- Footer -->
      <footer class="text-center py-4 text-white">
        <!-- Reload button -->
        <span
          @click="reloadApp"
          style="cursor: pointer; text-decoration: underline"
          v-html="
            __(
              'Problemen met opstarten? Klik hier om te herladen. | Trouble launching? Click here to reload.'
            )
          "
        />
        <!-- Copyright -->
        <small class="d-block mt-2" style="opacity: 0.7">
          &copy;{{ new Date().getFullYear() }} {{ app.author }} &nbsp;
          {{ __("Alle rechten voorbehouden | All rights reserved") }}<br />
          {{ app.title }} &middot; {{ __("Versie | Version") }}
          {{ app.version }}
        </small>
      </footer>
    </div>
    <!-- Closed state -->
    <input
      type="hidden"
      id="closed"
      name="closed"
      :value="closed ? 'true' : 'false'"
    />
    <!-- Background -->
    <figure class="page__bg"></figure>
  </main>
</template>

<script>
import AuthCard from "@/components/auth/AuthCard";
import AuthService from "@/services/AuthService";
export default {
  components: { AuthCard },
  data() {
    return {
      app: {},
      module: {},
      closed: false,
    };
  },
  beforeMount() {
    this.setApp();
    this.setModule();
    this.setTitle();
  },
  methods: {
    setApp() {
      this.$http
        .get("system/application", { params: { lang: this.$lang() } })
        .then(({ data: { data } }) => (this.app = data))
        .finally(() => this.setTitle());
    },
    setModule() {
      if (!this.$isRoot()) {
        this.$http
          .get("system/module", { params: { lang: this.$lang() } })
          .then(({ data: { data } }) => (this.module = data || {}))
          .finally(() => this.setTitle());
      }
    },
    setTitle() {
      var title = this.app.author;
      if (!_.isEmpty(this.module)) {
        title = `${this.module.title} - ${this.app.title}`;
      } else if (!_.isEmpty(this.app)) {
        title = `${this.app.title} - ${this.app.author}`;
      }
      document.title = title;
    },
    async reloadApp() {
      await AuthService.logout();
      this.closed = true;
      window.location.reload();
    },
  },
};
</script>