<template>
  <section class="content__container">
    <slot name="header" />
    <b-card-group deck class="cards mt-4">
      <b-card
        no-body
        :key="key"
        class="card card--clean"
        v-for="(row, key) in data"
        @click="$emit('select', row)"
        style="cursor: pointer"
      >
        <template v-slot:header>
          <header class="d-flex justify-center">
            <h6 class="m-0 font-weight-bold">
              {{ _.get(row, "__data.title") }}
            </h6>
            <small class="ml-auto pl-2">
              #{{ _.get(row, "__data.value") }}
            </small>
          </header>
        </template>
        <article class="datatable datatable--vertical">
          <table class="border-0">
            <tbody>
              <template v-for="(column, index) in columns">
                <tr :key="index">
                  <td>
                    <strong>{{ _.trim(column) }}</strong>
                  </td>
                  <td>
                    <span>{{ _.trim(row[column]) }}</span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </article>
      </b-card>
    </b-card-group>
    <slot name="footer" />
  </section>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    data: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  computed: {
    columns() {
      let columns = _.keys(_.first(this.data));
      return _.filter(columns, (column) => {
        return column.indexOf("_") !== 0;
      });
    },
  },
};
</script>