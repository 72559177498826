<template>
  <resource-view :parent="false" :base="resource" v-slot="view">
    <index-data
      :header="false"
      :request="request"
      :key="componentKey"
      @action="onDataAction"
      :view="{ ...view, ...navigator.view, layout: 'grid' }"
    />
  </resource-view>
</template>

<script>
const IndexData = () => import("@/components/index/IndexData");
export default {
  components: {
    IndexData,
  },
  props: {
    meta: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    grid: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    navigator: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  watch: {
    "grid.id": function () {
      // we use this fix to force re-rendering the component and its data,
      // as adviced here: https://michaelnthiessen.com/force-re-render/
      this.componentKey++;
    },
  },
  data() {
    return {
      model: {},
      componentKey: 0,
    };
  },
  computed: {
    // TODO DELETE ME
    // view() {
    //   const { label, icon } = this.navigator;
    //   return {
    //     ...this.navigator["view"],
    //     layout: "grid",
    //     title: label,
    //     icon: icon,
    //   };
    // },
    request() {
      return {
        url: this.resource,
        params: this.queryParams,
      };
    },
    resource() {
      return _.trim(
        _.get(this.navigator, "action.params.resource") ||
          _.get(this.navigator, "action.path") ||
          this.navigator["action"],
        "/"
      );
    },
    actionFilter() {
      let fallback = _.get(this.meta, "model.foreign_key");
      let column = this.navigator["foreign_key"] || fallback;
      return { [column]: this.grid["id"] };
    },
    actionFilterParams() {
      return this.$objectToQueryParams(this.actionFilter, "filter");
    },
    actionQueryParams() {
      return _.get(this.navigator, "action.query", {});
    },
    queryParams() {
      return {
        ...this.actionFilterParams,
        ...this.actionQueryParams,
      };
    },
  },
  methods: {
    onDataAction({ name, value }) {
      let query = {};
      if (name == "create") {
        query = this.actionFilter;
      } else if (name == "show" || name == "edit") {
        query = this.queryParams;
      }
      let params = { resource: this.resource };
      if (name != "create") params["identity"] = value;
      this.$router.push({ name, params, query });
    },
  },
};
</script>