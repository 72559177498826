// Layouts
import BaseLayout from "@/layouts/BaseLayout";
import AuthLayout from "@/layouts/AuthLayout";
import UserLayout from "@/layouts/UserLayout";
import ItemLayout from "@/layouts/ItemLayout";
import PageLayout from "@/layouts/PageLayout";

// Views > Pages
import PageNotFound from "@/views/page/PageNotFound";
import PageDashboard from "@/views/page/PageDashboard";

// Views > Auth
import AuthReset from "@/views/auth/AuthReset";
import AuthLogin from "@/views/auth/AuthLogin";
import AuthModule from "@/views/auth/AuthModule";
import AuthLogout from "@/views/auth/AuthLogout";
import AuthForgot from "@/views/auth/AuthForgot";
import AuthVerify from "@/views/auth/AuthVerify";
import AuthTwoFactor from "@/views/auth/AuthTwoFactor";

// Views > User
import UserAccount from "@/views/user/UserAccount";
import UserMessages from "@/views/user/UserMessages";
import UserSecurity from "@/views/user/UserSecurity";
import UserPreferences from "@/views/user/UserPreferences";

// Views > Modules
import ActivityMultiYearPlanning from "@/modules/ActivityMultiYearPlanning";

// Views > Item
import ItemIndex from "@/views/item/ItemIndex";
import ItemShow from "@/views/item/ItemShow";
import ItemEdit from "@/views/item/ItemEdit";
import ItemCreate from "@/views/item/ItemCreate";
import ItemDelete from "@/views/item/ItemDelete";
import ItemMenu from "@/views/item/ItemMenu";
import ItemFile from "@/views/item/ItemFile";
import ItemNotes from "@/views/item/ItemNotes";

// check if user is trying to access root
const rootCheck = async function (to, from, next) {
	var host = window.location.hostname.split('.');
	if (host.length == 2 || host[0] === 'www') {
		next({ name: 'module' });
	} else {
		next();
	}
}

export default [
	{
		path: '/auth',
		component: AuthLayout,
		children: [
			{
				path: '/',
				redirect: "/auth/login",
			},
			{
				name: 'login',
				path: 'login',
				component: AuthLogin,
			},
			{
				name: 'two_factor',
				path: 'two_factor',
				component: AuthTwoFactor,
			},
			{
				name: 'module',
				path: 'module',
				component: AuthModule,
			},
			{
				path: 'logout',
				name: 'logout',
				meta: { auth: true },
				component: AuthLogout,
			},
			{
				name: 'forgot',
				path: 'forgot',
				component: AuthForgot,
			},
			{
				name: 'reset',
				path: 'reset',
				component: AuthReset,
			},
			{
				name: 'verify',
				path: 'verify/:id/:hash',
				component: AuthVerify,
			},
		]
	},
	{
		path: '/',
		component: BaseLayout,
		meta: { auth: true },
		beforeEnter: rootCheck,
		children: [
			{
				path: '/',
				name: 'dashboard',
				component: PageDashboard,
			},
			{
				path: '/user',
				component: UserLayout,
				redirect: "/user/account",
				children: [
					{
						path: 'messages',
						name: 'messages',
						component: UserMessages,
					},
					{
						path: 'account',
						name: 'account',
						component: UserAccount,
					},
					{
						path: 'security',
						name: 'security',
						component: UserSecurity,
					},
					{
						path: 'preferences',
						name: 'preferences',
						component: UserPreferences,
					},
				]
			},
			{
				path: '/modules',
				component: PageLayout,
				children: [
					{
						path: '/modules/multi-year-planning',
						component: ActivityMultiYearPlanning,
						redirect: "/modules/multi-year-planning/import",
						children: [
							{
								path: 'import',
								name: 'multi-year-planning/import',
							},
							{
								path: 'delete',
								name: 'multi-year-planning/delete',
							},
						]
					}
				]
			},
			{
				path: '/:resource',
				component: ItemLayout,
				children: [
					{
						name: 'index',
						path: '',
						component: ItemIndex
					},
					{
						name: 'create',
						path: 'create',
						component: ItemCreate
					},
					{
						name: 'show',
						path: ':identity',
						component: ItemShow
					},
					{
						name: 'edit',
						path: ':identity/edit',
						component: ItemEdit
					},
					{
						name: 'delete',
						path: ':identity/delete',
						component: ItemDelete
					},
					{
						name: 'menu',
						path: ':identity/menu',
						component: ItemMenu
					},
					{
						name: 'notes',
						path: ':identity/notes',
						component: ItemNotes
					},
					{
						name: 'file',
						path: ':identity/file',
						component: ItemFile,
					}
				],
			},
		]
	},
	{
		name: 'not_found',
		path: '/:catchAll(.*)',
		component: PageNotFound
	},
];