<template>
  <div
    class="vdpComponent"
    ref="datepick"
    style="width: 100%"
    v-bind:class="{ vdpWithInput: hasInputElement }"
  >
    <div class="input-group">
      <input
        type="text"
        ref="input"
        v-if="hasInputElement"
        v-bind="inputAttributesFiltered"
        :value="inputValue"
        :class="inputClasses"
        :placeholder="placeholder"
        :readonly="inputAttributes.readonly || inputAttributes.disabled == true"
        :disabled="inputAttributes.readonly || inputAttributes.disabled == true"
        @change="onChange"
        @focus="onFocus"
        @keydown.esc="onEnter"
        @keydown.tab="close"
        @keydown="onKeyDown"
        @input="editable && processUserInput($event.target.value)"
        autocomplete="off"
      />
      <button
        v-if="
          clearButton &&
          editable &&
          hasInputElement &&
          inputValue &&
          !inputAttributes.disabled
        "
        @click="clear"
        class="vdpClearInput"
        type="button"
        tabindex="-1"
      ></button>
      <div v-if="showButton && useCalendar" class="input-group-append">
        <span
          @click.prevent="editable && (opened ? close() : open())"
          :disabled="inputAttributes.disabled"
          class="btn"
          :class="buttonClasses"
        >
          <i class="fal fa-calendar-alt" />
        </span>
      </div>
    </div>
    <span v-if="error" class="d-block error mt-1 ml-2 text-danger">
      <i class="fa fa-exclamation-triangle" />
      {{ error }}
    </span>
    <span v-if="info" class="d-block info mt-1 ml-2 text-info">
      <i class="fa fa-lightbulb" />
      {{ info }}
    </span>
    <div name="vdp-toggle-calendar">
      <div
        v-if="opened && useCalendar"
        ref="outerWrap"
        class="vdpOuterWrap"
        v-on:click="closeViaOverlay"
        v-bind:class="[positionClass, { vdpFloating: hasInputElement }]"
      >
        <div class="vdpInnerWrap">
          <header class="vdpHeader">
            <button
              class="vdpArrow vdpArrowPrev"
              v-bind:title="prevMonthCaption"
              type="button"
              v-on:click="incrementMonth(-1)"
              tabindex="-1"
            >
              {{ prevMonthCaption }}
            </button>
            <button
              class="vdpArrow vdpArrowNext"
              type="button"
              v-bind:title="nextMonthCaption"
              v-on:click="incrementMonth(1)"
              tabindex="-1"
            >
              {{ nextMonthCaption }}
            </button>
            <div class="vdpPeriodControls">
              <div class="vdpPeriodControl">
                <button
                  v-bind:class="directionClass"
                  v-bind:key="currentPeriod.month"
                  type="button"
                  tabindex="-1"
                >
                  {{ months[currentPeriod.month] }}
                </button>
                <select v-model="currentPeriod.month" tabindex="-1">
                  <option
                    v-for="(month, index) in months"
                    v-bind:value="index"
                    v-bind:key="month"
                  >
                    {{ month }}
                  </option>
                </select>
              </div>
              <div class="vdpPeriodControl">
                <button
                  v-bind:class="directionClass"
                  v-bind:key="currentPeriod.year"
                  type="button"
                  tabindex="-1"
                >
                  {{ currentPeriod.year }}
                </button>
                <select v-model="currentPeriod.year" tabindex="-1">
                  <option
                    v-for="year in yearRange"
                    v-bind:value="year"
                    v-bind:key="year"
                  >
                    {{ year }}
                  </option>
                </select>
              </div>
            </div>
          </header>
          <table class="vdpTable">
            <thead>
              <tr>
                <th
                  class="vdpHeadCell"
                  v-for="weekday in weekdaysSorted"
                  v-bind:key="weekday"
                >
                  <span class="vdpHeadCellContent">{{ weekday }}</span>
                </th>
              </tr>
            </thead>
            <tbody
              v-bind:key="currentPeriod.year + '-' + currentPeriod.month"
              v-bind:class="directionClass"
            >
              <tr
                class="vdpRow"
                v-for="(week, weekIndex) in currentPeriodDates"
                v-bind:key="weekIndex"
              >
                <td
                  class="vdpCell"
                  v-for="item in week"
                  v-bind:class="{
                    selectable: !item.disabled,
                    selected: item.selected,
                    disabled: item.disabled,
                    today: item.today,
                    outOfRange: item.outOfRange,
                  }"
                  v-bind:data-id="item.dateKey"
                  v-bind:key="item.dateKey"
                  v-on:click="selectDateItem(item)"
                >
                  <div class="vdpCellContent">{{ item.date.getDate() }}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="pickTime && currentTime" class="vdpTimeControls">
            <span class="vdpTimeCaption">{{ setTimeCaption }}</span>
            <div class="vdpTimeUnit">
              <pre><span>{{ currentTime.hoursPadded }}</span><br /></pre>
              <input
                type="number"
                pattern="\d*"
                class="vdpHoursInput"
                v-on:input.prevent="inputTime('setHours', $event)"
                v-bind:value="currentTime.hoursPadded"
                @keydown.enter.prevent="onEnter"
                @keydown.esc="onEnter"
                tabindex="-1"
              />
            </div>
            <span v-if="pickMinutes" class="vdpTimeSeparator">:</span>
            <div v-if="pickMinutes" class="vdpTimeUnit">
              <pre><span>{{ currentTime.minutesPadded }}</span><br /></pre>
              <input
                v-if="pickMinutes"
                type="number"
                pattern="\d*"
                class="vdpMinutesInput"
                v-on:input="inputTime('setMinutes', $event)"
                v-bind:value="currentTime.minutesPadded"
                @keydown.enter.prevent="onEnter"
                @keydown.esc="onEnter"
                tabindex="-1"
              />
            </div>
            <span v-if="pickSeconds" class="vdpTimeSeparator">:</span>
            <div v-if="pickSeconds" class="vdpTimeUnit">
              <pre><span>{{ currentTime.secondsPadded }}</span><br /></pre>
              <input
                v-if="pickSeconds"
                type="number"
                pattern="\d*"
                class="vdpSecondsInput"
                v-on:input="inputTime('setSeconds', $event)"
                v-bind:value="currentTime.secondsPadded"
                @keydown.enter.prevent="onEnter"
                @keydown.esc="onEnter"
                tabindex="-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

const formatRE = /,|\.|-| |:|\/|\\/;
const dayRE = /D+/;
const monthRE = /M+/;
const yearRE = /Y+/;
const hoursRE = /h+/i;
const minutesRE = /m+/;
const secondsRE = /s+/;

export default {
  props: {
    value: { type: String, default: "" },
    format: { type: String, default: "YYYY-MM-DD" },
    displayFormat: { type: String },
    editable: { type: Boolean, default: true },
    hasInputElement: { type: Boolean, default: true },
    inputAttributes: { type: Object },
    selectableYearRange: { type: Number, default: 40 },
    parseDate: { type: Function },
    formatDate: { type: Function },
    pickTime: { type: Boolean, default: false },
    pickMinutes: { type: Boolean, default: true },
    pickSeconds: { type: Boolean, default: false },
    isDateDisabled: { type: Function, default: () => false },
    nextMonthCaption: { type: String, default: "Next month" },
    prevMonthCaption: { type: String, default: "Previous month" },
    setTimeCaption: { type: String, default: "Set time:" },
    mobileBreakpointWidth: { type: Number, default: 500 },
    weekdays: {
      type: Array,
      default: () => ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    months: {
      type: Array,
      default: () => [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
    startWeekOnSunday: { type: Boolean, default: false },

    // extended by M. Nuyens
    info: { type: String, default: null },
    error: { type: String, default: null },
    placeholder: { type: String, default: null },
    showButton: { type: Boolean, default: true },
    clearButton: { type: Boolean, default: false },
    useCalendar: { type: Boolean, default: true },
    inputClasses: { type: String, default: null },
    buttonClasses: { type: String, default: null },
    keyNavigation: { type: Boolean, default: true },
  },

  data() {
    return {
      inputValue: this.valueToInputFormat(this.value),
      currentPeriod: this.getPeriodFromValue(this.value, this.format),
      direction: undefined,
      positionClass: undefined,
      opened: !this.hasInputElement,
    };
  },

  computed: {
    inputAttributesFiltered() {
      return _.omit(this.inputAttributes, "settings");
    },

    valueDate() {
      const value = this.value;
      const format = this.format;

      return value ? this.parseDateString(value, format) : undefined;
    },

    isValidValue() {
      const valueDate = this.valueDate;

      return this.value ? Boolean(valueDate) : true;
    },

    currentPeriodDates() {
      const { year, month } = this.currentPeriod;
      const days = [];
      const date = new Date(year, month, 1);
      const today = new Date();
      const offset = this.startWeekOnSunday ? 1 : 0;

      // append prev month dates
      const startDay = date.getDay() || 7;

      if (startDay > 1 - offset) {
        for (let i = startDay - (2 - offset); i >= 0; i--) {
          const prevDate = new Date(date);
          prevDate.setDate(-i);
          days.push({ outOfRange: true, date: prevDate });
        }
      }

      while (date.getMonth() === month) {
        days.push({ date: new Date(date) });
        date.setDate(date.getDate() + 1);
      }

      // append next month dates
      const daysLeft = 7 - (days.length % 7);

      for (let i = 1; i <= daysLeft; i++) {
        const nextDate = new Date(date);
        nextDate.setDate(i);
        days.push({ outOfRange: true, date: nextDate });
      }

      // define day states
      days.forEach((day) => {
        day.disabled = this.isDateDisabled(day.date);
        day.today = areSameDates(day.date, today);
        day.dateKey = [
          day.date.getFullYear(),
          day.date.getMonth() + 1,
          day.date.getDate(),
        ].join("-");
        day.selected = this.valueDate
          ? areSameDates(day.date, this.valueDate)
          : false;
      });

      return chunkArray(days, 7);
    },

    yearRange() {
      const years = [];
      const currentYear = this.currentPeriod.year;
      const startYear = currentYear - this.selectableYearRange;
      const endYear = currentYear + this.selectableYearRange;

      for (let i = startYear; i <= endYear; i++) {
        years.push(i);
      }

      return years;
    },

    currentTime() {
      const currentDate = this.valueDate || new Date();

      return currentDate
        ? {
            hours: currentDate.getHours(),
            minutes: currentDate.getMinutes(),
            seconds: currentDate.getSeconds(),
            hoursPadded: paddNum(currentDate.getHours(), 1),
            minutesPadded: paddNum(currentDate.getMinutes(), 2),
            secondsPadded: paddNum(currentDate.getSeconds(), 2),
          }
        : undefined;
    },

    directionClass() {
      return this.direction ? `vdp${this.direction}Direction` : undefined;
    },

    weekdaysSorted() {
      if (this.startWeekOnSunday) {
        const weekdays = this.weekdays.slice();
        weekdays.unshift(weekdays.pop());
        return weekdays;
      } else {
        return this.weekdays;
      }
    },
  },

  watch: {
    value(value) {
      if (this.isValidValue) {
        this.inputValue = this.valueToInputFormat(value);
        this.currentPeriod = this.getPeriodFromValue(value, this.format);
      }
    },

    currentPeriod(currentPeriod, oldPeriod) {
      const currentDate = new Date(
        currentPeriod.year,
        currentPeriod.month
      ).getTime();
      const oldDate = new Date(oldPeriod.year, oldPeriod.month).getTime();

      this.direction =
        currentDate !== oldDate
          ? currentDate > oldDate
            ? "Next"
            : "Prev"
          : undefined;
    },
  },

  beforeDestroy() {
    this.removeCloseEvents();
    this.teardownPosition();
  },

  methods: {
    // extended by M. Nuyens -- begin
    onChange() {
      this.$emit("change", this.value);
    },
    onFocus() {
      if (this.isEnabled()) {
        this.$refs.input.select();
        this.open();
      }
    },
    onEnter(e) {
      this.close();
      e.target.blur();
    },
    isEnabled() {
      return (
        this.useCalendar &&
        !this.inputAttributes.disabled &&
        !this.inputAttributes.readonly
      );
    },
    onKeyDown(e) {
      // TODO: control individual times via cursor position
      // || _.inRange(cursor, 6, 10)
      // _.inRange(cursor, 3, 5)
      // let cursor = this.$refs.input.selectionStart;
      // this.$refs.input.selectionStart = 5;
      // this.$refs.input.selectionEnd = 6;
      if (this.keyNavigation) {
        if (e.key == "ArrowUp" || e.key == "ArrowDown") {
          this.$emit("keydown");
          let amount = this.inputValue ? 1 : null;
          let method = e.key == "ArrowUp" ? "add" : "subtract";
          let unit = e.altKey ? "year" : e.shiftKey ? "month" : "day";
          let date = this.inputValue
            ? this.value
            : moment().format(this.format);
          this.processUserInput(
            moment(date, this.format, true)
              [method](amount, unit)
              .format(this.displayFormat)
          );
          e.preventDefault();
        } else if (e.code == "Space") {
          this.opened ? this.close() : this.open();
          e.preventDefault();
        }
      }
    },
    // extended by M. Nuyens -- end

    valueToInputFormat(value) {
      return !this.displayFormat
        ? value
        : this.formatDateToString(
            this.parseDateString(value, this.format),
            this.displayFormat
          ) || value;
    },

    getPeriodFromValue(dateString, format) {
      const date = this.parseDateString(dateString, format) || new Date();

      return { month: date.getMonth(), year: date.getFullYear() };
    },

    parseDateString(dateString, dateFormat) {
      return !dateString
        ? undefined
        : this.parseDate
        ? this.parseDate(dateString, dateFormat)
        : this.parseSimpleDateString(dateString, dateFormat);
    },

    formatDateToString(date, dateFormat) {
      return !date
        ? ""
        : this.formatDate
        ? this.formatDate(date, dateFormat)
        : this.formatSimpleDateToString(date, dateFormat);
    },

    parseSimpleDateString(dateString, dateFormat) {
      let day, month, year, hours, minutes, seconds;

      const dateParts = dateString.split(formatRE);
      const formatParts = dateFormat.split(formatRE);
      const partsSize = formatParts.length;

      for (let i = 0; i < partsSize; i++) {
        if (formatParts[i].match(dayRE)) {
          day = parseInt(dateParts[i], 10);
        } else if (formatParts[i].match(monthRE)) {
          month = parseInt(dateParts[i], 10);
        } else if (formatParts[i].match(yearRE)) {
          year = parseInt(dateParts[i], 10);
        } else if (formatParts[i].match(hoursRE)) {
          hours = parseInt(dateParts[i], 10);
        } else if (formatParts[i].match(minutesRE)) {
          minutes = parseInt(dateParts[i], 10);
        } else if (formatParts[i].match(secondsRE)) {
          seconds = parseInt(dateParts[i], 10);
        }
      }

      const resolvedDate = new Date(
        [paddNum(year, 4), paddNum(month, 2), paddNum(day, 2)].join("-")
      );

      if (isNaN(resolvedDate)) {
        return undefined;
      } else {
        const date = new Date(year, month - 1, day);

        [
          [year, "setFullYear"],
          [hours, "setHours"],
          [minutes, "setMinutes"],
          [seconds, "setSeconds"],
        ].forEach(([value, method]) => {
          typeof value !== "undefined" && date[method](value);
        });

        return date;
      }
    },

    formatSimpleDateToString(date, dateFormat) {
      return dateFormat
        .replace(yearRE, (match) => date.getFullYear())
        .replace(monthRE, (match) => paddNum(date.getMonth() + 1, match.length))
        .replace(dayRE, (match) => paddNum(date.getDate(), match.length))
        .replace(hoursRE, (match) => paddNum(date.getHours(), match.length))
        .replace(minutesRE, (match) => paddNum(date.getMinutes(), match.length))
        .replace(secondsRE, (match) =>
          paddNum(date.getSeconds(), match.length)
        );
    },

    incrementMonth(increment = 1) {
      const refDate = new Date(
        this.currentPeriod.year,
        this.currentPeriod.month
      );
      const incrementDate = new Date(
        refDate.getFullYear(),
        refDate.getMonth() + increment
      );

      this.currentPeriod = {
        month: incrementDate.getMonth(),
        year: incrementDate.getFullYear(),
      };
    },

    processUserInput(userText) {
      const userDate = this.parseDateString(
        userText,
        this.displayFormat || this.format
      );

      this.inputValue = userText;

      this.$emit(
        "input",
        userDate ? this.formatDateToString(userDate, this.format) : userText
      );
    },

    open() {
      if (!this.opened) {
        this.opened = true;
        this.currentPeriod = this.getPeriodFromValue(this.value, this.format);
        this.addCloseEvents();
        this.setupPosition();
      }
      this.direction = undefined;
    },

    close() {
      if (this.opened) {
        this.opened = false;
        this.direction = undefined;
        this.removeCloseEvents();
        this.teardownPosition();
      }
    },

    closeViaOverlay(e) {
      if (this.hasInputElement && e.target === this.$refs.outerWrap) {
        this.close();
      }
    },

    addCloseEvents(e) {
      if (!this.closeEventListener) {
        this.closeEventListener = (e) => this.inspectCloseEvent(e);

        ["click", "keyup"].forEach((eventName) =>
          document.addEventListener(eventName, this.closeEventListener)
        );
      }
    },

    inspectCloseEvent(event) {
      if (event.keyCode) {
        event.keyCode === 27 && this.close();
      } else if (
        !(event.target === this.$el) &&
        !this.$el.contains(event.target)
      ) {
        this.close();
      }
    },

    removeCloseEvents() {
      if (this.closeEventListener) {
        ["click", "keyup"].forEach((eventName) =>
          document.removeEventListener(eventName, this.closeEventListener)
        );

        delete this.closeEventListener;
      }
    },

    setupPosition() {
      if (!this.positionEventListener) {
        this.positionEventListener = () => this.positionFloater();
        window.addEventListener("resize", this.positionEventListener);
      }

      this.positionFloater();
    },

    positionFloater() {
      const inputRect = this.$el.getBoundingClientRect();

      let verticalClass = "vdpPositionTop";
      let horizontalClass = "vdpPositionLeft";

      const calculate = () => {
        const rect = this.$refs.outerWrap.getBoundingClientRect();
        const floaterHeight = rect.height;
        const floaterWidth = rect.width;

        if (window.innerWidth > this.mobileBreakpointWidth) {
          // vertical
          if (
            inputRect.top + inputRect.height + floaterHeight >
              window.innerHeight &&
            inputRect.top - floaterHeight > 0
          ) {
            verticalClass = "vdpPositionBottom";
          }

          // horizontal
          if (inputRect.left + floaterWidth > window.innerWidth) {
            horizontalClass = "vdpPositionRight";
          }

          this.positionClass = [
            "vdpPositionReady",
            verticalClass,
            horizontalClass,
          ].join(" ");
        } else {
          this.positionClass = "vdpPositionFixed";
        }
      };

      this.$refs.outerWrap ? calculate() : this.$nextTick(calculate);
    },

    teardownPosition() {
      if (this.positionEventListener) {
        this.positionClass = undefined;
        window.removeEventListener("resize", this.positionEventListener);
        delete this.positionEventListener;
      }
    },

    clear() {
      this.$emit("input", "");
    },

    selectDateItem(item) {
      if (!item.disabled) {
        const newDate = new Date(item.date);

        if (this.currentTime) {
          newDate.setHours(this.currentTime.hours);
          newDate.setMinutes(this.currentTime.minutes);
          newDate.setSeconds(this.currentTime.seconds);
        }

        this.$emit("input", this.formatDateToString(newDate, this.format));

        if (this.hasInputElement && !this.pickTime) {
          this.close();
        }
      }
    },

    inputTime(method, event) {
      const currentDate = this.valueDate;
      const maxValues = { setHours: 23, setMinutes: 59, setSeconds: 59 };

      let numValue = parseInt(event.target.value, 10) || 0;

      if (numValue > maxValues[method]) {
        numValue = maxValues[method];
      } else if (numValue < 0) {
        numValue = 0;
      }

      event.target.value = paddNum(numValue, method === "setHours" ? 1 : 2);
      currentDate[method](numValue);

      this.$emit("input", this.formatDateToString(currentDate, this.format));
    },
  },
};

function paddNum(num, padsize) {
  return typeof num !== "undefined"
    ? num.toString().length > padsize
      ? num
      : new Array(padsize - num.toString().length + 1).join("0") + num
    : undefined;
}

function chunkArray(inputArray, chunkSize) {
  const results = [];

  while (inputArray.length) {
    results.push(inputArray.splice(0, chunkSize));
  }

  return results;
}

function areSameDates(date1, date2) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}
</script>