<template>
  <b-badge
    pill
    class="ml-1"
    variant="primary"
    v-if="badge > 0"
    v-text="badge"
  />
</template>

<script>
export default {
  props: {
    badge: {
      type: String | Number,
      default: 0,
    },
  },
};
</script>