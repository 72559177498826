<template>
  <app-alert
    class="fadeIn"
    v-bind="alert"
    v-if="show && loaded"
    @close="show = false"
  />
</template>

<script>
import AppAlert from "@/components/app/AppAlert";
export default {
  components: {
    AppAlert,
  },
  data() {
    return {
      show: false,
      alert: {},
      retry: false,
      loaded: false,
      feedback: {},
    };
  },
  mounted() {
    this.$root.$on("feedback", (feedback) => {
      if (this.show === false) {
        this.setFeedback(feedback);
        this.setAlert();
        this.show = true;
      }
    });
  },
  computed: {
    apiUrl() {
      let { resource, identity, group, key } = this.feedback;
      let provim = resource ? null : "provim";
      let path = [provim, resource, identity, "alerts", group, key]
        .filter((v) => v)
        .join("/");
      return path;
    },
  },
  methods: {
    setFeedback(feedback) {
      let fallback = this.$route["params"];
      this.feedback = {
        key: _.get(feedback, "key", this.$route["name"]),
        group: _.get(feedback, "group", "item"),
        resource: _.get(feedback, "resource", fallback["resource"]),
        identity: _.get(feedback, "identity", fallback["identity"]),
      };
    },
    setAlert() {
      this.alert = {};
      this.$http
        .get(this.apiUrl)
        .then(({ data: { data } }) => (this.alert = data))
        .then(() => (this.loaded = true))
        .catch(() => this.tryAgain());
    },
    tryAgain() {
      if (!this.retry) {
        this.retry = true;
        this.feedback = {
          key: this.feedback["key"] || 500,
          group: "error",
          resource: null,
          identity: null,
        };
        this.setAlert();
      }
    },
  },
};
</script>