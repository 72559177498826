<template>
  <item-button
    class="ml-auto"
    v-bind="button"
    @click="dialogOpen = true"
    :disabled="$route.name == 'create'"
  >
    <!-- Flag Dialog -->
    <dialog-flag v-if="dialogOpen" @close="dialogOpen = false" />
  </item-button>
</template>

<script>
import ItemButton from "@/components/item/Buttons/ItemButton";
import DialogFlag from "@/components/dialogs/DialogFlag";
export default {
  components: {
    DialogFlag,
    ItemButton,
  },
  props: {
    button: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
};
</script>