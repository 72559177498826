<template>
  <!-- Panels -->
  <split-pane
    split="horizontal"
    :min-percent="10"
    :max-percent="90"
    :default-percent="fullHeight ? 100 : getStoredPaneSize('grid', 50)"
    @resize="(percent) => onPaneResize(percent, 'grid')"
  >
    <!-- Grid Panel -->
    <template slot="paneL">
      <data-grid
        v-model="grid"
        :data="data"
        :meta="meta"
        :view="view"
        :filters="filters"
        :abilities="abilities"
        @selection="(value) => $emit('selection', value)"
        @reorder="(value) => $emit('reorder', value)"
        @action="(value) => $emit('action', value)"
        @select="(value) => $emit('select', value)"
        @filter="(value) => $emit('filter', value)"
        @search="(value) => $emit('search', value)"
        @input="(value) => $emit('input', value)"
        @sort="(value) => $emit('sort', value)"
      >
        <template slot="footer">
          <slot name="footer" />
        </template>
      </data-grid>
    </template>
    <!-- Navigator Panel -->
    <template slot="paneR">
      <split-pane
        split="vertical"
        :min-percent="5"
        :max-percent="95"
        :default-percent="getStoredPaneSize('navigator', 20)"
        @resize="(percent) => onPaneResize(percent, 'navigator')"
      >
        <template slot="paneL">
          <browser-navigator
            @data-loaded="onNavigatorDataLoaded"
            v-model="navigator"
            :grid="grid"
          />
        </template>
        <template slot="paneR">
          <split-pane
            split="vertical"
            :min-percent="5"
            :max-percent="95"
            :default-percent="detailsPaneSize"
            @resize="(percent) => onPaneResize(percent, 'details')"
          >
            <!-- Detail Panel -->
            <template slot="paneL">
              <browser-details
                v-model="details"
                :navigator="navigator"
                :grid="grid"
                :meta="meta"
              />
            </template>
            <!-- Notes Panel -->
            <template slot="paneR">
              <browser-notes
                v-model="notes"
                :navigator="navigator"
                :details="details"
                :grid="grid"
              />
            </template>
          </split-pane>
        </template>
      </split-pane>
    </template>
  </split-pane>
</template>

<script>
import BrowserNavigator from "@/components/browser/BrowserNavigator";
import BrowserDetails from "@/components/browser/BrowserDetails";
import BrowserNotes from "@/components/browser/BrowserNotes";
import DataGrid from "@/components/data/DataGrid";
import splitPane from "vue-splitpane";
export default {
  components: {
    BrowserNavigator,
    BrowserDetails,
    BrowserNotes,
    splitPane,
    DataGrid,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
      required: false,
    },
    meta: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    abilities: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Array,
      default: () => [],
    },
    view: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      grid: {},
      notes: "",
      timeout: 0,
      details: {},
      navigator: {},
      fullHeight: true,
    };
  },
  computed: {
    detailsPaneSize() {
      let defaultPaneSize = this.getStoredPaneSize("details", 75);
      let navigatorAction = _.get(this.navigator, "action.name");
      let useFullWidth = navigatorAction == "notes" || _.isEmpty(this.notes);
      return useFullWidth ? 100 : defaultPaneSize;
    },
  },
  methods: {
    onPaneResize(percent, pane) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => localStorage.setItem(pane, percent), 500);
    },
    onNavigatorDataLoaded(data) {
      this.fullHeight = _.isEmpty(_.get(data, 'items'));
    },
    getStoredPaneSize(name, fallback = null) {
      return parseInt(localStorage.getItem(name) || fallback);
    },
  },
};
</script>