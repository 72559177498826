var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.check.apply(null, arguments)}}},[_c('form-field',{staticClass:"d-block mb-4",attrs:{"errors":_vm.error,"field":{
      name: 'code',
      label: _vm.__('Uw tweefactorcode | Your two-factor code'),
      placeholder: _vm.__('Voer hier uw code in | Enter your code here'),
      control: 'input',
      type: 'number',
      icon: 'qrcode',
      required: true,
      autofocus: true,
      class: 'text-center field--code',
      style: 'font-size: 15px;',
    }},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('submit-button',{attrs:{"label":_vm.__('Controleren | Verify'),"icon":"arrow-right","loading":_vm.loading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }