var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form-field',{staticClass:"d-block mb-4",attrs:{"field":{
      name: 'module',
      label: _vm.__('Kies een module | Choose a module'),
      placeholder: _vm.__('Selecteer uw module | Please select your module'),
      control: 'select',
      class: 'text-center',
      required: true,
      options: _vm.modules,
    }},model:{value:(_vm.module),callback:function ($$v) {_vm.module=$$v},expression:"module"}}),_c('submit-button',{attrs:{"label":_vm.__('Doorgaan | Continue'),"icon":"arrow-right"},on:{"submit":_vm.redirect}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }