<template>
  <section
    :class="`app__page app--${$getDevice()} ${
      hideMenu ? 'app--hidemenu' : ''
    } ${fullScreen ? 'app--fullscreen' : ''}`"
  >
    <app-navigation v-if="!hideMenu" />
    <router-view class="page__content" v-if="hasLabels" />
    <app-feedback />
  </section>
</template>

<script>
import AppNavigation from "@/components/app/AppNavigation";
import AppFeedback from "@/components/app/AppFeedback";
import ErrorsMixin from "@/mixins/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  components: {
    AppNavigation,
    AppFeedback,
  },
  data() {
    return {
      hasLabels: false,
    };
  },
  beforeCreate() {
    this.$store.dispatch("config/getLabels", () => (this.hasLabels = true));
  },
  beforeMount() {
    this.getModule(({ title }) => (document.title = `${title} · PropertyView`));
  },
  methods: {
    getModule(callback) {
      this.$http
        .get("system/module")
        .then(({ data: { data } }) => callback(data));
    },
  },
  computed: {
    hideMenu() {
      return [0, "false", "off", "disabled"].includes(
        this.$route.query["menu"]
      );
    },
    fullScreen() {
      return [1, "true", "on", "enabled"].includes(
        this.$route.query["fullscreen"]
      );
    },
  },
};
</script>