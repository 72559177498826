<template>
  <component
    :is="field.type"
    :form="form"
    :field="field"
    :errors="errors"
    v-model="input"
  />
</template>

<script>
import InspectionProperty from "@/components/form/custom/InspectionProperty";
export default {
  inheritAttrs: false,
  components: {
    InspectionProperty,
  },
  props: {
    field: {
      type: Object,
      default: () => {},
      required: true,
    },
    form: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Array | String,
      default: () => [],
    },
    value: {
      required: false,
    },
  },
  watch: {
    input: {
      deep: true,
      handler: function (input) {
        this.$emit("change", input);
      },
    },
  },
  data() {
    return {
      input: null,
    };
  },
};
</script>