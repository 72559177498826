<script>
import ResourceService from "@/services/ResourceService";
export default {
  props: {
    base: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    group: {
      type: String,
      default: undefined,
    },
    parent: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      view: {},
      update: false,
      resource: undefined,
    };
  },
  created() {
    this.setView();
    this.setResource();
    this.setListeners();
  },
  activated() {
    if (this.update) {
      this.setView(() => {
        this.update = false;
        this.$emit("updated", this.resource);
      });
    }
  },
  render() {
    return this.$scopedSlots.default(this.view);
  },
  methods: {
    async setView(callback = () => {}) {
      this.view = await ResourceService.getView(
        this.base,
        this.name,
        this.group,
        this.params
      );
      this.setDocumentTitle();
      callback();
    },
    setResource() {
      this.resource = this.$route.params["resource"];
    },
    setListeners() {
      this.$root.$on("updated-resource", (resource) => {
        this.resource = resource;
        this.update = true;
      });
    },
    setDocumentTitle() {
      if (this.parent) document.title = `${this.view["title"]} - PropertyView`;
    },
  },
};
</script>