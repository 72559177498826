<template>
  <div style="position: relative; margin: -10px 0">
    <!-- Refresh Button-->
    <b-button
      size="sm"
      id="resetButton"
      variant="outline-primary"
      @click="$confirm(confirm) ? loadInput() : null"
    >
      <i class="fal fa-redo"></i> &nbsp;Herstellen
    </b-button>
    <template v-if="!_.isEmpty(input)">
      <template v-for="(section, sectionKey) in input">
        <!-- Property Section -->
        <section :key="section.id" class="property__section">
          <!-- Section Header -->
          <header @click="section.open = !section.open" class="section__header">
            <span class="header__owner">
              <i class="toggle-icon fal fa-sort"></i> &nbsp;
              {{ `${section.title} (${_.size(section.items)})` }}
            </span>
          </header>
          <!-- Section Items -->
          <b-collapse v-model="section.open" class="section__items">
            <template v-for="(item, itemKey) in section.items">
              <!-- Item Fields -->
              <fieldset :key="item.id" class="section__item">
                <div class="item__description">
                  <label :for="`${item.id}_item_remarks`" class="item__label">
                    {{ item.title }}
                  </label>
                  <b-input
                    type="text"
                    v-model="item.remarks"
                    :id="`${item.id}_item_remarks`"
                    :name="`${item.id}_item_remarks`"
                    :placeholder="__('Opmerkingen | Remarks')"
                  />
                  <template v-if="error(sectionKey, itemKey, 'remarks')">
                    <small class="d-inline-block text-danger mt-1">
                      {{ error(sectionKey, itemKey, "remarks").join("; ") }}
                    </small>
                  </template>
                </div>
                <div class="item__owner">
                  <!-- Owners (Desktop) -->
                  <b-form-radio-group
                    size="md"
                    :buttons="true"
                    :options="owners"
                    v-model="item.owner"
                    class="d-none d-md-flex"
                    style="margin-bottom: 1px"
                    :name="`${item.id}_item_owner`"
                    button-variant="outline-secondary"
                  />
                  <!-- Owners (Mobile) -->
                  <b-form-radio-group
                    size="sm"
                    :buttons="true"
                    v-model="item.owner"
                    :options="ownersMobile"
                    class="d-flex d-md-none"
                    style="margin-bottom: 5px"
                    :name="`${item.id}_item_owner`"
                    button-variant="outline-secondary"
                  />
                  <template v-if="error(sectionKey, itemKey, 'owner')">
                    <small class="d-inline-block text-danger mt-1">
                      {{ error(sectionKey, itemKey, "owner").join("; ") }}
                    </small>
                  </template>
                </div>
              </fieldset>
            </template>
          </b-collapse>
        </section>
      </template>
    </template>
    <template v-else>
      <b-alert show variant="info" class="mt-3">
        {{ __("Geen eigendomlijst gevonden. | No ownership list found.") }}
      </b-alert>
    </template>
    <small class="text-danger" v-if="errors">
      {{
        __(
          "Sommige velden van uw eigendomslijst zijn niet (juist) ingevuld. | It appears some fields of your ownership list were not entered (correctly)."
        )
      }}
    </small>
  </div>
</template>

<script>
import { _ } from "core-js";

export default {
  inheritAttrs: false,
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: String,
      default: null,
    },
  },
  watch: {
    input: {
      deep: true,
      handler: function (input) {
        this.$emit("input", input);
      },
    },
    "form.ownership_importer_inspection": function (Isp_Id) {
      if (_.has(Isp_Id, "value") && this.$confirm(this.confirm)) {
        this.loadInput(Isp_Id.value);
      }
    },
    "form.ownership_importer_template": function (Isp_Id) {
      if (Isp_Id && this.$confirm(this.confirm)) {
        this.loadInput(Isp_Id);
      }
    },
  },
  beforeMount() {
    this.loadInput();
  },
  data() {
    return {
      input: null,
      confirm:
        "Weet u zeker dat u de huidige eigendomlijst wilt vervangen? | Are you sure you want to replace the current property list?",
    };
  },
  computed: {
    errorMessages() {
      if (!this.errors) return {};
      return JSON.parse(this.errors);
    },
    owners() {
      return {
        1: this.__("Verhuurder | Owner"),
        2: this.__("Huurder | Tenant"),
        3: this.__("N.v.t. | N/A"),
        4: this.__("N.t.b. | T.B.D."),
      };
    },
    ownersMobile() {
      return {
        1: this.__("VHR | OWN"),
        2: this.__("HDR | TNT"),
        3: this.__("N.v.t. | N/A"),
        4: this.__("N.t.b. | T.B.D."),
      };
    },
    importScope() {
      return this.form.ownership_importer_scope;
    },
    importEverything() {
      return this.importScope == "1";
    },
    importOwner() {
      return this.importScope == "2";
    },
    importRemarks() {
      return this.importScope == "3";
    },
  },
  methods: {
    loadInput(Isp_Id = this.$route.params.identity) {
      const params = Isp_Id
        ? this.$objectToQueryParams({ Isp_Id }, "filter")
        : {};
      this.$http
        .get("ownership_property", { params })
        .then(({ data: { data } }) => {
          Isp_Id ? this.updateInput(data) : (this.input = data);
        });
    },
    updateInput(data) {
      _.forEach(data, (section, sectionIndex) => {
        _.forEach(section.items, (item, itemIndex) => {
          let key = `${sectionIndex}.items.${itemIndex}`;
          if (this.importEverything) {
            _.set(this.input, key, item);
          } else if (this.importOwner) {
            _.set(this.input, `${key}.owner`, item.owner);
          } else if (this.importRemarks) {
            _.set(this.input, `${key}.remarks`, item.remarks);
          }
        });
      });
    },
    error(sectionKey, itemKey, field) {
      return this.errorMessages[
        `sections.${sectionKey}.items.${itemKey}.${field}`
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
#resetButton {
  position: absolute;
  top: -40px;
  right: 0;
}

.section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.property__section {
  .section__header {
    padding: 10px 15px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    background-color: #f8fafd;
    border: 1px solid #d3dae3;
    border-radius: 5px;
    font-weight: 600;
    color: #00295a;
    cursor: pointer;

    .toggle-icon {
      opacity: 0.7;
    }

    &:hover {
      background-color: #e7ebf0;
      border-color: #c4cdd8;
      color: #073368;

      .toggle-icon {
        opacity: 1;
      }
    }
  }

  .section__items {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .section__item {
      &:last-child {
        margin-bottom: 1rem;
      }

      .item__owner {
        margin-top: 29px;
      }

      .item__description {
        width: 75%;
        padding-right: 1rem;

        .item__label {
          font-size: 12px;
          color: #00295a;
        }
      }
    }
  }
}
</style>